import { useEffect, useState, type FC, type ReactNode } from 'react';

import {
  ComparisonGrid,
  ComparisonHeader,
  ComparisonLayout,
} from '@optimizer/components';
import { compareViewState } from '@optimizer/states/comparisonState';
import {
  Button,
  ExpandContentIcon,
  GeometryInspectorContainer,
  GeometryInspectorHeader,
} from '@pxui/components/ui';
import { useRecoilState } from 'recoil';

import LoadingIndicator from '@components/vtk-viewer-wasm/components/LoadingIndicator/LoadingIndicator';
import useMeshVisualiser from '@components/vtk-viewer-wasm/hooks/useMeshVisualiser';

import GeometryInspectorControls from './geometryInspectorControls';

interface GeometryData {
  filename: string;
  url: string;
}

interface GeometryInspectorPanelProps {
  buttons: ReactNode;
  children?: ReactNode;
  geometries: GeometryData[];
  isLoading?: boolean;
  onClose?: () => void;
  onExpand?: () => void;
  open?: boolean;
}

const GeometryInspectorPanel: FC<GeometryInspectorPanelProps> = ({
  buttons,
  children,
  geometries,
  onClose,
  onExpand,
  open,
  isLoading,
}) => {
  const [selectedGeometry, setSelectedGeometry] = useState<GeometryData | null>(
    null,
  );
  const showGeometryButtons = geometries && geometries.length > 1;
  const [isOnCompareView, setCompareView] = useRecoilState(compareViewState);

  const {
    addMesh,
    canvasRef,
    initialized,
    isLoading: isMeshLoading,
  } = useMeshVisualiser({});

  const overallLoading = isLoading || isMeshLoading;

  // Select the first geometry by default when the component opens or clear prev selected geometry
  useEffect(() => {
    if (geometries && geometries.length > 0) {
      setSelectedGeometry(geometries[0]);
    } else {
      setSelectedGeometry(null);
    }
  }, [geometries, open]);

  // Update the mesh when the selected geometry changes
  useEffect(() => {
    if (selectedGeometry && initialized && selectedGeometry.url) {
      addMesh(selectedGeometry.filename, selectedGeometry.url);
    }
  }, [addMesh, selectedGeometry, initialized]);

  const compareView = (
    <ComparisonLayout>
      <ComparisonHeader />
      <ComparisonGrid />
    </ComparisonLayout>
  );

  const geometryView = (
    <div className="flex flex-col overflow-y-auto max-h-full gap-2">
      <div className="relative w-[calc(100%-4em)] mx-auto h-[624px] bg-surface-container-low rounded-lg flex-shrink-0">
        <div className="absolute right-4 top-4 z-10 w-8 h-8 p-1 bg-surface-container-high rounded">
          <Button
            className="w-6 h-6 p-0 bg-surface-container-high rounded-sm"
            disabled={!selectedGeometry?.url && !overallLoading && initialized}
            icon={ExpandContentIcon}
            layout="iconOnly"
            onClick={onExpand}
            variant="ghost"
          />
        </div>

        {/* Show a loading indicator if the content or mesh is loading */}
        {overallLoading && (
          <div className="w-full flex justify-center">
            <LoadingIndicator className="text-blue-600" />
          </div>
        )}

        {/* If no URL or geometry is not available, show a message */}
        {!selectedGeometry?.url && !overallLoading && initialized && (
          <div className="flex justify-center items-center w-full h-full absolute top-0 left-0 z-10 rounded-lg bg-surface-00">
            <span className="text-primary">Geometry is not available</span>
          </div>
        )}

        {/* Canvas for the mesh visualization */}
        <div id="canvas-wrapper" className="rounded-lg overflow-hidden">
          <canvas id="canvas" ref={canvasRef} />
        </div>

        {/* Show geometry controls if there are multiple geometries */}
        {showGeometryButtons && selectedGeometry?.url && (
          <GeometryInspectorControls
            geometry={selectedGeometry}
            geometries={geometries}
            setGeometry={setSelectedGeometry}
          />
        )}
      </div>

      {children}
    </div>
  );

  return (
    <GeometryInspectorContainer open={open}>
      <GeometryInspectorHeader>
        <Button
          layout="iconOnly"
          variant="ghost"
          iconName="close"
          onClick={() => {
            if (isOnCompareView) {
              setCompareView(false);
            }
            onClose?.();
          }}
        />
        {buttons}
      </GeometryInspectorHeader>
      {isOnCompareView ? compareView : geometryView}
    </GeometryInspectorContainer>
  );
};

export { GeometryInspectorPanel, type GeometryInspectorPanelProps };
