import { injectLinkAtPosition, LinkObjType } from '@utils/stringUtils';

export type EmptyStateChildrenType = {
  message: string;
  messageLink?: LinkObjType;
  title: string;
};

export const EmptyStateChildren = ({
  message,
  title,
  messageLink,
}: EmptyStateChildrenType) => {
  if (messageLink) {
    const { label } = messageLink;
    const linkPosition = message.indexOf(label);
    return (
      <>
        <div className="paragraph-1 text-on-surface text-center">{title}</div>
        <div className="paragraph-1 text-on-surface-subtle text-center no-wrap">
          {injectLinkAtPosition(message, messageLink, linkPosition)}
        </div>
      </>
    );
  }

  return (
    <>
      <div className="paragraph-1 text-center text-on-surface">{title}</div>
      <div className="paragraph-1 text-center text-on-surface-subtle">
        {message}
      </div>
    </>
  );
};

export default EmptyStateChildren;
