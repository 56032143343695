import { FC } from 'react';

import { OptimizerUrlState } from '@config/optimizerUrl';
import { DataSeriesSelector } from '@optimizer/components/OptimizationFilters/DataSeriesSelector';
import { CandidateSelectionMode } from '@optimizer/schemas/filteredOptimizationsSchema';
import candidateSelectionModeState from '@optimizer/states/selectedCandidateSelectionMode';
import { PlotType } from '@optimizer/types/plotStateTypes';
import { useRecoilState } from 'recoil';

import { useUrlContext } from '@contexts/UrlQueryParamsContext';

const { MAX, MEDIAN, MIN } = CandidateSelectionMode;

// TODO: move radio to ui library
const CandidateSelectionModeList: FC = () => {
  const { params, updateParams } = useUrlContext<OptimizerUrlState>();

  const [candidateSelectionMode, setCandidateSelectionMode] = useRecoilState(
    candidateSelectionModeState,
  );
  const currentPlot =
    params?.plotConfigData?.find((plot) => plot.highlighted) ?? {};

  const handleLinePlotChange = (key: string, value: string) => {
    const tempKey = key as 'line' | 'marker';
    const tempValue = value as CandidateSelectionMode;

    const prevValues = candidateSelectionMode[tempKey];

    if (value !== 'All candidates') {
      const newValues = prevValues.includes(tempValue)
        ? prevValues.filter((e) => e !== tempValue)
        : [...prevValues, value];

      const newObj = {
        ...candidateSelectionMode,
        [tempKey]: prevValues.length > 2 ? [value] : newValues,
      };

      setCandidateSelectionMode(newObj);

      return;
    }

    const newObj = {
      ...candidateSelectionMode,
      [tempKey]: [MAX, MEDIAN, MIN],
    };

    setCandidateSelectionMode(newObj);
  };

  const handleScatterPlotChange = (key: string, value: string) => {
    const tempKey = key as 'line' | 'marker';

    updateParams({
      paretoFront: {
        ...params.paretoFront,
        [tempKey]: params?.paretoFront?.[tempKey] === value ? undefined : value,
      },
    });
  };

  if (!currentPlot.id) {
    return null;
  }

  const title = 'Data series';

  if (currentPlot.type === PlotType.LINE) {
    const areAllMarkerSelected = candidateSelectionMode.marker.length > 2;

    return (
      <DataSeriesSelector
        title={title}
        config={[
          {
            args: {
              [MAX]:
                !areAllMarkerSelected &&
                candidateSelectionMode.marker.includes(MAX),
              [MEDIAN]:
                !areAllMarkerSelected &&
                candidateSelectionMode.marker.includes(MEDIAN),
              [MIN]:
                !areAllMarkerSelected &&
                candidateSelectionMode.marker.includes(MIN),
              // eslint-disable-next-line sort-keys, sort-keys-fix/sort-keys-fix
              'All candidates': areAllMarkerSelected,
            },
            id: 'marker',
            label: 'Marker',
          },
          {
            args: {
              [MAX]: candidateSelectionMode.line.includes(MAX),
              [MEDIAN]: candidateSelectionMode.line.includes(MEDIAN),
              [MIN]: candidateSelectionMode.line.includes(MIN),
            },
            id: 'line',
            label: 'Line',
          },
        ]}
        handleChange={handleLinePlotChange}
      />
    );
  }

  if (currentPlot.type === PlotType.SCATTER) {
    return (
      <DataSeriesSelector
        title={title}
        config={[
          {
            args: {
              Pareto: params?.paretoFront?.marker === 'Pareto',
              // eslint-disable-next-line sort-keys, sort-keys-fix/sort-keys-fix
              'All candidates':
                params?.paretoFront?.marker === 'All candidates',
            },
            id: 'marker',
            label: 'Marker',
          },
          {
            args: {
              Pareto: params?.paretoFront?.line === 'Pareto',
            },
            id: 'line',
            label: 'Line',
          },
        ]}
        handleChange={handleScatterPlotChange}
      />
    );
  }

  return null;
};

export default CandidateSelectionModeList;
