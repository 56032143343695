import { FC, useEffect } from 'react';

import { OptimizationStatusEnum } from '@optimizer/schemas/allOptimizationsSchema';
import {
  selectedOptimizationsStaticDataState,
  selectedOptimizationsDynamicDataState,
  visibleSelectedOptimizationsState,
} from '@optimizer/states/selectedOptimizationsStates';
import { Optimization } from '@optimizer/types/services/allOptimizationsTypes';
import formatOptimizationLabel from '@optimizer/utils/formatOptimizationLabel';
import pollOptimizationStatus from '@optimizer/utils/pollOptimizationStatus';
import { Icon, ListItem, ListItemCollectionToggle } from '@pxui/components/ui';
import cn from '@pxui/lib/utils';
import { useRecoilValue, useSetRecoilState } from 'recoil';

interface OptimizationsListItemProps {
  id: string;
}

const OptimizationsListItem: FC<OptimizationsListItemProps> = ({ id }) => {
  // Use separate state for static and dynamic parts instead of `selectedOptimizationFullState`
  const optimizationStatic = useRecoilValue(
    selectedOptimizationsStaticDataState,
  ).find((opt) => opt.id === id);

  const dynamicData = useRecoilValue(selectedOptimizationsDynamicDataState(id));
  const setStaticData = useSetRecoilState(selectedOptimizationsStaticDataState);
  const setDynamicData = useSetRecoilState(
    selectedOptimizationsDynamicDataState(id),
  );
  const visibleOptimizations = useRecoilValue(
    visibleSelectedOptimizationsState,
  );

  const toggleHide = () => {
    if (dynamicData.hide || visibleOptimizations.length > 1) {
      setDynamicData((prev) => ({ ...prev, hide: !prev.hide }));
    }
  };

  useEffect(() => {
    if (
      !optimizationStatic ||
      optimizationStatic.status !== OptimizationStatusEnum.RUNNING
    )
      return;

    const cancel = pollOptimizationStatus(
      id,
      optimizationStatic.stepsCompleted,
      optimizationStatic.status,
      ({
        error,
        optimization,
      }: {
        error?: boolean;
        optimization: Optimization;
      }) => {
        // Update static data for the optimization
        setStaticData((prev) =>
          prev.map((opt) => (opt.id === optimization.id ? optimization : opt)),
        );

        // Update dynamic data like error state if necessary
        setDynamicData((prev) => ({
          ...prev,
          error: error ?? false, // Ensure error is always a boolean
        }));
      },
    );

    return () => cancel();
  }, [id, optimizationStatic, setDynamicData, setStaticData]);

  if (!optimizationStatic) {
    return null; // Render nothing if optimization data is missing
  }

  const isHidden = dynamicData.hide;

  const renderSuffix = () => {
    if (dynamicData.error) {
      return (
        <span className="label-1 ml-auto text-auxiliary-error">Error</span>
      );
    }

    return (
      <Icon
        className={cn(
          'opacity-0 group-hover:opacity-100 transition-opacity',
          isHidden && 'opacity-100',
        )}
        name={isHidden ? 'visibility_off' : 'visibility'}
      />
    );
  };

  return (
    <ListItem
      className={cn('items-center group', {
        'text-on-surface-subtle': isHidden,
      })}
      label={formatOptimizationLabel(
        optimizationStatic.createdAt,
        optimizationStatic.name || undefined,
      )}
      prefix={
        <ListItemCollectionToggle
          color={dynamicData.color}
          toggled={!isHidden}
        />
      }
      suffix={renderSuffix()}
      onClick={toggleHide}
    />
  );
};

export default OptimizationsListItem;
