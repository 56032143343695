import { useCallback, useRef, useState } from 'react';

import { LINK_COPY_FAST_TIMEOUT } from '@flow/constants';
import { Button } from '@pxui/components/ui';
import cn from '@pxui/lib/utils';

const successTextClasses =
  'label-1 text-key-success ml-2 opacity-100 transition-opacity duration-300 cursor-default pr-4';

const CopyViewButton = () => {
  const [isCopying, setIsCopying] = useState<boolean>(false);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleCopy = useCallback(() => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl);
    setIsCopying(true);

    timeoutRef.current = setTimeout(() => {
      setIsCopying(false);
      timeoutRef.current = null;
    }, LINK_COPY_FAST_TIMEOUT);
  }, []);

  return (
    <>
      {isCopying && <span className={cn(successTextClasses)}>Copied</span>}
      <Button
        className="mr-2"
        disabled={isCopying}
        layout="textOnly"
        onClick={handleCopy}
        variant="primary"
      >
        Copy Link
      </Button>
    </>
  );
};

export default CopyViewButton;
