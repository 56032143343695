import { FC } from 'react';

import cn from '@pxui/lib/utils';

interface MeshDataTableProps {
  data: string[][];
}

const MeshDataTable: FC<MeshDataTableProps> = ({ data }) => {
  return (
    <div className="text-sm w-full text-primary">
      {data.map((row, index: number) => {
        return (
          <div key={index} className="flex">
            {Object.values(row).map((value, i: number) => {
              return (
                <span
                  key={i}
                  className={cn('block label-1 align-middle py-2', {
                    'flex-auto': i === 0,
                    'px-3': i > 0,
                    'text-secondary': i === 0,
                  })}
                >
                  {value}
                </span>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default MeshDataTable;
