import type { FC } from 'react';

interface HelperTextProps {
  helperText?: string;
  size?: 'small' | 'medium';
}

const HelperText: FC<HelperTextProps> = ({ helperText, size = 'medium' }) => {
  if (!helperText) return null;

  const labelSize = size === 'small' ? 'label-2' : 'label-1';

  return (
    <span
      className={`${labelSize} text-on-surface-subtle peer-disabled:text-disabled`}
    >
      {helperText}
    </span>
  );
};

export { HelperText, type HelperTextProps };
