import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD hh:mm A';

/**
 * Formats a given date into the specified format. Throws an error if the date is invalid.
 *
 * @param {Date} date - The date object to format.
 * @returns {string} - The formatted date string.
 * @throws {Error} - Throws an error if the provided date is invalid.
 */
export default function formatDate(date: Date | string): string {
  if (!date || Number.isNaN(new Date(date).getTime())) {
    throw new Error('Invalid date provided');
  }

  return moment(date).format(DATE_FORMAT);
}
