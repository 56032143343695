import formatDate from './formatDate';
import formatTime from './formatTime';

/**
 * Formats the label for an optimization based on its name and creation date.
 * @param {Date} createdAt - The creation date of the optimization.
 * @param {string | undefined} name - The name of the optimization.
 * @returns {string} - The formatted optimization label.
 */
export default function formatOptimizationLabel(
  createdAt?: Date | string,
  name?: string,
): string {
  if (!createdAt) return '';

  return name ? `${name} ${formatTime(createdAt)}` : formatDate(createdAt);
}
