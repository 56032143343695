import { FC, useCallback, useEffect } from 'react';

import { OptimizerUrlState } from '@config/optimizerUrl';
import uncertaintySelectionModeState, {
  UncertaintySelectionModeValue,
} from '@optimizer/states/uncertaintySelectionMode';
import {
  Icon,
  InfoTooltip,
  ListHeader,
  ListItem,
  ListSection,
} from '@pxui/components/ui';
import * as RadioPrimitive from '@radix-ui/react-radio-group';
import { InfoIcon } from 'lucide-react';
import { useRecoilState } from 'recoil';

import { useUrlContext } from '@contexts/UrlQueryParamsContext';

const UncertaintyModeItems = {
  '0': 'Hide',
  '1': 'Show',
};

const UncertaintyMode: FC = () => {
  const { params, updateParams } = useUrlContext<OptimizerUrlState>();

  const [uncertaintySelectionMode, setUncertaintySelectionMode] =
    useRecoilState(uncertaintySelectionModeState);

  const handleUncertaintySelectionModeFilterUpdate = useCallback(
    (value?: UncertaintySelectionModeValue) => {
      if (params?.steps?.candidateSelection !== value) {
        updateParams({
          steps: {
            ...params.steps,
            uncertaintyMode: value,
          },
        });
      }
    },
    [params.steps, updateParams],
  );

  const handleValueChange = (value: UncertaintySelectionModeValue) => {
    setUncertaintySelectionMode(value);
    handleUncertaintySelectionModeFilterUpdate(value);
  };

  useEffect(() => {
    if (!params.steps?.uncertaintyMode) {
      handleUncertaintySelectionModeFilterUpdate(uncertaintySelectionMode);
    } else if (uncertaintySelectionMode !== params.steps.uncertaintyMode) {
      setUncertaintySelectionMode(params.steps.uncertaintyMode);
    }
  }, [
    uncertaintySelectionMode,
    handleUncertaintySelectionModeFilterUpdate,
    params.steps?.uncertaintyMode,
    setUncertaintySelectionMode,
  ]);

  return (
    <ListSection>
      <ListHeader
        title="Show Uncertainty"
        icon={
          <InfoTooltip
            description="Select to toggle uncertainty on plots"
            className="max-w-60"
          >
            <InfoIcon
              width={20}
              height={20}
              className="text-on-surface-subtle cursor-pointer"
            />
          </InfoTooltip>
        }
      />
      <RadioPrimitive.Root
        value={uncertaintySelectionMode}
        onValueChange={handleValueChange}
        aria-label="Uncertainty Mode"
      >
        {Object.entries(UncertaintyModeItems)
          .reverse()
          .map(([key, value]) => (
            <ListItem
              key={key}
              label={value}
              onClick={() =>
                handleValueChange(key as UncertaintySelectionModeValue)
              }
              className="cursor-pointer flex items-center"
              prefix={
                <RadioPrimitive.Item className="relative" value={key} id={key}>
                  <RadioPrimitive.Indicator className="absolute inset-0 flex items-center justify-center">
                    <Icon
                      name="radio_button_checked"
                      className="text-primary-container"
                    />
                  </RadioPrimitive.Indicator>
                  <Icon name="radio_button_unchecked" />
                </RadioPrimitive.Item>
              }
            />
          ))}
      </RadioPrimitive.Root>
    </ListSection>
  );
};

export default UncertaintyMode;
