import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Pin: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13.2497 10.1762L15.0734 12V13.0833H11.208V17.4166L10.6664 17.9583L10.1247 17.4166V13.0833H6.25928V12L8.08303 10.1762V4.49996H7.08303V3.41663H14.2497V4.49996H13.2497V10.1762ZM7.79136 12H13.5414L12.1664 10.625V4.49996H9.16636V10.625L7.79136 12Z"
      fill="currentColor"
    />
  </svg>
);

export default Pin;
