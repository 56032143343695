import { atomFamily, selector } from 'recoil';

import { comparisonState } from './comparisonState';

/**
 * Recoil atomFamily to store presigned URLs for each candidate.
 * Each atom represents a candidate, and for each candidate,
 * it stores a record where the key is the geometry name and the value is the URL.
 *
 * @type {atomFamily<Record<string, string>, string>}
 * @example
 * const presignedUrls = useRecoilValue(candidatesPresignedUrlsState(candidateId));
 * // presignedUrls = { "geometryName1": "url1", "geometryName2": "url2" }
 */
const candidatesPresignedUrlsState = atomFamily<Record<string, string>, string>(
  {
    default: {},
    key: 'candidatesPresignedUrlsState',
  },
);

export default candidatesPresignedUrlsState;

// Recoil selector to get presigned URLs for all candidates in the comparison array
export const comparisonCandidatesPresignedUrls = selector<
  Record<string, string>[]
>({
  get: ({ get }) => {
    const comparisons = get(comparisonState);
    const comparisonCandidatesIds = comparisons.map((el) => el.candidate.id);
    const presignedUrls = comparisonCandidatesIds.map((id) =>
      get(candidatesPresignedUrlsState(id)),
    );

    return presignedUrls;
  },
  key: 'comparisonCandidatesPresignedUrls',
});
