import { RouteObject } from 'react-router';

import { lazyLoadHelper } from '@utils/lazyLoadHelper';

export const routes: RouteObject[] = [
  {
    index: true,
    lazy: lazyLoadHelper(() => import('@flow/pages/HomePage/HomePage')),
  },
  {
    lazy: lazyLoadHelper(
      () => import('@flow/pages/PipelinesPage/PipelinesPage'),
    ),
    path: 'pipelines',
  },
  {
    lazy: lazyLoadHelper(() => import('@flow/pages/RunsPage/RunsPage')),
    path: 'runs',
  },
];

export default routes;
