/* Summary & Context of the File

  This file provides a React context (UrlContext) that manages URL-based state
  for different applications (flow, optimizer, vault). It ensures that the URL
  query parameters are parsed, validated, and synchronized with the application's state.

  The primary purpose of this file is to allow different apps to manage their
  state via URL parameters, ensuring deep linking, bookmarking, and persistence
  across sessions (TODO).

Key Functionalities:

  1. Detects the current app (flow, optimizer, vault) from the URL.
  2. Parses and validates URL query parameters, ensuring correct data
    types and handling malformed values.
  3. Syncs query parameters with React state, allowing updates that reflect
    in the URL without reloading the page.
  4. Provides a global context (UrlContext) to access and update URL
    state from anywhere in the app.
  5. When user navigates to Home all url states reset.

How to Add New URL Variables:

  To add a new URL parameter (e.g., newParam for optimizer):

  1️⃣ Update the OptimizerUrlState Interface
    Modify @config/optimizerUrl.ts to include:

    ```
    export interface OptimizerUrlState {
      ...
      newParam?: string; // Add your new URL parameter type
    }
    ```

  2️⃣ Update URL Parsing in validateOptimizerParams
    Modify the validateOptimizerParams function in this file:

    ```
    case 'newParam': // Ensure correct type parsing
      params[typedKey] = value; // Assuming it's a simple string, see existing validator for more
      break;
    ```

  3️⃣ Use updateParams to Modify State
    In a component, use useUrlContext to update the state:

    ```
    const { updateParams } = useUrlContext<OptimizerUrlState>();
    updateParams({ newParam: 'myValue' });
    ```

    This updates the URL without reloading.

TODO:
  1. Persist URL state per app using sessionStorage (currently this info is lost with reset)
  2. Extend to handle common url params across app
  3. Move app-specific url param validation elsewhere (needs to be a component in order to render the toast)
  4. Dynamically initialise url defaultStates with app names from AppConfig
*/

import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Location, useLocation, useNavigate } from 'react-router';

import { flowDefaultUrl } from '@config/flowUrl';
import {
  defaultConfigDataDummy,
  optimizerDefaultUrl,
  OptimizerUrlState,
  PlotConfigDataForUrl,
  StepsState,
} from '@config/optimizerUrl';
import { vaultDefaultUrl } from '@config/vaultUrl';
import { toast } from '@pxui/components/ui/toast/useToast';

import { parseJson } from '@utils/urlParamsParser';

const defaultStates = {
  flow: flowDefaultUrl,
  optimizer: optimizerDefaultUrl,
  vault: vaultDefaultUrl,
};

type AppNames = keyof typeof defaultStates;

const getCurrentApp = (pathname: string): AppNames | null => {
  const app = pathname.split('/')[2];
  return app in defaultStates ? (app as AppNames) : null;
};

// ---- APP SPECIFIC URL PARAMS VALIDATION FUNCTIONS ----
const validateOptimizerParams = (
  query: URLSearchParams,
): Partial<OptimizerUrlState> => {
  const params: Partial<OptimizerUrlState> = {};

  query.forEach((value, key) => {
    const typedKey = key as keyof OptimizerUrlState;

    switch (typedKey) {
      case 'metrics':
      case 'optimizationIds': // string array
      case 'satisfiedConstraints': {
        const parsedValue = parseJson<string[]>(typedKey, value);
        params[typedKey] = parsedValue;

        if (typedKey === 'optimizationIds' && !parsedValue) {
          toast({
            description:
              'Invalid optimization IDs in URL. Please select new optimizations from the table on Home page.',
            title: 'Invalid URL',
            variant: 'error',
          });
        }
        break;
      }

      case 'plotConfigData': {
        params[typedKey] =
          parseJson<PlotConfigDataForUrl[]>(typedKey, value) ??
          defaultConfigDataDummy;
        break;
      }

      case 'steps': {
        params[typedKey] = parseJson<StepsState>(typedKey, value);
        break;
      }

      default:
        console.error(`Unsupported URL parameter: ${typedKey}`);
        break;
    }
  });

  return params;
};

// loader helper function that ensures that the correct app specific parameters are loaded on initialisation
const loadQueryParamsFromUrl = <T extends object>(
  locationState: Location,
  app: keyof typeof defaultStates,
): T => {
  const query = new URLSearchParams(locationState.search);
  let params: Partial<T> = {};

  switch (app) {
    case 'optimizer':
      params = validateOptimizerParams(query) as Partial<T>;
      break;
    default:
      query.forEach((value, key) => {
        try {
          params[key as keyof T] = JSON.parse(value);
        } catch {
          console.error(`Malformed JSON for key '${key}' in the URL`);
          params[key as keyof T] = undefined;
        }
      });
  }

  return { ...defaultStates[app], ...params } as T;
};

export interface UrlContextState<T> {
  params: T;
  updateParams: (updates: Partial<T>) => void;
}

const UrlContext = createContext<UrlContextState<any> | null>(null);

export const UrlProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [currentApp, setCurrentApp] = useState<AppNames | null>(null);
  const prevApp = useRef<AppNames | null>(null);

  // 🏎️ Compute initial queryParams immediately instead of initializing with an empty object
  const initialQueryParams = useMemo(
    () => (currentApp ? loadQueryParamsFromUrl(location, currentApp) : {}),
    [currentApp, location],
  );

  // Initialize state from URL or default values in case user navigated directly to app
  const [queryParams, setQueryParams] =
    useState<OptimizerUrlState>(initialQueryParams);
  const prevQueryParams = useRef<string | null>(null);

  useEffect(() => {
    const app = getCurrentApp(location.pathname);
    if (currentApp !== app) {
      setCurrentApp(app);
    }
  }, [currentApp, location.pathname]);

  useEffect(() => {
    if (!currentApp) {
      setQueryParams({}); // reset query params state when user navigates to home
    } else if (currentApp !== prevApp.current) {
      const initQueryParams = loadQueryParamsFromUrl(location, currentApp);
      setQueryParams(initQueryParams);
      prevApp.current = currentApp;
    }
  }, [location, currentApp]);

  // Set the state updates to URL
  useEffect(() => {
    if (!currentApp || Object.entries(queryParams).length === 0) return;

    if (Object.entries(queryParams).length) {
      const query = new URLSearchParams();

      Object.entries(queryParams).forEach(([key, value]) => {
        if (value !== undefined) {
          query.set(key, JSON.stringify(value));
        }
      });

      const queryString = query.toString();

      if (queryString !== prevQueryParams.current) {
        navigate({ search: queryString }, { replace: true });
        prevQueryParams.current = queryString;
      }
    }
  }, [queryParams, navigate, currentApp]);

  // Query update callback function
  const updateParams = useCallback((updates: Partial<typeof queryParams>) => {
    setQueryParams((prev) => {
      const newState = { ...prev, ...updates };

      // Avoid updating state if there's no actual change
      if (JSON.stringify(prev) === JSON.stringify(newState)) {
        return prev;
      }
      return newState;
    });
  }, []);

  const value = useMemo(
    () => ({ params: queryParams, updateParams }),
    [queryParams, updateParams],
  );
  return <UrlContext.Provider value={value}>{children}</UrlContext.Provider>;
};

export const useUrlContext = <T extends object>() => {
  const context = useContext(UrlContext);
  if (!context) {
    throw new Error('useUrlContext must be used within a UrlProvider');
  }
  return context as UrlContextState<T>;
};
