import { FC, createContext, useEffect, useState } from 'react';
import { useLocation, useMatch } from 'react-router';

import { getAllOperatorApps } from '@config/appRegistry';

import { type AppbarState } from '@typing/AppbarState';
import { AppConfig } from '@typing/AppConfig';

const defaultState = {
  appName: 'Platform',
  navigationLinks: [],
};

const fluxApps: AppConfig[] = [
  ...getAllOperatorApps().filter((el) => !el?.external),
];
export const AppbarContext = createContext<AppbarState>(defaultState);

export const AppbarContextProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const match = useMatch('/app/*');
  const [appbarState, setAppbarState] = useState<AppbarState>(defaultState);

  useEffect(() => {
    if (match) {
      fluxApps.forEach(({ appName, path, navigationLinks }) => {
        if (match.pathname.includes(path)) {
          if (appbarState.appName === appName) return;
          setAppbarState({
            appName,
            navigationLinks,
          });
        }
      });
    } else {
      setAppbarState(defaultState);
    }
  }, [match, location, setAppbarState, appbarState.appName]);

  return (
    <AppbarContext.Provider value={appbarState}>
      {children}
    </AppbarContext.Provider>
  );
};

export default AppbarContextProvider;
