import {
  BRIGHTEN_FACTOR,
  DEFAULT_OPACITY,
  MARKER_SIZE,
  SELECTED_OPACITY,
  SELECTED_SIZE,
} from '@optimizer/constants/plotConstants';
import { CustomPlotData, CustomPlotDatum } from '@optimizer/types/plotTypes';
import { Optimization } from '@optimizer/types/services/allOptimizationsTypes';
import { Candidate } from '@optimizer/types/services/filteredOptimizationsTypes';
import { brightenHexColor } from '@optimizer/utils/brightenHexColor';
import { Datum } from 'plotly.js';

/**
 * Sorts and filters Pareto front candidates by their X and Y metric values.
 * Filters out candidates that do not have valid metric values for the selected metrics.
 *
 * @param {Candidate[]} candidates - Array of candidates from the Pareto front.
 * @param {string} metricX - The metric used for the X-axis.
 * @param {string} metricY - The metric used for the Y-axis.
 * @returns {Candidate[]} - The sorted and filtered array of candidates.
 */
const sortParetoFrontCandidates = (
  candidates: Candidate[],
  metricX: string,
  metricY: string,
): Candidate[] => {
  return candidates
    .map((candidate) => {
      const metricXValue = candidate.metrics.find(
        (m) => m.name === metricX,
      )?.value;
      const metricYValue = candidate.metrics.find(
        (m) => m.name === metricY,
      )?.value;
      return { candidate, metricXValue, metricYValue };
    })
    .filter(
      ({ metricXValue, metricYValue }) =>
        metricXValue !== undefined && metricYValue !== undefined,
    )
    .sort((a, b) => {
      if (a.metricXValue !== b.metricXValue) {
        return a.metricXValue! - b.metricXValue!;
      }
      return a.metricYValue! - b.metricYValue!;
    })
    .map(({ candidate }) => candidate);
};

/**
 * Transforms optimization data into a format suitable for Plotly scatter plots.
 * Also checks for candidates in the Pareto front and conditionally includes/excludes non-Pareto candidates.
 *
 * @param {Optimization[]} visibleOptimizations - Array of visible optimizations with associated color.
 * @param {Record<string, Step[]>} optimizationData - The fetched optimization data mapped by optimization IDs.
 * @param {string} metricX - The metric used for the X-axis of the plot.
 * @param {string} metricY - The metric used for the Y-axis of the plot.
 * @param {string} [selectedCandidateId] - The ID of the selected candidate to highlight.
 * @param {Record<string, Candidate[]>} [paretoFrontData] - Pareto front candidates mapped by optimization ID.
 * @param {boolean} [includeParetoFront=true] - Whether to include Pareto front data in the plot.
 * @param {boolean} [includeNonParetoCandidates=true] - Whether to include non-Pareto candidates in the plot.
 * @returns {[Partial<CustomPlotData>[], Record<string, Candidate>, boolean]} - An array of transformed data ready for Plotly rendering, the candidates, and the Pareto front existence flag.
 */
const transformOptimizationDataToScatterPlotData = (
  visibleOptimizations: (Optimization & { color: string })[],
  optimizationData: Optimization[],
  metricX: string,
  metricY: string,
  includeParetoFront: boolean,
  includeNonParetoCandidates: boolean,
  selectedCandidateId?: string,
  areErrorsVisible?: boolean,
  plotMode?: 'markers' | 'lines',
): [Partial<CustomPlotData>[], Record<string, Candidate>] => {
  const plotData: Partial<CustomPlotData>[] = [];
  const allCandidates: Record<string, Candidate> = {};
  optimizationData.forEach(
    ({ id: optimizationId, filteredStepData: steps }) => {
      const xValues: Datum[] = [];
      const yValues: Datum[] = [];
      const xUncertainties: number[] = [];
      const yUncertainties: number[] = [];
      const customdata: CustomPlotDatum[] = [];
      const selectedpoints: number[] = [];
      let currIndex = -1;

      const relevantOptimization = optimizationData.find(
        (el) => el.id === optimizationId,
      );
      const paretoFrontCandidates = relevantOptimization?.paretoFront ?? [];
      // Process all candidates
      steps?.forEach((step) => {
        step.candidates?.forEach((candidate) => {
          currIndex += 1;
          const foundMetricX = candidate.metrics.find(
            (m) => m.name === metricX,
          );
          const foundMetricY = candidate.metrics.find(
            (m) => m.name === metricY,
          );

          const isParetoCandidate = paretoFrontCandidates.some(
            (paretoCandidate) => paretoCandidate.id === candidate.id,
          );

          // Exclude non-Pareto candidates if the option is disabled
          if (!includeNonParetoCandidates && !isParetoCandidate) {
            return;
          }

          if (
            foundMetricX?.value !== undefined &&
            foundMetricY?.value !== undefined
          ) {
            xValues.push(foundMetricX.value);
            yValues.push(foundMetricY.value);
            xUncertainties.push(foundMetricX.uncertainty || 0);
            yUncertainties.push(foundMetricY.uncertainty || 0);

            if (candidate?.id && optimizationId) {
              customdata.push([candidate.id, optimizationId, step.step]);
              allCandidates[candidate.id] = candidate;

              if (candidate.id === selectedCandidateId) {
                selectedpoints.push(currIndex);
              }
            }
          }
        });
      });

      const optimization = visibleOptimizations.find(
        (opt) => opt.id === optimizationId,
      );

      if (!optimization || xValues.length === 0 || yValues.length === 0) {
        return;
      }

      // Process Pareto front candidates
      if (paretoFrontCandidates.length > 0) {
        const sortedParetoFront = sortParetoFrontCandidates(
          paretoFrontCandidates,
          metricX,
          metricY,
        );

        const paretoXValues = sortedParetoFront.map(
          (candidate) =>
            candidate.metrics.find((m) => m.name === metricX)?.value,
        );
        const paretoYValues = sortedParetoFront.map(
          (candidate) =>
            candidate.metrics.find((m) => m.name === metricY)?.value,
        );

        // Create customdata for Pareto candidates
        const paretoCustomData = sortedParetoFront.map((candidate) => [
          candidate.id,
          optimization.id,
        ]);

        const brighterColor = brightenHexColor(
          optimization.color,
          BRIGHTEN_FACTOR,
        );

        if (includeParetoFront) {
          plotData.push({
            customdata: paretoCustomData,
            line: { color: brighterColor, width: 1 },
            marker: {
              color: brighterColor,
              opacity: DEFAULT_OPACITY,
              size: MARKER_SIZE,
            },
            mode: plotMode,
            name: '',
            x: paretoXValues,
            y: paretoYValues,
          } as Partial<CustomPlotData>);
        } else if (!includeNonParetoCandidates) {
          plotData.push({
            customdata: paretoCustomData,
            marker: {
              color: optimization.color,
              opacity: DEFAULT_OPACITY,
              size: MARKER_SIZE,
            },
            mode: plotMode,
            name: '',
            x: paretoXValues,
            y: paretoYValues,
          } as Partial<CustomPlotData>);
        }
      }
      // Add all candidates plot
      plotData.push({
        customdata,
        error_x: {
          array: xUncertainties,
          color: optimization.color,
          opacity: DEFAULT_OPACITY / 2,
          thickness: 1,
          type: 'data',
          visible: areErrorsVisible,
          width: 3,
        },
        error_y: {
          array: yUncertainties,
          color: optimization.color,
          opacity: DEFAULT_OPACITY / 2,
          thickness: 1,
          type: 'data',
          visible: areErrorsVisible,
          width: 3,
        },
        hoverinfo: 'text',
        marker: {
          color: optimization.color,
          opacity: DEFAULT_OPACITY,
          size: MARKER_SIZE,
          symbol: Object.values(allCandidates).map(({ isFeasible }) =>
            isFeasible ? 'circle' : 'circle-open',
          ),
        },
        mode: 'markers',
        name: '',
        selected: {
          marker: {
            color: 'white',
            opacity: SELECTED_OPACITY,
            size: SELECTED_SIZE,
            symbol: ['square'],
          },
        },
        selectedpoints,
        text: xValues.map(
          (x, index) =>
            `X: ${x} ±${xUncertainties[index]}<br>Y: ${yValues[index]}<br>±${yUncertainties[index]}`,
        ),
        type: 'scatter',
        unselected: {
          marker: {
            opacity: DEFAULT_OPACITY,
          },
        },
        x: xValues,
        y: yValues,
      } as Partial<CustomPlotData>);
    },
  );

  return [plotData, allCandidates];
};

export default transformOptimizationDataToScatterPlotData;
