import { DotIcon } from '@pxui/components/ui';

type OptimizationIdentifierType = {
  color: string;
  name: string;
  step?: number;
};

const titleClasses = 'label-1-strong text-on-surface';

const OptimizationIdentifier = ({
  name,
  color,
  step,
}: OptimizationIdentifierType) => {
  return (
    <div className="flex flex-row justify-between items-center p-2">
      <div className="flex gap-2 items-center">
        <div>
          <DotIcon color={color} />
        </div>
        <div className={titleClasses}>{name}</div>
        {step && (
          <div className="pl-4 label-1 text-on-surface">{`Step ${step}`}</div>
        )}
      </div>
    </div>
  );
};

export default OptimizationIdentifier;
