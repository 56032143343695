import { FC, memo } from 'react';

import { Button, InfoTooltip } from '@pxui/components/ui';

interface ExportButtonProps {
  handleExportAllGeometries?: () => void;
}

const ExportButton: FC<ExportButtonProps> = ({ handleExportAllGeometries }) => {
  const button = (
    <Button
      layout="textOnly"
      variant="secondary"
      onClick={handleExportAllGeometries}
      className="h-8"
      disabled={!handleExportAllGeometries}
    >
      Export
    </Button>
  );

  return handleExportAllGeometries ? (
    button
  ) : (
    <InfoTooltip
      description="Cannot export geometries for this candidate."
      side="left"
      className="max-w-60"
    >
      {button}
    </InfoTooltip>
  );
};

export default memo(ExportButton);
