import { useEffect } from 'react';
import { Outlet } from 'react-router';

import { Toaster } from '@pxui/components/ui';

import { useAuth0TokenContext } from '@contexts/Auth0TokenContext';
import useFetchIfNotLatestTermsConditions from '@hooks/useFetchIfNotLatestTermsConditions';
import initHotjar from '@hooks/useHotjar';

import {
  FullScreenContainer,
  Navbar,
  TermsAndConditionsModal,
} from './components';

// need to run the hook inside the authGuard hence separate component
const AppWrapper = () => {
  const { tokenExpiry } = useAuth0TokenContext();
  const { isError, isLoading, acceptedLatestTerms, setAcceptedLatestTerms } =
    useFetchIfNotLatestTermsConditions();

  useEffect(() => {
    initHotjar();
  }, []);

  const timeNow = new Date();
  const isTokenValid = tokenExpiry !== null && tokenExpiry > timeNow;

  if (!isTokenValid) return;

  return (
    <>
      <FullScreenContainer>
        <Navbar />
        {/** Hide all components to prevent the request from coming out
         *  and show the T&C modal if latest T&Cs are not accepted */}
        {acceptedLatestTerms ? (
          <Outlet />
        ) : (
          <TermsAndConditionsModal
            open={!acceptedLatestTerms}
            setAccepted={setAcceptedLatestTerms}
            isError={isError}
            isLoading={isLoading}
          />
        )}
      </FullScreenContainer>
      <Toaster />
    </>
  );
};

export default AppWrapper;
