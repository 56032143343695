import { OptimizationsResponseSchema } from '@optimizer/schemas/allOptimizationsSchema';
import { OptimizationsResponse } from '@optimizer/types/services/allOptimizationsTypes';
import handleError from '@optimizer/utils/handleError';

import apiClient from '@services/interceptors/authInterceptor';

import { PAGE_SIZE } from '@constants/common';

/**
 * Fetches a paginated list of optimizations from the API.
 * Supports fetching optimizations created after a specific timestamp.
 *
 * @param {number} [pageSize=PAGE_SIZE] - The number of results to fetch per page.
 * @param {string | null} [pageToken=null] - The pagination token for the next page.
 * @param {string | null} [createdAfter=null] - ISO 8601 timestamp to filter results by creation date.
 * @returns {Promise<OptimizationsResponse | undefined>} - The parsed optimizations and pagination token, or undefined on error.
 */
export const fetchAllOptimizations = async (
  pageSize: number = PAGE_SIZE,
  pageToken: string | null = null,
  createdAfter: string | null = null,
): Promise<OptimizationsResponse | undefined> => {
  try {
    const params: Record<string, any> = { page_size: pageSize };
    if (pageToken) params.page_token = pageToken;
    if (createdAfter) params.created_after = createdAfter;

    const response = await apiClient.get(
      `${process.env.PLATFORM_API_URL}/v0/optimizer/optimizations`,
      {
        params,
      },
    );

    return OptimizationsResponseSchema.parse(response.data);
  } catch (error) {
    handleError(error, 'fetch optimizations');
    return undefined;
  }
};

export default fetchAllOptimizations;
