import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const ContentCopy: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.54813 14.5833C7.17299 14.5833 6.8559 14.4538 6.59687 14.1948C6.33785 13.9358 6.20833 13.6187 6.20833 13.2435V3.75645C6.20833 3.38131 6.33785 3.06423 6.59687 2.8052C6.8559 2.54617 7.17299 2.41666 7.54813 2.41666H15.0352C15.4103 2.41666 15.7274 2.54617 15.9865 2.8052C16.2455 3.06423 16.375 3.38131 16.375 3.75645V13.2435C16.375 13.6187 16.2455 13.9358 15.9865 14.1948C15.7274 14.4538 15.4103 14.5833 15.0352 14.5833H7.54813ZM7.54813 13.5H15.0352C15.0994 13.5 15.1581 13.4733 15.2115 13.4198C15.2649 13.3664 15.2917 13.3077 15.2917 13.2435V3.75645C15.2917 3.69228 15.2649 3.63353 15.2115 3.5802C15.1581 3.52673 15.0994 3.49999 15.0352 3.49999H7.54813C7.48396 3.49999 7.42521 3.52673 7.37188 3.5802C7.3184 3.63353 7.29167 3.69228 7.29167 3.75645V13.2435C7.29167 13.3077 7.3184 13.3664 7.37188 13.4198C7.42521 13.4733 7.48396 13.5 7.54813 13.5ZM4.96479 17.1667C4.58965 17.1667 4.27257 17.0371 4.01354 16.7781C3.75451 16.5191 3.625 16.202 3.625 15.8269V5.25645H4.70833V15.8269C4.70833 15.891 4.73507 15.9498 4.78854 16.0031C4.84188 16.0566 4.90062 16.0833 4.96479 16.0833H13.5352V17.1667H4.96479Z"
      fill="currentColor"
    />
  </svg>
);

export default ContentCopy;
