import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Delete: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.30773 17.5C2.80901 17.5 2.38306 17.3234 2.02986 16.9701C1.67664 16.6169 1.50003 16.191 1.50003 15.6923V2.99999H0.500031V1.50002H5.00001V0.615417H11V1.50002H15.5V2.99999H14.5V15.6923C14.5 16.1974 14.325 16.625 13.975 16.975C13.625 17.325 13.1974 17.5 12.6923 17.5H3.30773ZM13 2.99999H3.00001V15.6923C3.00001 15.782 3.02886 15.8557 3.08656 15.9134C3.14426 15.9711 3.21798 16 3.30773 16H12.6923C12.7692 16 12.8397 15.9679 12.9039 15.9038C12.968 15.8397 13 15.7692 13 15.6923V2.99999ZM5.40388 14H6.90386V4.99999H5.40388V14ZM9.09616 14H10.5961V4.99999H9.09616V14Z"
      fill="currentColor"
    />
  </svg>
);

export default Delete;
