import {
  Candidate,
  Step,
} from '@optimizer/types/services/filteredOptimizationsTypes';
import { atom } from 'recoil';

export interface ComparisonStateInterface {
  candidate: Candidate;
  optimizationId: string | null;
  step: Step['step'];
}

export const comparisonState = atom<ComparisonStateInterface[] | []>({
  default: [],
  key: 'comparisonState',
});

export const compareViewState = atom<boolean>({
  default: false,
  key: 'compareViewState',
});
