import { useEffect, useRef, useState } from 'react';

import cn from '@pxui/lib/utils';

interface RunningSpanType {
  disabled?: boolean;
  functionality: 'text' | 'link';
  labelText: string;
}

/**
 * RunningSpan Component
 *
 * This component renders a label (`labelText`) inside a container with optional animation.
 * It dynamically determines if the label text needs animation based on the width of the
 * text relative to its container. If the label text is longer than the container,
 * the animation class is applied on hover, running text animation.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {boolean} [props.disabled] - Determines if the component is disabled. When true, the
 *    cursor changes to indicate a disabled state, and interaction is limited.
 *  @param {'text' | 'link'} props.functionality - Determines the visual style of the label.
 *    - `text`: Renders a regular styled label.
 *    - `link`: Renders a label styled as a link with hover effects.
 * @param {string} props.labelText - The text to display in the label.
 *
 * @example
 * <RunningSpan labelText="This is a long text that might animate" disabled={false} />
 *
 * Key Features:
 * - **Overflow Detection**: Automatically detects if the label text exceeds the width of the
 *   container and applies the animation class if overflow occurs.
 * - **Customizable Styling**: Supports `text` or `link` functionality with hover states.
 * - **Hover Animation**: Applies a `hover:animate-slide` class for smooth sliding text animation
 *   when the text overflows the container.
 * - **Disabled State**: When `disabled` is true, the cursor changes to indicate a non-interactive
 *   state, and hover effects are disabled.
 *
 * @returns {JSX.Element} A container with the animated or static label.
 */

const RunningSpan = ({
  labelText,
  disabled,
  functionality,
}: RunningSpanType) => {
  const labelRef = useRef<HTMLSpanElement>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);

  useEffect(() => {
    const labelElement = labelRef.current;
    const divElement = divRef.current;
    if (labelElement && divElement) {
      const { width: labelWidth } = labelElement.getBoundingClientRect();
      const { width: divWidth } = divElement.getBoundingClientRect();

      if (labelWidth > divWidth) {
        // eslint-disable-next-line no-magic-numbers
        const animationDuration = (labelWidth / 50).toFixed(2); // Adjust divisor for speed
        divElement.style.setProperty(
          '--slide-duration',
          `${animationDuration}s`,
        );
        setShouldAnimate(true);
      } else {
        divElement.style.removeProperty('--slide-duration');
        setShouldAnimate(false);
      }
    }
  }, [labelText]);
  return (
    <div
      className={cn(
        'relative flex flex-grow overflow-x-hidden min-w-[30%]',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
      )}
      ref={divRef}
    >
      <div className={cn(shouldAnimate ? 'hover:animate-slide' : '')}>
        <span
          ref={labelRef}
          className={cn(
            'pb-1 label-1',
            functionality === 'link'
              ? 'text-link-primary hover:text-link-primary-hover'
              : 'text-secondary hover:text-primary',
          )}
        >
          {labelText}
        </span>
      </div>
    </div>
  );
};

export default RunningSpan;
