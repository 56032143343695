import { FC, useCallback, useEffect, useMemo } from 'react';

import { OptimizerUrlState } from '@config/optimizerUrl';
import { PlotHeader } from '@optimizer/components';
import {
  DEFAULT_PLOT_LAYOUT_CONFIG,
  PLOT_AXIS_LAYOUT_CONFIG,
  PLOT_AXIS_TITLE_LAYOUT_CONFIG,
  PLOT_MARGIN_BOTTOM,
  PLOT_MARGIN_RIGHT,
  PLOT_MARGIN_TOP,
  PLOT_PADDING,
} from '@optimizer/constants/plotConstants'; // Import from constants file
import useLinePlotData from '@optimizer/hooks/useLinePlotData';
import { visibleObjectiveMetricsState } from '@optimizer/states/metricsState';
import { plotConfigState } from '@optimizer/states/plotsStates';
import { CustomPlotMouseEvent } from '@optimizer/types/plotTypes';
import {
  formatMetricName,
  getAxisTickValues,
  getAxisTitle,
} from '@optimizer/utils/plotUtils'; // Reuse utility functions
import { Dropdown, DropdownMenuItem } from '@pxui/components/ui';
import cn from '@pxui/lib/utils';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useUrlContext } from '@contexts/UrlQueryParamsContext';

import capitalizeFirstLetter from '@utils/capitalizeFirstLetter';

import PlotlyPlot from './PlotlyPlot';

interface LinePlotProps {
  className?: string;
  onPointClick?: (e: CustomPlotMouseEvent) => void;
  plotId: string;
}

const LinePlot: FC<LinePlotProps> = ({ onPointClick, plotId, className }) => {
  const { params, updateParams } = useUrlContext<OptimizerUrlState>();

  const objectiveMetrics = useRecoilValue(visibleObjectiveMetricsState);
  const { isLoading, plotData } = useLinePlotData(plotId);
  const [plotConfig, setPlotConfig] = useRecoilState(plotConfigState(plotId));

  const syncUrlParams = useCallback(
    (thisPlotId: string, updates: Record<string, any>) => {
      const updatedPlotConfigData = params.plotConfigData?.map(
        (plot) =>
          plot.id === thisPlotId
            ? {
                ...plot, // Keep original properties
                ...updates, // Apply updates
              }
            : plot, // Keep unchanged plots in the original order
      );
      if (updatedPlotConfigData) {
        updateParams({
          plotConfigData: updatedPlotConfigData,
        });
      }
    },
    [params.plotConfigData, updateParams],
  );

  // Set default metric on component mount
  useEffect(() => {
    if (!plotConfig.metricY || !plotConfig.name) {
      const defaultMetricY = objectiveMetrics[0]?.name;
      if (defaultMetricY) {
        setPlotConfig((prevConfig) => {
          const plotToUpdate = params?.plotConfigData?.find(
            (plot) => plot.id === prevConfig.id,
          );
          if (plotToUpdate && !plotToUpdate?.metricY) {
            syncUrlParams(prevConfig.id, {
              metricY: defaultMetricY,
            });
            return {
              ...prevConfig,
              metricY: defaultMetricY,
              name: `${capitalizeFirstLetter(defaultMetricY)} Evaluation`,
            };
          }
          return {
            ...prevConfig,
            metricY: plotToUpdate?.metricY,
            name: `${capitalizeFirstLetter(plotToUpdate?.metricY as string)} Evaluation`,
          };
        });
      }
    }
  }, [
    objectiveMetrics,
    params?.plotConfigData,
    plotConfig,
    setPlotConfig,
    syncUrlParams,
  ]);

  const handleMetricChange = (newMetricY: string) => {
    setPlotConfig((prevConfig) => {
      syncUrlParams(prevConfig.id, {
        metricY: newMetricY,
      });
      return {
        ...prevConfig,
        metricY: newMetricY,
      };
    });
  };

  const memoizedLayout = useMemo(
    () => ({
      margin: {
        b: PLOT_MARGIN_BOTTOM,
        pad: PLOT_PADDING,
        r: PLOT_MARGIN_RIGHT,
        t: PLOT_MARGIN_TOP,
      },
      ...DEFAULT_PLOT_LAYOUT_CONFIG,
      xaxis: {
        ...PLOT_AXIS_LAYOUT_CONFIG,
        ...getAxisTickValues(plotData, 'x'),
        title: {
          ...PLOT_AXIS_TITLE_LAYOUT_CONFIG,
          text: 'Step',
        },
      },
      yaxis: {
        ...PLOT_AXIS_LAYOUT_CONFIG,
        ...getAxisTickValues(plotData, 'y'),
        title: {
          ...PLOT_AXIS_TITLE_LAYOUT_CONFIG,
          text: getAxisTitle(plotConfig?.metricY || '', objectiveMetrics),
        },
      },
    }),
    [objectiveMetrics, plotConfig?.metricY, plotData],
  );

  return (
    <div
      className={cn('flex flex-col relative', className, {
        'animate-pulse': isLoading,
      })}
    >
      {isLoading && (
        <div className="text-on-surface-subtle absolute left-1/2 top-1/2 z-10">
          Loading ...
        </div>
      )}
      {plotConfig.metricY && (
        <PlotHeader title="">
          {/* Dropdown for selecting metric */}
          <Dropdown
            className="title-3 text-primary"
            buttonText={
              objectiveMetrics.some(
                (metric) => metric.name === plotConfig.metricY,
              )
                ? formatMetricName(
                    capitalizeFirstLetter(plotConfig.metricY),
                    objectiveMetrics.find((m) => m.name === plotConfig.metricY)
                      ?.toMinimize ?? false,
                  )
                : capitalizeFirstLetter(plotConfig.metricY) || ''
            }
            variant="ghost"
          >
            {/* Render objective metrics with arrow symbols */}
            {objectiveMetrics.map((metric) => (
              <DropdownMenuItem
                key={metric.name}
                onClick={() => handleMetricChange(metric.name)}
                className="label-1 text-secondary cursor-pointer select-none hover:bg-hover focus:bg-focus transition-colors"
              >
                {formatMetricName(metric.name, metric.toMinimize)}
              </DropdownMenuItem>
            ))}
          </Dropdown>
          <span className="text-primary label-1">evaluation</span>
        </PlotHeader>
      )}

      {/* Render the plot */}
      {plotData && plotConfig.metricY ? (
        <PlotlyPlot
          onPointClick={onPointClick}
          data={plotData}
          layout={memoizedLayout}
          isActive={params.plotConfigData?.some(
            (plot) => plot.highlighted && plot.id === plotId,
          )}
        />
      ) : (
        <div /> // TODO: Add empty state or loader
      )}
    </div>
  );
};

export default LinePlot;
