import { OptimizationStatusEnum } from '@optimizer/schemas/allOptimizationsSchema';
import { atomFamily, atom, selector } from 'recoil';

import { Optimization } from '../types/services/allOptimizationsTypes';

// AtomFamily to store individual optimizations by their ID
export const optimizationsDataState = atomFamily<Optimization | null, string>({
  default: null,
  key: 'optimizationsDataState',
});

// Atom to store the list of optimization IDs
export const optimizationIdsState = atom<string[]>({
  default: [],
  key: 'optimizationIdsState',
});

// Selector to get all optimizations as an array
export const optimizationsState = selector<Optimization[]>({
  get: ({ get }) => {
    const optimizationIds = get(optimizationIdsState);
    return optimizationIds
      .map((id) => get(optimizationsDataState(id)))
      .filter((opt): opt is Optimization => opt !== null);
  },
  key: 'optimizationsState',
  set: ({ get, set }, newValue) => {
    if (Array.isArray(newValue) && newValue.length > 0) {
      const currentIds = get(optimizationIdsState);
      const newIds = newValue.map((opt) => opt.id);

      // Update individual optimizations if necessary
      newValue.forEach((updatedOptimization) => {
        const currentOptimization = get(
          optimizationsDataState(updatedOptimization.id),
        );

        if (currentOptimization?.status === OptimizationStatusEnum.FAILED) {
          return;
        }

        if (
          !currentOptimization ||
          currentOptimization.stepsCompleted !==
            updatedOptimization.stepsCompleted ||
          currentOptimization.status !== updatedOptimization.status
        ) {
          set(
            optimizationsDataState(updatedOptimization.id),
            updatedOptimization,
          );
        }
      });

      const combinedIds = [
        ...newIds,
        ...currentIds.filter((id) => !newIds.includes(id)),
      ];

      // Only update optimizationIdsState if there is a change
      if (
        !(
          currentIds.length === combinedIds.length &&
          currentIds.every((id, index) => id === combinedIds[index])
        )
      ) {
        set(optimizationIdsState, combinedIds);
      }
    }
  },
});

// Selector to delete an optimization by ID
export const deleteOptimizationState = selector<string>({
  get: () => {
    // This selector is write-only, so we don't need to implement get.
    throw new Error('deleteOptimizationState is a write-only selector');
  },
  key: 'deleteOptimizationState',
  set: ({ get, set }, idToDelete) => {
    // Ensure idToDelete is a string
    if (typeof idToDelete !== 'string') {
      console.error('Invalid idToDelete. Expected a string.');
      return;
    }

    // Remove the optimization data for the given ID
    set(optimizationsDataState(idToDelete), null);

    // Update the list of optimization IDs to exclude the given ID
    const currentIds = get(optimizationIdsState);
    const updatedIds = currentIds.filter((id) => id !== idToDelete);
    set(optimizationIdsState, updatedIds);
  },
});
