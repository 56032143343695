import moment from 'moment';
import { z } from 'zod';

export enum CandidateSelectionMode {
  MAX = 'MAX',
  MEDIAN = 'MEDIAN',
  MIN = 'MIN',
}

export const MetricSchema = z.object({
  name: z.string(),
  uncertainty: z.number().nullable(),
  value: z.number(),
});

export const CandidateSchema = z
  .object({
    created_at: z
      .string()
      .refine((val) => moment(val, moment.ISO_8601, true).isValid(), {
        message: 'Invalid datetime string! Must be a valid ISO 8601 date.',
      }),
    geometry_names: z.array(z.string()).nullable(),
    id: z.string().uuid(),
    is_feasible: z.boolean(),
    metrics: z.array(MetricSchema), // add type of metric here
    parameters: z.record(z.string(), z.any()),
    // add step here
  })
  .transform(({ is_feasible, created_at, geometry_names, ...rest }) => ({
    ...rest,
    createdAt: created_at,
    geometryNames: geometry_names,
    isFeasible: is_feasible,
  }));

export const StepSchema = z
  .object({
    candidates: z.array(CandidateSchema).nullable().optional(),
    selected_candidates_by_metric: z
      .record(z.string(), CandidateSchema)
      .nullable()
      .optional(),
    step: z.number(),
  })
  .superRefine((data, ctx) => {
    if (
      (data.candidates === null) ===
      (data.selected_candidates_by_metric === null)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          'Exactly one of `candidates` or `selected_candidates_by_metric` should be set',
      });
    }
  })
  .transform(({ selected_candidates_by_metric, ...rest }) => ({
    ...rest,
    selectedCandidatesByMetric: selected_candidates_by_metric,
  }));

export const GetFilteredOptimizationDataRequestSchema = z
  .object({
    candidate_selection_per_step: z
      .nativeEnum(CandidateSelectionMode)
      .nullable()
      .optional(),
    flow_run_ids: z.array(z.string().uuid()).nullable().optional(),
    include_pareto_front: z.boolean().optional(),
    max_step: z.number().nullable().optional(),
    metrics: z.array(z.string()).nullable().optional(),
    min_step: z.number().nullable().optional(),
    optimization_ids: z.array(z.string().uuid()).nullable().optional(),
    satisfied_constraints: z.array(z.string()).nullable().optional(),
    steps: z.array(z.number()).nullable().optional(),
  })
  .superRefine((data, ctx) => {
    if (!data.flow_run_ids && !data.optimization_ids) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          'At least one of `optimization_ids` or `flow_run_ids` should be set and non-empty',
      });
    }
  });
