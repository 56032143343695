import { GeometryData } from '@optimizer/types/geometryTypes';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

/**
 * Extracts the filename from a given URL by parsing the pathname.
 * @param url The full URL from which to extract the filename.
 * @returns The filename (e.g., "geometry_1.stl") or an empty string if not found.
 */
export const extractFilenameFromUrl = (url: string): string => {
  if (!url) {
    console.error('Invalid URL provided:', url);
    return '';
  }

  try {
    const parsedUrl = new URL(url);
    const { pathname } = parsedUrl;
    return pathname.split('/').pop() || '';
  } catch (error) {
    console.error('Invalid URL provided:', url, error);
    return '';
  }
};

/**
 * Exports all geometry files as a ZIP.
 * @param geometries Array of geometry data (name, filename, and URL).
 * @param candidateId ID of the candidate (for naming the ZIP file).
 */
export const exportAllGeometries = async (
  geometries: GeometryData[],
  candidateId: string,
) => {
  if (!geometries.length) return;

  const zip = new JSZip();

  try {
    const fetchPromises = geometries.map(async (geometry) => {
      const { filename, url } = geometry;
      if (url) {
        const response = await fetch(url);
        const blob = await response.blob();
        zip.file(filename, blob);
      }
    });

    await Promise.all(fetchPromises);

    const zipBlob = await zip.generateAsync({ type: 'blob' });
    // TODO: add optimization name
    saveAs(zipBlob, `geometries_${candidateId}.zip`);
  } catch (error) {
    console.error('Error downloading geometries:', error);
  }
};
