import lazyLoadHelper from '@utils/lazyLoadHelper';

import vaultImageSmall from '@assets/images/apps/vault_small.png';
import vaultImage from '@assets/images/launcher/vault.png';
import { VAULT_URL } from '@constants/common';

import { type AppConfig } from '@typing/AppConfig';

import { routes } from './routes';

// There are two versions of Vault available. Currently Vault V2 is the default.
// This array defines which namespaces should use Vault V1 instead of V2 on the homepage.
// The Vault V1 page is required to be a defined route in order to serve the model viewer.
// This is temporary and will be removed once the model viewer has been migrated to be a separate module.
const vaultV1Namespaces: string[] = [];

export const configVaultV1: AppConfig = {
  appName: 'Vault',
  description: 'Securely upload and share data',
  hidden: !vaultV1Namespaces.includes(process.env.PLATFORM_NAMESPACE || ''),
  icon: vaultImageSmall,
  id: 'vault1',
  imageSrc: vaultImage,
  navigationLinks: [],
  path: 'app/vault',
  root: lazyLoadHelper(() => import('@vault/pages/index')),
  routes,
};

export const configVaultV2: AppConfig = {
  appName: 'Vault',
  description: 'Securely upload and share data',
  external: true,
  hidden: vaultV1Namespaces.includes(process.env.PLATFORM_NAMESPACE || ''),
  id: 'vault2',
  imageSrc: vaultImage,
  navigationLinks: [], // external app don't have any child routes
  path: VAULT_URL,
  routes: [], // external app don't have any child routes
};

export default configVaultV1;
