import { Optimization } from '@optimizer/types/services/allOptimizationsTypes';
import handleError from '@optimizer/utils/handleError';
import { z } from 'zod';

import apiClient from '@services/interceptors/authInterceptor';

import { OptimizationSchema } from '../schemas/allOptimizationsSchema';

/**
 * Fetches batch optimizations by their IDs.
 * @param {string[]} optimizationIds - The list of IDs of the optimizations to fetch.
 * @returns {Promise<Optimization | undefined>} - The fetched optimizations or undefined if an error occurs.
 * @throws Will throw an error if the fetch operation fails or if the response does not match the expected schema.
 */
export const fetchBatchOptimizations = async (
  optimizationIds: string[],
): Promise<Optimization[] | undefined> => {
  try {
    const response = await apiClient.post(
      `${process.env.PLATFORM_API_URL}/v0/optimizer/optimizations:batchGet`,
      {
        optimization_ids: optimizationIds,
      },
    );

    const parsedResponse = z
      .array(OptimizationSchema)
      .parse(response.data.optimizations);

    return parsedResponse;
  } catch (error) {
    handleError(error, `fetch optimization with IDs ${optimizationIds}`);
    return undefined;
  }
};

export default fetchBatchOptimizations;
