import { FC, useCallback, useEffect } from 'react';

import { OptimizerUrlState } from '@config/optimizerUrl';
import {
  availableStepsRangeState,
  stepsRangeFilterState,
  stepsSpacingFilterState,
} from '@optimizer/states/selectedStepsState';
import {
  ListSection,
  ListHeader,
  TextInput,
  InfoTooltip,
} from '@pxui/components/ui';
import { RangeLabel } from '@pxui/components/ui/textInput/rangeLabel';
import { InfoIcon } from 'lucide-react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useUrlContext } from '@contexts/UrlQueryParamsContext';

const StepsSpacing: FC = () => {
  const { params, updateParams } = useUrlContext<OptimizerUrlState>();

  const [stepsSpacingFilter, setStepsSpacingFilter] = useRecoilState(
    stepsSpacingFilterState,
  );
  const stepsRangeFilter = useRecoilValue(stepsRangeFilterState);
  const availableStepsRange = useRecoilValue(availableStepsRangeState);

  const rangeMax = stepsRangeFilter[1];
  const availableMax = availableStepsRange[1];
  const displayMax = rangeMax < availableMax ? rangeMax : availableMax;
  const defaultSpacing = Math.ceil(availableMax / 100);

  const handleStepSpacingFilterUpdate = useCallback(
    (value?: number) => {
      // Only update if spacing is different from the current one
      if (params?.steps?.spacing !== value) {
        updateParams({
          steps: {
            ...params.steps,
            spacing: value,
          },
        });
      }
    },
    [params.steps, updateParams],
  );

  // initialise the url with default params
  useEffect(() => {
    if (!params.steps?.spacing) {
      handleStepSpacingFilterUpdate(stepsSpacingFilter || defaultSpacing);
    } else if (stepsSpacingFilter !== params.steps.spacing) {
      setStepsSpacingFilter(params.steps.spacing);
    }
  }, [
    defaultSpacing,
    handleStepSpacingFilterUpdate,
    params.steps?.spacing,
    setStepsSpacingFilter,
    stepsSpacingFilter,
  ]);

  return (
    <ListSection>
      <ListHeader
        title="Steps Spacing"
        icon={
          <InfoTooltip
            className="max-w-60"
            description="Select the spacing between the steps shown. For example, for a step range 1-20, setting the spacing to 4 will show steps 4, 8, 12, 16, 20"
          >
            <InfoIcon width={20} height={20} className="cursor-pointer" />
          </InfoTooltip>
        }
      />
      <RangeLabel left={availableStepsRange[0]} right={displayMax}>
        <TextInput
          id="steps-spacing"
          type="number"
          className="bg-surface-container-low"
          min={availableStepsRange[0]}
          max={displayMax}
          defaultValue={
            params.steps?.spacing || stepsSpacingFilter || defaultSpacing
          }
          debounce={800}
          onChange={(e) => {
            setStepsSpacingFilter(Number(e.target.value ?? 1));
            handleStepSpacingFilterUpdate(Number(e.target.value));
          }}
        />
      </RangeLabel>
    </ListSection>
  );
};

export default StepsSpacing;
