import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Selected: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    className={className}
  >
    <g filter="url(#filter0_i_743_10922)">
      <rect
        x="3.5"
        y="3.5"
        width="13"
        height="13"
        rx="6.5"
        fill="none"
        stroke="currentColor"
        className={className}
      />
      <circle cx="10" cy="10" r="4" fill="currentColor" className={className} />
    </g>
    <defs>
      <filter
        id="filter0_i_743_10922"
        x="0"
        y="0"
        width="20"
        height="20"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="1"
          operator="erode"
          in="SourceAlpha"
          result="effect1_innerShadow_743_10922"
        />
        <feOffset />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_743_10922"
        />
      </filter>
    </defs>
  </svg>
);

export default Selected;
