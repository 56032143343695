import type { FC, ReactNode } from 'react';

import cn from '@pxui/lib/utils';

interface PlotHeaderProps {
  children: ReactNode;
  className?: string;
  title: string;
}

const PlotHeader: FC<PlotHeaderProps> = ({ children, title, className }) => (
  <div
    className={cn(
      'py-2 pl-6 pr-10 flex items-center justify-start w-full ',
      className,
    )}
  >
    <span className="title-3 text-primary pr-4 whitespace-nowrap">{title}</span>
    {children}
  </div>
);

export default PlotHeader;
