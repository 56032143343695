import { useEffect, useMemo, useState } from 'react';

import fetchFilteredOptimizations from '@optimizer/services/fetchFilteredOptimizations';
import candidatesState from '@optimizer/states/candidatesState';
import { visibleObjectiveMetricsState } from '@optimizer/states/metricsState';
import { plotConfigState } from '@optimizer/states/plotsStates';
import selectedCandidateState from '@optimizer/states/selectedCandidateState';
import selectedConstraintsState from '@optimizer/states/selectedConstraintsState';
import { visibleSelectedOptimizationsState } from '@optimizer/states/selectedOptimizationsStates';
import {
  getSubsampledSteps,
  stepsRangeFilterState,
} from '@optimizer/states/selectedStepsState';
import {
  Candidate,
  GetFilteredOptimizationDataRequest,
} from '@optimizer/types/services/filteredOptimizationsTypes';
import transformOptimizationDataToParallelPlotData from '@optimizer/utils/transformOptimizationDataToParallelPlotData';
import { PlotData } from 'plotly.js';
import { useRecoilCallback, useRecoilValue } from 'recoil';

/**
 * Custom hook to fetch and transform plot content data for Plotly parallel plots.
 * This hook ensures the data is ready for rendering based on selected optimizations and metrics.
 *
 * @param {string} plotId - The ID of the plot for which data needs to be fetched and transformed.
 * @returns { isLoading: boolean; plotData: Partial<PlotData>[] }  The transformed data ready for Plotly rendering and loading state.
 */
export const useParallelPlotData = (
  plotId: string,
): { isLoading: boolean; plotData: Partial<PlotData>[] } => {
  const plotConfig = useRecoilValue(plotConfigState(plotId));
  const visibleOptimizations = useRecoilValue(
    visibleSelectedOptimizationsState,
  );
  const metrics = useRecoilValue(visibleObjectiveMetricsState);
  const selectedConstraints = useRecoilValue(selectedConstraintsState);
  const selectedCandidate = useRecoilValue(selectedCandidateState);
  const [plotData, setPlotData] = useState<Partial<PlotData>[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const stepsRangeFilter = useRecoilValue(stepsRangeFilterState);
  const subsampledSteps = useRecoilValue(getSubsampledSteps);

  const setCandidates = useRecoilCallback(
    ({ set }) =>
      (candidates: Record<string, Candidate>) => {
        Object.entries(candidates).forEach(([id, candidate]) => {
          set(candidatesState(id), candidate);
        });
      },
    [],
  );

  // Memoizing effect dependencies with useMemo
  const plotDataRequestParams: GetFilteredOptimizationDataRequest =
    useMemo(() => {
      return {
        max_step: stepsRangeFilter[1],
        metrics: plotConfig.selectedMetrics,
        min_step: stepsRangeFilter[0],
        optimization_ids: [plotConfig?.selectedOptimization?.id as string],
        satisfied_constraints: selectedConstraints?.length
          ? selectedConstraints
          : undefined,
        steps: subsampledSteps,
      };
    }, [
      plotConfig.selectedMetrics,
      plotConfig?.selectedOptimization?.id,
      selectedConstraints,
      stepsRangeFilter,
      subsampledSteps,
    ]);

  useEffect(() => {
    const getPlotData = async () => {
      if (
        !plotConfig?.selectedMetrics?.length ||
        !plotConfig.selectedOptimization
      )
        return;

      setIsLoading(true);

      try {
        const data = await fetchFilteredOptimizations(plotDataRequestParams);
        setIsLoading(false);
        if (!data) return;

        const [transformedData, candidates] =
          transformOptimizationDataToParallelPlotData(
            plotConfig.selectedOptimization,
            data.optimizationData,
            plotConfig?.selectedMetrics,
            plotConfig?.selectedParams ?? [],
            selectedCandidate?.id || null,
          );

        setPlotData(transformedData);
        setCandidates(candidates);
      } catch (error) {
        console.error('Failed to fetch and set plot content:', error);
        // TODO: Handle error using a toast
      }
    };

    getPlotData();
  }, [
    plotConfig,
    visibleOptimizations,
    metrics,
    plotId,
    setCandidates,
    selectedCandidate?.id,
    plotDataRequestParams,
  ]);

  return { isLoading, plotData };
};

export default useParallelPlotData;
