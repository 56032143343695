import { FC, memo } from 'react';

import pages from '@optimizer/pages';
import { selectedOptimizationsStaticDataState } from '@optimizer/states/selectedOptimizationsStates';
import { useRecoilValue } from 'recoil';

import { Section } from '@components/index';

import useAppNavigate from '@hooks/useAppNavigation';

import OptimizationManagerSectionHeader from '../OptimizationManagerTable/OptimizationManagerSectionHeader';
import OptimizationManagerTable from '../OptimizationManagerTable/OptimizationManagerTable';

const OptimizationHistory: FC = () => {
  const navigate = useAppNavigate();
  const selectedOptimizations = useRecoilValue(
    selectedOptimizationsStaticDataState,
  );

  const handleInspectButtonClick = () => {
    navigate(pages.inspector.path);
  };

  return (
    <Section className="surface-0 min-w-[50%]">
      <OptimizationManagerSectionHeader
        onInspectButtonClick={handleInspectButtonClick}
        selectedOptimizationsCount={selectedOptimizations.length}
      />
      <OptimizationManagerTable />
    </Section>
  );
};

export default memo(OptimizationHistory);
