import {
  FC,
  memo,
  useRef,
  useCallback,
  useMemo,
  useEffect,
  useState,
} from 'react';

import { OptimizerUrlState } from '@config/optimizerUrl';
import { OPTIMIZATION_MESSAGING } from '@flow/constants';
import useOptimizations from '@optimizer/hooks/useOptimizations';
import { OptimizationStatusEnum } from '@optimizer/schemas/allOptimizationsSchema';
import { selectedOptimizationsStaticDataState } from '@optimizer/states/selectedOptimizationsStates';
import { Optimization } from '@optimizer/types/services/allOptimizationsTypes';
import {
  Checkbox,
  Spinner,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableTHead,
  useToast,
} from '@pxui/components/ui';
import DownArrowAlt from '@pxui/components/ui/icons/DownArrowAlt';
import cn from '@pxui/lib/utils';
import { useRecoilState } from 'recoil';

import EmptyStateChildren from '@components/EmptyState/EmptyStateChildren';
import { EmptyState } from '@components/index';

import { useUrlContext } from '@contexts/UrlQueryParamsContext';

import OptimizationManagerTableRow from './OptimizationManagerTableRow';

const layoutClasses = 'flex flex-col h-[calc(100vh-121px)] overflow-y-auto';

const MAX_SELECTION = 12; // Maximum number of optimizations that can be selected

const isSortedAscending = (arr: string[]) =>
  arr.every(
    (val, i, array) => i === 0 || new Date(array[i - 1]) <= new Date(val),
  );

const isSortedDescending = (arr: string[]) =>
  arr.every(
    (val, i, array) => i === 0 || new Date(array[i - 1]) >= new Date(val),
  );

const OptimizationManagerTable: FC = memo(() => {
  const { params, updateParams } = useUrlContext<OptimizerUrlState>();

  const { optimizations, fetchMore, isLoading, error } = useOptimizations();
  const { toast } = useToast();
  const [isMoreOptAvailable, setIsMoreOptAvailable] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [hasReachedLastRow, setHasReachedLastRow] = useState<boolean>(false);
  const [isSorted, setIsSorted] = useState<boolean>(
    isSortedDescending(optimizations.map((el) => el.createdAt)),
  ); // initial sorting
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | null>(
    isSorted ? 'desc' : null,
  );

  const sortedOptimizations = useMemo(() => {
    if (
      !sortOrder ||
      (sortOrder === 'desc' &&
        isSortedDescending(optimizations.map((el) => el.createdAt))) ||
      (sortOrder === 'asc' &&
        isSortedAscending(optimizations.map((el) => el.createdAt)))
    )
      return optimizations;

    return [...optimizations].sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
  }, [optimizations, sortOrder]);

  useEffect(() => {
    setIsSorted(Boolean(sortOrder));
  }, [sortOrder]);

  const handleSortClick = () => {
    setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
  };

  useEffect(() => {
    if (error) {
      toast({
        description: error,
        title: 'Error',
        variant: 'error',
      });
    }
  }, [error, toast]);

  /**
   * IntersectionObserver instance used to observe when the last element
   * in the table comes into view, triggering more data to be fetched.
   */
  const observer = useRef<IntersectionObserver | null>(null);

  /**
   * Callback ref function to attach the IntersectionObserver to the last
   * table row element for triggering infinite scroll.
   */
  const loadMoreRef = useCallback(
    (node: HTMLElement | null) => {
      if (isLoading) return;

      // Disconnect the previous observer if there's any
      if (observer.current) observer.current.disconnect();

      // Create a new IntersectionObserver instance
      observer.current = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting) {
          setIsLoadingMore(true);
          setHasReachedLastRow(true); // User has reached the last row
          const newOptimizationsLength = await fetchMore();
          if (!newOptimizationsLength || newOptimizationsLength === 0) {
            setIsLoadingMore(false);
            setIsMoreOptAvailable(false);
          }
        } else {
          setHasReachedLastRow(false);
        }
      });

      // Start observing the target node if it's available
      if (node) observer.current.observe(node);
      setIsLoadingMore(false);
    },
    [isLoading, fetchMore],
  );

  const [selectedOptimizations, setSelectedOptimizations] = useRecoilState(
    selectedOptimizationsStaticDataState,
  );

  const allowedOptimizations = useMemo(
    () =>
      optimizations.filter(
        (opt) =>
          opt.status === OptimizationStatusEnum.SUCCEEDED ||
          opt.status === OptimizationStatusEnum.RUNNING,
      ),
    [optimizations],
  );

  const selectedSucceededOptimizations = useMemo(
    () =>
      allowedOptimizations.filter(({ id }) =>
        selectedOptimizations.some((opt) => opt.id === id),
      ),
    [allowedOptimizations, selectedOptimizations],
  );

  const handleSelect = useCallback(
    (optimization: Optimization) => {
      if (params?.optimizationIds) {
        // clear url to make sure it does not interfere with new selections
        updateParams({ optimizationIds: undefined });
      }
      if (
        selectedOptimizations.some((opt) => opt.id === optimization.id) ||
        selectedOptimizations.length < MAX_SELECTION
      ) {
        setSelectedOptimizations((prev) => {
          const newState = prev.some((opt) => opt.id === optimization.id)
            ? prev.filter((opt) => opt.id !== optimization.id)
            : [...prev, optimization];
          updateParams({ optimizationIds: newState.map((el) => el.id) });
          return newState;
        });
      } else {
        toast({
          description: `You can only select up to ${MAX_SELECTION} optimizations.`,
          title: 'Selection Limit Reached',
        });
      }
    },
    [
      params.optimizationIds,
      selectedOptimizations,
      setSelectedOptimizations,
      toast,
      updateParams,
    ],
  );

  const handleAllSelect = useCallback(() => {
    if (selectedOptimizations.length) {
      setSelectedOptimizations([]);
    } else if (allowedOptimizations.length > MAX_SELECTION) {
      const firstMaxOptimizations = allowedOptimizations.slice(
        0,
        MAX_SELECTION,
      );
      setSelectedOptimizations(firstMaxOptimizations);
      toast({
        description: `Only the first ${MAX_SELECTION} optimizations have been selected.`,
        title: 'Selection Limit Reached',
      });
    } else {
      setSelectedOptimizations(allowedOptimizations);
    }
  }, [
    allowedOptimizations,
    selectedOptimizations.length,
    setSelectedOptimizations,
    toast,
  ]);

  if (optimizations.length === 0) {
    return (
      <EmptyState className="h-full">
        {!isLoading && (
          <EmptyStateChildren
            {...OPTIMIZATION_MESSAGING.noOptimizationsAvailable()}
          />
        )}
        {isLoading && (
          <EmptyStateChildren {...OPTIMIZATION_MESSAGING.loading()} />
        )}
        {/* for displaying if there has been an error loading all optimizations  */}
        {/* {isError && (
          <EmptyStateChildren {...RUNS_MESSAGING.error(isInHomePage)} />
        )} */}
      </EmptyState>
    );
  }

  return (
    <div className={cn(layoutClasses)}>
      <Table data-testid="optimizations-table">
        <TableTHead className="bg-surface-0">
          <TableRow className="border-01-subtle">
            <TableHead className="w-[52px]">
              <Checkbox
                indeterminate={
                  selectedSucceededOptimizations?.length !==
                  allowedOptimizations?.length
                }
                checked={!!selectedOptimizations?.length}
                onCheckedChange={handleAllSelect}
              />
            </TableHead>
            <TableHead
              className={cn(
                'flex flex-row items-center min-w-[180px] cursor-pointer',
                isSorted ? 'text-on-surface border-b' : '',
              )}
              key="created_time_header"
              onClick={handleSortClick}
            >
              <div>Created Time</div>
              {isSorted && (
                <DownArrowAlt
                  className={`ml-1 text-blue-450 transition-transform ${
                    sortOrder === 'asc' ? 'rotate-180' : ''
                  }`}
                />
              )}
            </TableHead>
            <TableHead className="min-w-[260px]">Name</TableHead>
            <TableHead className="min-w-[260px]">Description</TableHead>
            <TableHead className="w-[260px]">Metrics</TableHead>
            <TableHead>Progress</TableHead>
            <TableHead className="w-16" />
          </TableRow>
        </TableTHead>
        <TableBody>
          {sortedOptimizations.map((optimization, index) => (
            <OptimizationManagerTableRow
              key={optimization.id}
              optimization={optimization}
              selected={selectedOptimizations.some(
                (opt) => opt.id === optimization.id,
              )}
              onSelect={handleSelect}
              ref={index === optimizations.length - 1 ? loadMoreRef : null}
            />
          ))}
        </TableBody>
      </Table>
      {hasReachedLastRow && (
        <div className="flex w-full label-1 paragraph-1 pb-4 justify-center text-primary pt-4">
          {isLoadingMore && <Spinner />}
          {!isLoadingMore &&
            !isMoreOptAvailable &&
            'No more optimizations available'}
        </div>
      )}
    </div>
  );
});

export default OptimizationManagerTable;
