import {
  FC,
  isValidElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import { ListSection, TextInput } from '@pxui/components/ui';

type ExcludePropertyProps = {
  items: ReactNode[];
  handleItemClick: (props: string) => void;
};

export const ExcludeProperty: FC<ExcludePropertyProps> = ({
  items = [],
  handleItemClick,
}) => {
  const [search, setSearch] = useState('');
  const [exclude, setExclude] = useState('');

  const excludeInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (excludeInputRef.current) {
      const input = excludeInputRef.current;
      input.scrollLeft = input.scrollWidth;

      input.setSelectionRange(input.value.length, input.value.length);
    }
  }, [exclude]);

  const filterFn = (item: ReactNode) => {
    if (!isValidElement(item)) {
      return;
    }

    const itemLabel = item.props.label;
    const exclusions = exclude.split(',');

    const hasCommonLetters = exclusions.some((query) => {
      const trimmed = query.trim();

      if (trimmed && trimmed.length >= 2) {
        return itemLabel.includes(trimmed);
      }
    });

    if (hasCommonLetters) {
      return;
    }

    return itemLabel.toLowerCase().includes(search.toLowerCase());
  };

  const filteredItems = items.filter(filterFn);

  const renderItems = () => {
    const areAllItemsExcluded = !!exclude && !filteredItems.length;

    if (!items.length || areAllItemsExcluded) {
      return (
        <div className="text-on-surface-subtle text-xs text-center">
          No items
        </div>
      );
    }

    if (!filteredItems.length) {
      return (
        <div className="text-on-surface-subtle text-xs text-center">
          No items for query: &quot;{search}&quot;
        </div>
      );
    }

    return filteredItems.map((item, index) => {
      if (!isValidElement(item)) {
        return;
      }

      return (
        <button key={index} onClick={() => handleItemClick(item.props.label)}>
          {item}
        </button>
      );
    });
  };

  return (
    <ListSection>
      <div className="flex flex-col gap-2 mb-2">
        <TextInput
          id="search-property"
          startIcon="search"
          placeholder="Search all metrics"
          debounce={300}
          onChange={(e) => setSearch(e.target.value)}
        />
        <TextInput
          id="exclude-property"
          startIcon="playlist_remove"
          placeholder="Exclude"
          // TODO - fix debounce is not working properly
          debounce={300}
          onChange={(e) => setExclude(e.target.value)}
          ref={excludeInputRef}
        />
      </div>
      {renderItems()}
    </ListSection>
  );
};
