import { useMemo } from 'react';

import { comparisonCandidatesPresignedUrls } from '@optimizer/states/candidatesPresignedUrlsState';
import { comparisonState } from '@optimizer/states/comparisonState';
import { extractFilenameFromUrl } from '@optimizer/utils/geometryUtils';
import { isEmpty } from 'lodash';
import { useRecoilValue } from 'recoil';

import { MeshViewerWasm } from '@components/index';
import {
  numViewports,
  MeshConfig,
} from '@components/vtk-viewer-wasm/hooks/useMeshVisualiser';

const GRID_ITEM_WIDTH = 440;
const GAP_WIDTH = 16;

const ComparisonGeometries = () => {
  const comparison = useRecoilValue(comparisonState);
  const geometryNames = comparison.map(
    (el) => el?.candidate?.geometryNames?.[0] || '',
  );

  const comparisonPresignedUrls = useRecoilValue(
    comparisonCandidatesPresignedUrls,
  );

  const meshes: MeshConfig[] = useMemo(() => {
    // when url is not available nothing in displayed in the viewer, due to comparisonPresignedUrls=[{},{}]
    // but if at least one url is available we want to display all,
    // otherwise they wont be aligned properly with the metadata cards below
    const areAllUrlsEmpty = comparisonPresignedUrls.every(
      (obj) => Object.keys(obj).length === 0,
    );
    if (
      comparison.length > 0 &&
      comparisonPresignedUrls.length > 0 &&
      !areAllUrlsEmpty
    ) {
      return comparisonPresignedUrls.map((item, index) => {
        const url = item[geometryNames[index]] || '';
        return {
          filename: extractFilenameFromUrl(url),
          url,
          viewportId: index % numViewports('2x2'),
        };
      });
    }

    return [];
  }, [comparisonPresignedUrls, geometryNames, comparison]);

  const sectionWidth =
    comparison.length * GRID_ITEM_WIDTH + GAP_WIDTH * (comparison.length - 1);

  if (isEmpty(comparisonPresignedUrls)) {
    return null;
  }

  return (
    meshes.length > 0 && (
      <section
        key={meshes.map((m) => m.url).toString()}
        className="h-96 relative m-4 rounded-md shadow-lg overflow-auto min-h-16"
        style={{ width: `${sectionWidth}px` }}
      >
        <MeshViewerWasm
          meshes={meshes}
          showColorLegend={false}
          sidebar={false}
        />
      </section>
    )
  );
};

export default ComparisonGeometries;
