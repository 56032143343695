import { FC, useCallback, useEffect } from 'react';

import { OptimizerUrlState } from '@config/optimizerUrl';
import { visibleConstraintMetricsState } from '@optimizer/states/metricsState';
import { selectedConstraintsState } from '@optimizer/states/selectedConstraintsState';
import { ListSection, ListHeader, InfoTooltip } from '@pxui/components/ui';
import { InfoIcon } from 'lucide-react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useUrlContext } from '@contexts/UrlQueryParamsContext';

import ConstraintsListItem from './ConstraintsListItem';

const ConstraintsList: FC = () => {
  const { params, updateParams } = useUrlContext<OptimizerUrlState>();

  const visibleConstraints = useRecoilValue(visibleConstraintMetricsState);

  const [selectedConstraints, setSelectedConstraints] = useRecoilState(
    selectedConstraintsState,
  );

  const handleConstraintToggle = useCallback(
    (newName: string) => {
      setSelectedConstraints((prev) => {
        // add newly selected constraint if not already selected
        if (!prev?.includes(newName)) {
          updateParams({
            satisfiedConstraints: [
              ...(params?.satisfiedConstraints ?? []),
              newName,
            ],
          });
          return [...(prev ?? []), newName];
        }

        // remove if already selected
        updateParams({
          satisfiedConstraints: params?.satisfiedConstraints?.filter(
            (constraintName) => constraintName !== newName,
          ),
        });
        return [
          ...(prev?.filter((constraintName) => constraintName !== newName) ??
            []),
        ];
      });
    },
    [params?.satisfiedConstraints, setSelectedConstraints, updateParams],
  );

  // only initialise the url and state with default params if they are not set in the initial url
  useEffect(() => {
    if (!params?.satisfiedConstraints) {
      updateParams({
        satisfiedConstraints: visibleConstraints.map((el) => el.name),
      });
      setSelectedConstraints(visibleConstraints.map((el) => el.name));
    } else {
      setSelectedConstraints(params.satisfiedConstraints);
    }
  }, [
    params?.satisfiedConstraints,
    setSelectedConstraints,
    updateParams,
    visibleConstraints,
  ]);

  return (
    <ListSection>
      <ListHeader
        title="Satisfied Constraints"
        icon={
          <InfoTooltip description="Hide candidates that don't satisfy selected constraints.">
            <InfoIcon
              width={20}
              height={20}
              className="text-on-surface-subtle cursor-pointer"
            />
          </InfoTooltip>
        }
      />
      {visibleConstraints.length > 0 ? (
        visibleConstraints.map(({ name }) => (
          <ConstraintsListItem
            key={name}
            name={name}
            handleClick={handleConstraintToggle}
            selected={selectedConstraints?.includes(name)}
          />
        ))
      ) : (
        <div className="text-sm text-gray-500 p-4">
          No constraints available.
        </div>
      )}
    </ListSection>
  );
};

export default ConstraintsList;
