import type { FC } from 'react';

import { Icon } from '@pxui/components/ui';
import { NeutralIcon } from '@pxui/components/ui/icons';

interface DiffPrefixProps {
  value: number;
  withIcon?: boolean;
}

const DiffPrefix: FC<DiffPrefixProps> = ({ value, withIcon }) => {
  if (value > 0) {
    return withIcon ? <Icon name="arrow_drop_up" size="large" /> : '+';
  }
  if (value < 0) {
    return withIcon ? <Icon name="arrow_drop_down" size="large" /> : '-';
  }
  return withIcon ? <NeutralIcon size="large" /> : '';
};

export { DiffPrefix, type DiffPrefixProps };
