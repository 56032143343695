import { type FC } from 'react';

import toReadableText from '@optimizer/utils/convertKebabAndCamelToReadable';
import cn from '@pxui/lib/utils';

import CompareValue from './CompareValue';

interface InfoSectionItemProps {
  className?: string;
  isBaselineCandidate?: boolean;
  parameters: { name: string; value: number }[];
  parametersToCompare?: { name: string; value: number }[];
  title: string;
}

const titleClasses = 'label-1-strong text-on-surface';
const labelClasses = 'label-1 text-on-surface-subtle';
const valueClasses = 'code-1 text-on-surface';

const InfoSectionItem: FC<InfoSectionItemProps> = ({
  className,
  isBaselineCandidate = false,
  parameters,
  parametersToCompare,
  title,
}) => {
  return (
    <div className={cn('flex flex-col', className)}>
      <div className="p-2">
        <div className={titleClasses}>{title}</div>
      </div>
      {parameters.map((parameter, index) => (
        <div className="flex justify-between px-2 py-1" key={parameter.name}>
          <div className={labelClasses}>{toReadableText(parameter.name)}</div>
          <div className="flex gap-4">
            <div className={valueClasses}>
              {parameter.value.toExponential(2)}
            </div>
            <CompareValue
              baseValue={parametersToCompare?.[index]?.value || 0}
              currentValue={parameter.value}
              index={index}
              isBaselineCandidate={isBaselineCandidate}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default InfoSectionItem;
