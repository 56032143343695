import { forwardRef, useState } from 'react';

import { yaml } from '@codemirror/lang-yaml';
import { Diagnostic, linter } from '@codemirror/lint';
import { EditorView } from '@codemirror/view';
import { YAML_CODE_EDITOR_MESSAGING } from '@flow/constants';
import cn from '@pxui/lib/utils';
import { createTheme } from '@uiw/codemirror-themes';
import CodeMirror, {
  type ReactCodeMirrorProps,
  type ReactCodeMirrorRef,
} from '@uiw/react-codemirror';
import parser, { YAMLException } from 'js-yaml';

const myTheme = createTheme({
  settings: {
    background: '#161616',
    caret: '#fff',
    foreground: '#75baff',
    gutterBackground: '#161616',
    gutterBorder: 'transparent',
    lineHighlight: 'transparent',
    selection: '#036dd626',
    selectionMatch: '#036dd626',
  },
  styles: [],
  theme: 'dark',
});

export interface CodeAreaProps extends ReactCodeMirrorProps {
  externalErrors?: string[];
  externalWarnings?: string[];
  lintYaml?: boolean;
}

const CodeArea = forwardRef<ReactCodeMirrorRef, CodeAreaProps>(
  (
    {
      className,
      onChange,
      readOnly,
      value,
      lintYaml = true,
      height = 'auto',
      externalErrors = [],
      externalWarnings = [],
      placeholder = 'There are no parameters defined for this pipeline...',
    },
    ref,
  ) => {
    const [errorMsg, setErrorMsg] = useState<string | null>(null);

    const yamlLinter = linter((view) => {
      const diagnostics: Diagnostic[] = [];
      const document = view.state.doc;

      try {
        parser.load(document.toString());
        setErrorMsg(null);
      } catch (error: any) {
        if (error instanceof YAMLException) {
          const lineNumber = error.mark.line;
          const line = document.line(lineNumber + 1);
          const { to, from } = line;
          const severity = 'error';

          const errorMessage = error.message;
          const bracketIndex = errorMessage.indexOf(')');
          const errorSubstring = errorMessage
            .substring(0, bracketIndex + 1)
            .trim();

          const errorResult =
            errorSubstring.charAt(0).toUpperCase() + errorSubstring.slice(1);

          diagnostics.push({
            from,
            message: errorResult,
            severity,
            to,
          });

          setErrorMsg(errorResult);
        }
      }
      return diagnostics;
    });

    const overrideClasses =
      '[&>*]:text-white [&>*]:code-1 [&_.cm-editor]:rounded [&_.cm-content]:p-0 [&_.cm-focused]:outline-none [&_.cm-scroller]:rounded [&_.cm-scroller]:p-2 [&_.cm-line]:p-0 [&_.cm-tooltip-lint]:hidden [&_.cm-placeholder]:text-placeholder [.cm-gutterElement]:pr-1 [&_.cm-lineNumbers]:pr-2';
    const lineErrorClasses =
      '[&_.cm-line]:relative [&_.cm-lintRange-error]:bg-none [&_.cm-lintRange-error]:before:bg-danger/25 [&_.cm-lintRange-error]:before:w-[calc(100%+16px)] [&_.cm-lintRange-error]:before:h-full [&_.cm-lintRange-error]:before:-left-2 [&_.cm-lintRange-error]:before:top-0 [&_.cm-lintRange-error]:before:absolute';

    const codeAreaClasses = cn(className, overrideClasses, lineErrorClasses, {
      'pointer-events-none opacity-50': readOnly,
    });

    const getErrorsClasses = (condition: boolean) =>
      cn('text-error label-2 flex transition-opacity justify-end text-end', {
        'opacity-0': !condition,
        'opacity-100': condition,
        'overflow-wrap': 'break-word',
        'white-space': 'pre-wrap',
      });

    const getWarningClasses = (condition: boolean) =>
      cn(
        'text-auxiliary-warning label-2 flex transition-opacity justify-end text-end',
        {
          'opacity-0': !condition,
          'opacity-100': condition,
        },
      );

    return (
      <div
        className={cn(
          'flex flex-row gap-2 w-full h-full relative border border-solid transition-colors rounded',
          {
            'border-auxiliary-error': errorMsg || externalErrors.length,
            'border-auxiliary-warning':
              externalWarnings.length && !externalErrors.length,
            'border-transparent':
              !errorMsg && !externalErrors.length && !externalWarnings.length,
            'pointer-events-none opacity-50': readOnly,
          },
        )}
      >
        <div className="flex-grow h-full overflow-y-auto">
          <CodeMirror
            className={codeAreaClasses}
            readOnly={readOnly}
            value={value}
            theme={myTheme}
            placeholder={placeholder}
            height={height}
            basicSetup={{
              foldGutter: false,
              lineNumbers: true,
            }}
            contentEditable={readOnly}
            extensions={
              lintYaml
                ? [yaml(), yamlLinter, EditorView.lineWrapping, myTheme]
                : undefined
            }
            onChange={onChange}
            ref={ref}
            data-testid="code-area"
          />
        </div>
        <div
          className={cn(
            'z-10 flex flex-col w-full gap-2 absolute bottom-0 left-0 bg-gray-950 px-2',
            {
              'opacity-0':
                !errorMsg || !externalErrors.length || !externalWarnings.length,
              'opacity-90':
                !!errorMsg ||
                externalErrors.length > 0 ||
                externalWarnings.length > 0,
            },
          )}
        >
          <div className={getErrorsClasses(!!errorMsg)}>{errorMsg}</div>
          <div className={getErrorsClasses(externalErrors.length > 0)}>
            {externalErrors?.map((error) => (
              <span key={error}>
                {
                  YAML_CODE_EDITOR_MESSAGING.parameterNotConfigured(
                    error,
                    'published_parameters',
                  ).message
                }
              </span>
            ))}
          </div>
          <div className={getWarningClasses(externalWarnings.length > 0)}>
            {externalWarnings?.map((error) => (
              <span key={error}>
                {
                  YAML_CODE_EDITOR_MESSAGING.missingParameterValue(
                    error,
                    'published_parameters',
                  ).message
                }
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  },
);

CodeArea.displayName = 'CodeArea';

export { CodeArea };
