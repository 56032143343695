import { useCallback, type FC } from 'react';

import { OptimizerUrlState } from '@config/optimizerUrl';
import { plotsStaticDataState } from '@optimizer/states/plotsStates';
import { PlotType } from '@optimizer/types/plotStateTypes';
import {
  Dropdown,
  DropdownMenuItem,
  DropdownMenuGroup,
} from '@pxui/components/ui';
import { useSetRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import { useUrlContext } from '@contexts/UrlQueryParamsContext';

const AddPlotButton: FC = () => {
  const setPlotsStaticData = useSetRecoilState(plotsStaticDataState);
  const { updateParams } = useUrlContext<OptimizerUrlState>();

  const addPlot = useCallback(
    (type: PlotType) => {
      const newId = uuidv4();
      setPlotsStaticData((existingPlots) => {
        updateParams({
          plotConfigData: [...existingPlots, { id: newId, type }],
        });
        return [...existingPlots, { id: newId, type }];
      });
    },
    [setPlotsStaticData, updateParams],
  );

  const handleAddLinePlot = () => {
    addPlot(PlotType.LINE);
  };

  const handleAddScatterPlot = () => {
    addPlot(PlotType.SCATTER);
  };

  const handleAddParallelPlot = () => {
    addPlot(PlotType.PARALLEL);
  };

  return (
    <Dropdown
      buttonText="Add Plot"
      variant="primary"
      contentClassName="mt-1 mr-6 rounded-md right-0"
    >
      <DropdownMenuGroup>
        <DropdownMenuItem inset onClick={handleAddLinePlot}>
          Evaluation
        </DropdownMenuItem>
        <DropdownMenuItem inset onClick={handleAddScatterPlot}>
          Scatter
        </DropdownMenuItem>
        <DropdownMenuItem inset onClick={handleAddParallelPlot}>
          Parallel
        </DropdownMenuItem>
      </DropdownMenuGroup>
    </Dropdown>
  );
};

export default AddPlotButton;
