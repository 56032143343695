import { useEffect, useState } from 'react';

import fetchPresignedUrl from '@optimizer/services/fetchPresignedUrl';
import candidatesPresignedUrlsState from '@optimizer/states/candidatesPresignedUrlsState';
import { useSetRecoilState } from 'recoil';

interface UseGetPresignedUrlProps {
  candidateId: string;
  geometryNames: string[];
  optimizationId: string;
}

/**
 * Hook to fetch presigned URLs for a candidate's geometry names and store them in Recoil state.
 * Uses `Promise.all` to handle multiple asynchronous calls concurrently.
 *
 * @param {string} candidateId - The ID of the candidate.
 * @param {string[]} geometryNames - Array of geometry names for the candidate.
 * @param {string} optimizationId - The ID of the optimization.
 *
 * @returns {{ isLoading: boolean }} - Returns whether URLs are being fetched.
 */
const useGetPresignedUrl = ({
  candidateId,
  geometryNames,
  optimizationId,
}: UseGetPresignedUrlProps) => {
  const setPresignedUrlsState = useSetRecoilState(
    candidatesPresignedUrlsState(candidateId),
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getPresignedUrls = async () => {
      if (candidateId && geometryNames.length > 0 && optimizationId) {
        setIsLoading(true);
        try {
          const urlsMap: Record<string, string> = {};

          const fetchUrls = geometryNames.map(async (geometryName) => {
            const response = await fetchPresignedUrl({
              candidateId,
              geometryName,
              optimizationId,
            });
            urlsMap[geometryName] = response?.url || '';
          });

          await Promise.all(fetchUrls);

          // Set the state with the map of geometryName -> URL
          setPresignedUrlsState((prev) => ({ ...prev, ...urlsMap }));
        } catch (error) {
          console.error('Error fetching presigned URLs:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    getPresignedUrls();
  }, [candidateId, geometryNames, optimizationId, setPresignedUrlsState]);

  return { isLoading };
};

export default useGetPresignedUrl;
