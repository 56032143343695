import type { FC } from 'react';

import { OptimizationStatusEnum } from '@optimizer/schemas/allOptimizationsSchema';
import { Progress } from '@pxui/components/ui/progress';

interface OptimizationStatusBarProps {
  maxSteps?: number | null;
  status: OptimizationStatusEnum;
  stepsCompleted?: number;
}

const UNKNOWING_PROGRESS_PERCENTAGE = 50;

const OptimizationStatusBar: FC<OptimizationStatusBarProps> = ({
  stepsCompleted = 0,
  maxSteps = 0,
  status,
}) => {
  let stepsInPercent = 0;

  switch (status) {
    case OptimizationStatusEnum.SUCCEEDED:
      stepsInPercent = 100;
      break;
    case OptimizationStatusEnum.FAILED:
      stepsInPercent = 0;
      break;
    case OptimizationStatusEnum.RUNNING:
      stepsInPercent = maxSteps
        ? Math.floor((stepsCompleted / maxSteps) * 100)
        : UNKNOWING_PROGRESS_PERCENTAGE;
      break;
    default:
      stepsInPercent = 0;
  }

  return (
    <Progress
      className="w-[240px]"
      value={stepsInPercent}
      error={status === OptimizationStatusEnum.FAILED}
    />
  );
};

export default OptimizationStatusBar;
