/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';

import { reduce } from 'lodash';

import { geometryViewerWasmModule } from '../components/GeometryViewer/GeometryViewerWasmModule';
import { chunkify } from '../utils/chunkify';
import { download } from '../utils/fileDownload';
import parseFieldData from '../utils/parseFieldData';
import { configureCanvasWebGL } from '../utils/wasmConfigure-react';

import type {
  GeometryViewer,
  FieldData,
  ViewportLayoutOption,
} from '../components/GeometryViewer/GeometryViewerFactory';

// set chunk size to 100mb
// eslint-disable-next-line no-magic-numbers
export const MAX_CHUNK_SIZE = 100 * 1000 * 100;

export function numViewports(layout: ViewportLayoutOption) {
  const rowsCols = layout.split('x').map((d) => parseInt(d, 10));
  return reduce(rowsCols, (acc, num) => acc * num, 1);
}

export type MeshConfig = { filename: string; url: string; viewportId: number };
export type MeshMetadata = string[][];

interface UseMeshVisualiserProps {
  layout?: ViewportLayoutOption;
  onLoaded?: () => void;
}

function useMeshVisualiser({ layout = '1x1' }: UseMeshVisualiserProps) {
  const [canvas, setCanvasElement] = useState<HTMLCanvasElement | null>(null);

  const viewer = useRef<GeometryViewer>();
  const [colorArrays, setColorArrays] = useState<string[]>(['Solid']);
  const [colorMapPresets, setColorMapPresets] = useState<string[]>(['Warm']);
  const [metadata, setMetadata] = useState<MeshMetadata[]>();
  const loadingCounter = useRef(0);

  //  states
  const [isLoading, setIsLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const loadFile = useCallback(
    async (file: File, viewportId = 0) => {
      if (viewer.current === null || viewer.current === undefined) {
        return;
      }

      try {
        const chunks = chunkify(file, MAX_CHUNK_SIZE);
        let offset = 0;

        const ptr = await geometryViewerWasmModule.malloc(file.size);
        for (let i = 0; i < chunks.length; ++i) {
          const chunk = chunks[i];

          const data = new Uint8Array(await chunk.arrayBuffer());

          await geometryViewerWasmModule.setHeapData(data, ptr + offset);
          offset += data.byteLength;
        }
        viewer.current.addMesh(file.name, ptr, file.size, viewportId);
        await geometryViewerWasmModule.free(ptr);
      } catch (err) {
        console.error('Error loading and parsing the mesh file');
        console.error(err);
      }
    },
    [viewer],
  );

  const handleCanvasRef = useCallback(
    (canvasEl: HTMLCanvasElement | null) => {
      if (canvasEl === null) {
        // Cleanup if the canvas is unmounted
        if (viewer.current) {
          viewer.current.delete();
          viewer.current = undefined;
        }
      }
      setCanvasElement(canvasEl);
    },
    [viewer],
  );

  const updateProperties = useCallback(() => {
    // Extract ColorArrays
    if (viewer.current) {
      const pointArrays = viewer.current.getPointDataArrays().split(';');
      const cellArrays = viewer.current.getCellDataArrays().split(';');
      const allArrays = ['Solid', ...pointArrays, ...cellArrays].filter(
        (el) => {
          return el.length > 0;
        },
      );
      viewer.current.setColorByArray(
        allArrays.length > 1 ? allArrays[1] : allArrays[0],
      );
      setColorArrays(allArrays);

      // Extract Color Presets
      const presets = viewer.current.getColorMapPresets().split(';');
      viewer.current.setColorMapPreset('Warm');
      setColorMapPresets([...presets]);

      //  Parse field data if any
      const fieldData = JSON.parse(
        viewer.current.getFieldData(),
      ) as FieldData[][];
      if (fieldData) {
        const formattedMetadata = parseFieldData(fieldData);
        setMetadata(formattedMetadata);
      }
    }
  }, []);

  const addMesh = useCallback(
    async (filename: string, url: string, viewportId = 0) => {
      const viewportCount = numViewports(layout);
      if (viewportId > viewportCount - 1) {
        console.error(
          'Trying to add a mesh to the viewport out of bounds, ignoring mesh.',
        );
        return;
      }

      if (!filename || !url) {
        console.error(
          'Trying to add a mesh without filename or url, ignoring mesh.',
        );
        return;
      }

      loadingCounter.current += 1;
      setIsLoading(true);

      const { blob } = await download(url);
      const meshFile = new File([blob], filename);
      await loadFile(meshFile, viewportId);

      loadingCounter.current -= 1;
      if (loadingCounter.current === 0) {
        setIsLoading(false);

        if (viewer.current) {
          updateProperties();
          viewer.current.resetView();
          viewer.current.render();
        }
      }
    },
    [],
  );

  useEffect(() => {
    async function initViewer(canvasElement: HTMLCanvasElement) {
      configureCanvasWebGL(canvasElement);
      // Create GeometryViewer instance
      viewer.current =
        await geometryViewerWasmModule.createNewGeometeryViewer(layout);
      viewer.current.initialize();
      // starts processing events on browser main thread.
      viewer.current.start();

      viewer.current.resetView();
      viewer.current.render();
      setInitialized(true);
    }

    if (!initialized && canvas) {
      initViewer(canvas);
    }

    return () => {
      // Clean up viewer instance
      if (viewer.current) {
        viewer.current.delete();
        viewer.current = undefined;
      }

      setInitialized(false);
    };
  }, [layout, canvas]);

  return {
    addMesh,
    canvasRef: handleCanvasRef,
    colorArrays,
    colorMapPresets,
    initialized,
    isLoading,
    metadata,
    viewer,
  };
}

export default useMeshVisualiser;
