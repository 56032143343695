import {
  BIG_THRESHOLD,
  PRECISION_DIFFERENCE_THRESHOLD,
} from '@optimizer/constants/plotConstants'; // Import from constants file
import { MetricTypeEnum } from '@optimizer/schemas/allOptimizationsSchema';
import { CustomPlotData } from '@optimizer/types/plotTypes';

/**
 * Checks if the number requires special formatting due to high precision differences.
 * Takes into account both the range and the precision of differences.
 *
 * @param {number[]} values - The array of numbers to check.
 * @returns {boolean} - Returns true if extra space is needed for axis labels.
 */
export function needsExtraSpaceForLabels(values: number[]): boolean {
  if (values.length < 2) {
    return false;
  }

  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);
  const range = maxValue - minValue;

  return values.some((value, index, arr) => {
    if (index === 0) return false;

    const prevValue = arr[index - 1];
    const difference = Math.abs(value - prevValue);

    return difference < PRECISION_DIFFERENCE_THRESHOLD && range < BIG_THRESHOLD;
  });
}

/**
 * Adds an arrow symbol next to the metric name based on whether it is being minimized or maximized.
 *
 * @param {string} name - The name of the metric.
 * @param {boolean} toMinimize - Indicates if the metric should be minimized.
 * @returns {string} - The formatted name with the corresponding arrow.
 */
export function formatMetricName(
  name: string,
  toMinimize: boolean,
  suffix?: boolean,
): string {
  if (suffix) {
    return `${name}  ${toMinimize ? '↓' : '↑'}`;
  }
  return `${toMinimize ? '↓' : '↑'}  ${name}`;
}

/**
 * Generates the title for the X or Y axis of the plot.
 * If the metric is of type "OBJECTIVE", it appends an arrow indicating
 * whether the metric is minimized or maximized.
 *
 * @param {string} metricName - The name of the metric being plotted.
 * @param {{ name: string; type: MetricTypeEnum; toMinimize?: boolean }[]} metrics - The available objective metrics.
 * @returns {string} - The formatted metric name with a direction indicator if applicable.
 */
export function getAxisTitle(
  metricName: string,
  metrics: { name: string; toMinimize?: boolean; type: MetricTypeEnum }[],
): string {
  const metric = metrics.find((m) => m.name === metricName);

  if (
    metric &&
    metric.type === MetricTypeEnum.OBJECTIVE &&
    metric.toMinimize !== undefined
  ) {
    return formatMetricName(metric.name, metric.toMinimize);
  }

  return metricName;
}

const getAxisTickTexts = (values: number[]) => {
  const roundFactor = 20;

  const roundedValues = values.map((value) => {
    const roundedValue = Math.round(value * roundFactor) / roundFactor;
    return roundedValue.toFixed(2);
  });

  const areCloseNumbers = roundedValues.every(
    (val) => val === roundedValues[0],
  );

  if (!areCloseNumbers) {
    return roundedValues;
  }

  const strValues = values.map((value) =>
    value.toPrecision(value.toString().length),
  );

  const commonPart = strValues.reduce((acc, curr) => {
    let i = 0;
    while (i < acc.length && i < curr.length && acc[i] === curr[i]) {
      i++;
    }
    return acc.slice(0, i);
  }, strValues[0]);

  const MAX_LENGTH = 5;

  return strValues.map((value) => {
    const begin = parseFloat(value).toFixed(2);
    const remaining = value.replace(commonPart, '');
    const remainingPart =
      remaining.length <= MAX_LENGTH
        ? remaining
        : `${remaining.slice(0, MAX_LENGTH)}...`;

    return `${begin}...${remainingPart}`;
  });
};

export const getAxisTickValues = (
  plotData: Partial<CustomPlotData>[],
  axis: 'x' | 'y',
) => {
  const axisValues = plotData.flatMap((data) => (data[axis] as number[]) || []);

  const minAxis = Math.min(...axisValues);
  const maxAxis = Math.max(...axisValues);

  const numTicks = 7;

  const step = (maxAxis - minAxis) / (numTicks - 1);

  const values = Array.from({ length: numTicks }, (_, i) => minAxis + i * step);

  const tickValues = Array.from(
    { length: numTicks },
    (_, i) => minAxis + i * step,
  );

  const tickTexts = getAxisTickTexts(values);

  return {
    ticktext: tickTexts,
    tickvals: tickValues,
  };
};
