import { useState, useEffect } from 'react';

import { fetchBatchOptimizations } from '@optimizer/services/fetchBatchOptimizations';
import { Optimization } from '@optimizer/types/services/allOptimizationsTypes';
import { toast } from '@pxui/components/ui/toast/useToast';

/**
 * Custom hook to fetch optimizations by their IDs.
 * @param {string[]} optimizationIdArray - The list of IDs of the optimizations to fetch.
 * @returns {{ optimizations: Optimization[], isError: boolean }}
 */
const useBatchOptimizations = (optimizationIdArray: string[]) => {
  const [optimizations, setOptimizations] = useState<Optimization[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!optimizationIdArray?.length) {
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const data = await fetchBatchOptimizations(optimizationIdArray);
        if (data) {
          setOptimizations(data);
          setIsLoading(false);
        } else {
          setIsError(true);
          setIsLoading(false);
          toast({
            description: `Optimization with IDs ${optimizationIdArray} not found.`,
            title: 'Error',
            variant: 'error',
          });
        }
      } catch (error) {
        setIsError(true);
        setIsLoading(false);
        toast({
          description: 'Failed to fetch optimizations. Please try again.',
          title: 'Error',
          variant: 'error',
        });
      }
    };

    fetchData();
  }, [optimizationIdArray]);

  return { isError, isLoading, optimizations };
};

export default useBatchOptimizations;
