import { Optimization } from './services/allOptimizationsTypes';

export enum PlotType {
  LINE = 'LINE',
  PARALLEL = 'PARALLEL',
  SCATTER = 'SCATTER',
}

// Static data interface
export interface PlotStaticData {
  id: string;
  type: PlotType;
}

export interface ParamsType {
  lowerBound: number;
  name: string;
  type: 'INTEGER' | 'FLOAT';
  upperBound: number;
}

// Dynamic data interface
export interface PlotDynamicData {
  highlighted: boolean;
  metricX?: string;
  metricY?: string;
  name: string;
  selectedMetrics?: string[];
  selectedOptimization?: Optimization & { color: string };
  selectedParams?: ParamsType[];
}

// Full data interface for configuration
export interface PlotConfigData extends PlotStaticData, PlotDynamicData {}
