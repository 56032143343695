import { FC, memo, useMemo } from 'react';

import { Button } from '@pxui/components/ui';

interface ExternalAppButtonsProps {
  isAppsLoading: boolean;
  registeredApps: {
    displayName: string;
    inferenceApiUrl?: string | null;
    name: string;
    showInferencelink?: boolean | null;
  }[];
  optimizationId: string;
  selectedCandidateId: string;
}

const ExternalAppButtons: FC<ExternalAppButtonsProps> = ({
  isAppsLoading,
  registeredApps,
  optimizationId,
  selectedCandidateId,
}) => {
  const externalAppButtons = useMemo(
    () =>
      registeredApps
        .filter(
          ({ inferenceApiUrl, showInferencelink }) =>
            inferenceApiUrl && showInferencelink,
        )
        .map(({ name, displayName, inferenceApiUrl }) => (
          <a
            key={name}
            href={`${inferenceApiUrl}?optId=${optimizationId}&candidateId=${selectedCandidateId}`}
            className="flex flex-col"
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`${displayName} (external link)`}
          >
            <Button layout="textOnly" variant="secondary" className="h-8">
              {`Open in ${displayName}`}
            </Button>
          </a>
        )),
    [registeredApps, optimizationId, selectedCandidateId],
  );

  if (isAppsLoading) {
    return (
      <Button layout="textOnly" variant="secondary" className="h-8" disabled>
        Loading...
      </Button>
    );
  }

  return (
    <>
      {externalAppButtons}
      {registeredApps.length === 0 ||
      !registeredApps.some(
        ({ showInferencelink, inferenceApiUrl }) =>
          showInferencelink && inferenceApiUrl,
      ) ? (
        <Button layout="textOnly" variant="secondary" className="h-8" disabled>
          Open in external app
        </Button>
      ) : null}
    </>
  );
};

export default memo(ExternalAppButtons);
