import { atomFamily } from 'recoil';

export interface EmbeddedApp {
  id: string;
  name: string;
  url: string;
}

export const embeddedAppsState = atomFamily<EmbeddedApp | null, string>({
  default: null,
  key: 'embeddedAppFamily',
});
