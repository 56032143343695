import {
  PresignedUlrResponse,
  PresignedUrlResponseSchema,
} from '@optimizer/schemas/presignedUrlSchema';

import apiClient from '@services/interceptors/authInterceptor';

export interface PresignedUrlParams {
  candidateId: string;
  geometryName: string;
  optimizationId: string;
}

export const fetchPresignedUrl = async ({
  candidateId,
  geometryName,
  optimizationId,
}: PresignedUrlParams): Promise<PresignedUlrResponse | undefined> => {
  try {
    const response = await apiClient.get(
      `${process.env.PLATFORM_API_URL}/v0/optimizer/optimizations/${optimizationId}/candidates/${candidateId}:getGeometryPresignedUrl`,
      {
        params: {
          geometry_name: geometryName,
        },
      },
    );

    const parseResponse = PresignedUrlResponseSchema.parse(response.data);

    return parseResponse;
  } catch (error) {
    console.warn('No presigned url');
    return undefined;
  }
};

export default fetchPresignedUrl;
