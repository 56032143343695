import { optimizerDefaultUrl } from '@config/optimizerUrl';
import { atom, selector } from 'recoil';

import { visibleSelectedOptimizationsState } from './selectedOptimizationsStates';

// Selector to get the available steps range (min and max) from visible selected optimizations
export const availableStepsRangeState = selector<[number, number]>({
  get: ({ get }) => {
    // Get the currently visible selected optimizations
    const visibleOptimizations = get(visibleSelectedOptimizationsState);

    // Extract the steps-related data from the visible optimizations
    const stepsRanges = visibleOptimizations
      .filter((opt) => opt.maxSteps !== null)
      .map((opt) => ({
        maxSteps: opt.maxSteps,
        minSteps: 1,
      }));

    // If there are no valid optimizations, return the default range [1, 1]
    if (stepsRanges.length === 0) {
      return [1, 1];
    }

    // Calculate the global maximum from the steps ranges
    const globalMin = 1;
    const globalMax = Math.max(
      ...stepsRanges.map((range) => range.maxSteps || 1),
    );

    return [globalMin, globalMax];
  },
  key: 'availableStepsRangeState',
});

// Atom to store the internal/unfiltered steps range
const rawStepsRangeFilterState = atom<[number, number]>({
  default: optimizerDefaultUrl.steps?.selectedRange,
  key: 'rawStepsRangeFilterState',
});

// Selector to manage the constrained steps range filter, constrained by available steps range
export const stepsRangeFilterState = selector<[number, number]>({
  get: ({ get }) => {
    const [availableMin, availableMax] = get(availableStepsRangeState);
    const [filterMin, filterMax] = get(rawStepsRangeFilterState);

    // Ensure that the filter steps range is within the bounds of the available steps range
    const constrainedMin = Math.max(availableMin, filterMin);
    const constrainedMax = Math.min(availableMax, filterMax);

    return [constrainedMin, constrainedMax] as [number, number];
  },
  key: 'stepsRangeFilterState',
  set: ({ set, get }, newValue) => {
    const [availableMin, availableMax] = get(availableStepsRangeState);
    const [newFilterMin, newFilterMax] = newValue as [number, number];

    const constrainedMin = Math.max(availableMin, newFilterMin);
    const constrainedMax = Math.min(availableMax, newFilterMax);

    const [currentFilterMin, currentFilterMax] = get(rawStepsRangeFilterState);

    // Only set the state if the constrained values differ from the current state
    if (
      constrainedMin !== currentFilterMin ||
      constrainedMax !== currentFilterMax
    ) {
      set(rawStepsRangeFilterState, [constrainedMin, constrainedMax] as [
        number,
        number,
      ]);
    }
  },
});

// Atom to store the steps spacing
export const stepsSpacingFilterState = atom<number>({
  default: optimizerDefaultUrl.steps?.spacing,
  key: 'stepsSpacingFilterState',
});

// Selector to get the subsampled steps based on the steps range and spacing
export const getSubsampledSteps = selector<number[]>({
  get: ({ get }) => {
    // Retrieve the steps range and step spacing
    const [minStep, maxStep] = get(stepsRangeFilterState);
    const stepSpacing = get(stepsSpacingFilterState);
    const availableStepsRange = get(availableStepsRangeState);

    // Initialize the list to hold subsampled steps
    const steps: number[] = [];

    const availableMax = availableStepsRange[1];
    const defaultStepSpacing = stepSpacing || Math.ceil(availableMax / 100);

    // Iterate through the range and apply the step spacing filter
    for (let step = minStep; step <= maxStep; step++) {
      if (step % defaultStepSpacing === 0) {
        steps.push(step);
      }
    }
    return steps;
  },
  key: 'getSubsampledSteps',
});
