import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router';

import useGetPresignedUrl from '@optimizer/hooks/useGetPresignedUrl';
import candidatesPresignedUrlsState from '@optimizer/states/candidatesPresignedUrlsState';
import candidatesState from '@optimizer/states/candidatesState';
import {
  compareViewState,
  comparisonState,
} from '@optimizer/states/comparisonState';
import selectedCandidateState from '@optimizer/states/selectedCandidateState';
import { GeometryData } from '@optimizer/types/geometryTypes';
import {
  exportAllGeometries,
  extractFilenameFromUrl,
} from '@optimizer/utils/geometryUtils';
import { useToast } from '@pxui/components/ui';
import { useRecoilValue, useRecoilState } from 'recoil';

import AddCompareButton from './ActionButtons/AddCompareButton';
import CompareButton from './ActionButtons/CompareButton';
import ExportButton from './ActionButtons/ExportButton';
import GeometryInspectorPanelWrapper from './GeometryInspectorPanelWrapper';

const MAX_SELECTION = 3;

/**
 * InspectorSidePanel displays detailed information and actions for a selected candidate.
 */
const InspectorSidePanel: FC = () => {
  const [selectedCandidate, setSelectedCandidate] = useRecoilState(
    selectedCandidateState,
  );
  const candidate = useRecoilValue(
    candidatesState(selectedCandidate?.id || ''),
  );
  const presignedUrls = useRecoilValue(
    candidatesPresignedUrlsState(selectedCandidate?.id || ''),
  );
  const [comparison, setComparison] = useRecoilState(comparisonState);
  const isOnCompareView = useRecoilValue(compareViewState);
  const { toast } = useToast();
  const navigation = useNavigate();

  // Fetch presigned URLs and get loading state
  const { isLoading } = useGetPresignedUrl({
    candidateId: selectedCandidate?.id || '',
    geometryNames: candidate?.geometryNames || [],
    optimizationId: selectedCandidate?.optimizationId || '',
  });

  // Create geometry data only if not loading
  const geometries: GeometryData[] = useMemo(() => {
    if (isLoading || !candidate?.geometryNames) return [];
    return candidate.geometryNames
      .map((geometryName) => {
        const url = presignedUrls[geometryName] || '';

        if (!url) {
          console.error(`No URL found for geometry: ${geometryName}`);
          return {
            filename: '',
            name: geometryName,
            url: '',
          };
        }

        return {
          filename: extractFilenameFromUrl(url),
          name: geometryName,
          url,
        };
      })
      .filter((geometry) => geometry.filename && geometry.url);
  }, [isLoading, candidate?.geometryNames, presignedUrls]);

  const comparisonsIds = useMemo(
    () => comparison.map(({ candidate: { id } }) => id),
    [comparison],
  );
  const isComparing = useMemo(
    () => comparisonsIds.includes(candidate?.id || ''),
    [comparisonsIds, candidate?.id],
  );

  const handleAddToComparison = () => {
    if (!candidate) return;
    if (comparison.length < MAX_SELECTION) {
      setComparison((prev) =>
        isComparing
          ? prev.filter((item) => item.candidate.id !== selectedCandidate?.id)
          : [
              ...prev,
              {
                candidate,
                optimizationId: selectedCandidate?.optimizationId || '',
                step: selectedCandidate?.step || 0,
              },
            ],
      );
    } else {
      toast({
        description: `You can only select up to ${MAX_SELECTION} comparisons.`,
        title: 'Selection Limit Reached',
      });
    }
  };

  const handleExportAllGeometries = () => {
    exportAllGeometries(geometries, selectedCandidate?.id || '');
  };

  const optimizationId = selectedCandidate?.optimizationId || '';

  return (
    <GeometryInspectorPanelWrapper
      open={!!selectedCandidate}
      geometries={geometries}
      isLoading={isLoading}
      optimizationId={optimizationId}
      selectedCandidateId={selectedCandidate?.id || ''}
      selectedCandidateStep={selectedCandidate?.step}
      buttons={
        <div className="flex justify-end items-center gap-2">
          {!isOnCompareView && (
            <AddCompareButton
              isComparing={isComparing}
              handleAddToComparison={handleAddToComparison}
            />
          )}
          <CompareButton />
        </div>
      }
      bottomButtons={
        <div className="flex justify-end items-center gap-2">
          <ExportButton
            handleExportAllGeometries={
              geometries.length ? handleExportAllGeometries : undefined
            }
          />
        </div>
      }
      onClose={() => setSelectedCandidate(null)}
      onExpand={() => navigation('/app/optimizer/candidate-mesh')}
    />
  );
};

export default InspectorSidePanel;
