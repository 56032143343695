/* eslint-disable no-magic-numbers */
import { colors } from '@design-tokens/primitive-tokens';

import type { DataTitle, Layout } from 'plotly.js';

// TODO: update with the design system
export const PLOT_COLORS = {
  beige: '#C2B078',
  blue: '#7BABD6',
  brown: '#734222',
  emerald: '#35CE8D',
  golden: '#DBAA33',
  ivory: '#E6D690',
  khaki: '#6A5F31',
  lightGreen: '#84C3BE',
  orange: '#F44611',
  papyrus: '#CFD3CD',
  red: '#D33C46',
  rose: '#F8C7CC',
}; // Plot available line/marker colors

export const PLOT_AXIS_TITLE_LAYOUT_CONFIG: Partial<DataTitle> = {
  font: {
    color: colors.neutral[70],
    family: 'Geist Sans',
    size: 11,
  },
  standoff: 10,
}; // Plot layout config that applies styles on axis title label

export const PLOT_AXIS_LAYOUT_CONFIG: Partial<Layout['xaxis']> = {
  automargin: true,
  color: colors.gray[900],
  gridcolor: colors.gray[900],
  tickfont: {
    color: colors.neutral[70],
    family: 'Geist Sans',
    size: 13,
  },
  title: PLOT_AXIS_TITLE_LAYOUT_CONFIG,
}; // Plot layout config that applies styles on axes grid, title label and tick labels

export const DEFAULT_PLOT_LAYOUT_CONFIG: Partial<Layout> = {
  modebar: {
    orientation: 'v',
  },
  paper_bgcolor: colors.neutral[6],
  plot_bgcolor: colors.neutral[6],
  shapes: [
    {
      line: {
        color: colors.gray[900],
        width: 1,
      },
      type: 'rect',
      x0: 0,
      x1: 1,
      xref: 'paper',
      y0: 0,
      y1: 0.998,
      yref: 'paper',
    },
  ],
  showlegend: false,
  xaxis: PLOT_AXIS_LAYOUT_CONFIG,
  yaxis: PLOT_AXIS_LAYOUT_CONFIG,
}; // Plot layout config that applies main plot styles

// Constants related to marker sizes, opacity, etc.
export const DEFAULT_OPACITY = 0.6; // Default opacity for non-selected markers
export const SELECTED_OPACITY = 1; // Opacity for selected markers
export const SELECTED_SIZE = 12; // Marker size for selected candidates
export const MARKER_SIZE = 6; // Default marker size for the scatter plot
export const LINE_WIDTH = 1; // Line width for line plots
export const HIGHLIGHTED_LINE_WIDTH = 2; // Line width for highlighted lines

// Constants related to color manipulation
export const BRIGHTEN_FACTOR = 3; // Factor to brighten Pareto front line color

// Default symbols for Plotly markers
export const DEFAULT_MARKER_SYMBOL = 'circle';
export const SELECTED_MARKER_SYMBOL = 'circle';

// Thresholds and limits for plotting
export const MAX_POINTS_PER_PLOT = 10000; // Maximum number of points to display in a plot

// Common colors
// TODO: add to the design system
export const HIGHLIGHT_COLOR = '#ffffff'; // Color for highlighting selected markers or lines

// Constants for plot layouts
export const BIG_THRESHOLD = 5; // Maximum allowed symbols before considering it "big"
export const PRECISION_DIFFERENCE_THRESHOLD = 1e-12; // Smallest difference to consider precision impact

// Standoff values
export const DEFAULT_STANDOFF = 30; // Default padding for axis titles
export const BIG_STANDOFF = 70; // Extra padding for titles when precision formatting is needed

// Plot margins
export const PLOT_MARGIN_BOTTOM = 40;
export const PLOT_MARGIN_LEFT = 10;
export const PLOT_MARGIN_RIGHT = 40;
export const PLOT_MARGIN_TOP = 0;

// Plot padding
export const PLOT_PADDING = 4;
