import { useEffect, useState } from 'react';

import { useRecoilCallback } from 'recoil';

import { EmbeddedApp, embeddedAppsState } from '@states/embeddedAppsState';

import { fetchRegisteredApps } from '@services/fetchRegisteredApps';

import { LauncherTileRegisteredType } from '@typing/appsManagementTypes';

/**
 * Custom hook to fetch and manage the state of registered applications with their icons.
 *
 * @returns {LauncherTileRegisteredType[]} An array of registered applications with their icons.
 */
const useRegisteredApps = (): {
  isError: { errorMessage: string };
  isLoading: boolean;
  registeredApps: LauncherTileRegisteredType[];
} => {
  const [registeredApps, setRegisteredApps] = useState<
    LauncherTileRegisteredType[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<{ errorMessage: string }>({
    errorMessage: '',
  });

  // Recoil callback to set multiple embedded apps in the atom family
  const setEmbeddedApps = useRecoilCallback(
    ({ set }) =>
      (apps: EmbeddedApp[]) => {
        apps.forEach((app) => {
          set(embeddedAppsState(app.id), app);
        });
      },
    [],
  );

  useEffect(() => {
    const getRegisteredApps = async () => {
      try {
        const apps = await fetchRegisteredApps();
        setRegisteredApps(apps);

        const embeddedApps: EmbeddedApp[] = apps
          .filter((app) => app.embedded)
          .map((app) => ({
            id: app.name,
            name: app.displayName,
            url: app.url,
          }));

        setEmbeddedApps(embeddedApps);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching registered apps:', error);
        setIsError({ errorMessage: String(error) });
        // TODO: error flow
      }
    };

    getRegisteredApps();
  }, [setEmbeddedApps]);

  return { isError, isLoading, registeredApps };
};

export default useRegisteredApps;
