import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Optimizer: FC<IconProps> = ({ className, size = 'default', id }) => (
  <svg
    id={id}
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M11 3H9V8H11V3Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.4772 15.5228 0 10 0C4.4772 0 0 4.4772 0 10C0 15.5228 4.4772 20 10 20ZM10 17C13.866 17 17 13.866 17 10C17 6.134 13.866 3 10 3C6.134 3 3 6.134 3 10C3 13.866 6.134 17 10 17Z"
      fill="#AAABB1"
    />
    <path
      d="M10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="10"
        y1="0"
        x2="10"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.45" stopColor="#AAABB1" />
      </linearGradient>
    </defs>
  </svg>
);

export default Optimizer;
