import { FC, memo } from 'react';

import { Button } from '@pxui/components/ui';

interface AddCompareButtonProps {
  isComparing: boolean;
  handleAddToComparison: () => void;
}

const AddCompareButton: FC<AddCompareButtonProps> = ({
  isComparing,
  handleAddToComparison,
}) => (
  <Button
    layout="textOnly"
    variant="primary"
    onClick={handleAddToComparison}
    className="h-8"
  >
    {isComparing ? 'Remove from Compare' : 'Add to Compare'}
  </Button>
);

export default memo(AddCompareButton);
