import { type FC } from 'react';

import { OptimizerUrlState } from '@config/optimizerUrl';
import {
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DndContext,
  closestCenter,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { plotsStaticDataState } from '@optimizer/states/plotsStates';
import { ListHeader, ListSection } from '@pxui/components/ui';
import { useRecoilState } from 'recoil';

import { useUrlContext } from '@contexts/UrlQueryParamsContext';

import PlotsListItem from './PlotsListItem';

const PlotsList: FC = () => {
  const [plotsStaticData, setPlotsStaticData] =
    useRecoilState(plotsStaticDataState);
  const { params, updateParams } = useUrlContext<OptimizerUrlState>();

  // Define the sensors (PointerSensor for mouse, and KeyboardSensor for keyboard users)
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  // Handle the end of a drag event
  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = plotsStaticData.findIndex(
        (item) => item.id === active.id,
      );
      const newIndex = plotsStaticData.findIndex((item) => item.id === over.id);
      setPlotsStaticData((prev) => arrayMove(prev, oldIndex, newIndex));
      if (params?.plotConfigData) {
        updateParams({
          plotConfigData: arrayMove(params?.plotConfigData, oldIndex, newIndex),
        });
      }
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <ListSection>
        <ListHeader title="Plots" />
        <SortableContext
          items={plotsStaticData.map(({ id }) => id)} // Pass the list of ids to SortableContext
          strategy={verticalListSortingStrategy} // For vertical sorting
        >
          {plotsStaticData.length &&
            plotsStaticData.map(({ id }) => (
              <PlotsListItem key={id} plotId={id} />
            ))}
          {!plotsStaticData.length && (
            <div className="text-sm text-gray-500 p-4">
              No visualizations available.
            </div>
          )}
        </SortableContext>
      </ListSection>
    </DndContext>
  );
};

export default PlotsList;
