import { RouteObject } from 'react-router';

import { lazyLoadHelper } from '@utils/lazyLoadHelper';

export const routes: RouteObject[] = [
  {
    index: true,
    lazy: lazyLoadHelper(() => import('./LauncherPage/LauncherPage')),
  },
  {
    lazy: lazyLoadHelper(() => import('./EmbeddedAppPage/EmbeddedAppPage')),
    path: '/app/:id',
  },
  {
    lazy: lazyLoadHelper(() => import('./ErrorPage/ErrorPage')),
    path: '*', // 404 page
  },
];

const experimentalFeatureFlagAllowedNamespaces = [
  'platform-dev',
  'platform-sandbox',
];

// Conditionally add a route because it is an experimental feature
if (
  experimentalFeatureFlagAllowedNamespaces.includes(
    process.env.PLATFORM_NAMESPACE ?? '',
  )
) {
  routes.push({
    lazy: lazyLoadHelper(() => import('@pages/Experimental/Experimental')),
    path: 'experimental',
  });
}

export default routes;
