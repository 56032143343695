import type { FC, HTMLAttributes, MouseEvent as ReactMouseEvent } from 'react';

import { Icon } from '@pxui/components/ui';
import cn from '@pxui/lib/utils';

interface TreeViewBranchCaretProps extends HTMLAttributes<HTMLButtonElement> {
  isOpen?: boolean;
  onOpenChange: (open: boolean) => void;
}

const TreeViewBranchCaret: FC<TreeViewBranchCaretProps> = ({
  isOpen,
  onOpenChange,
  className,
}) => {
  const handleClick = (
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    onOpenChange(!isOpen);
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className={cn('flex items-center px-2 h-full', className)}
    >
      <Icon
        name={isOpen ? 'arrow_drop_down' : 'arrow_right'}
        className="text-on-surface-subtle"
      />
    </button>
  );
};

export { TreeViewBranchCaret, type TreeViewBranchCaretProps };
