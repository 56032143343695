import { comparisonState } from '@optimizer/states/comparisonState';
import { showDiffSelector } from '@optimizer/states/showDiffState';
import { ToggleSwitch } from '@pxui/components/ui';
import { useRecoilState, useRecoilValue } from 'recoil';

const ComparisonHeader = () => {
  const comparison = useRecoilValue(comparisonState);
  const [toggleShowDiff, setToogleShowDiff] = useRecoilState(showDiffSelector);

  return (
    <div className="flex justify-start items-center">
      <ToggleSwitch
        checked={toggleShowDiff}
        disabled={comparison.length < 2}
        label="Show diff"
        onClick={() => setToogleShowDiff(!toggleShowDiff)}
      />
    </div>
  );
};

export default ComparisonHeader;
