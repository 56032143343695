import { GetFilteredOptimizationDataResponseSchema } from '@optimizer/schemas/allOptimizationsSchema';
import {
  GetFilteredOptimizationDataRequest,
  GetFilteredOptimizationDataResponse,
} from '@optimizer/types/services/filteredOptimizationsTypes';
import handleError from '@optimizer/utils/handleError';

import apiClient from '@services/interceptors/authInterceptor';

import { GetFilteredOptimizationDataRequestSchema } from '../schemas/filteredOptimizationsSchema';

/**
 * Fetches filtered optimization data based on the request criteria.
 * @param {GetFilteredOptimizationDataRequest} request - The request object containing filter criteria.
 * @returns {Promise<GetFilteredOptimizationDataResponse | undefined>} - Promise resolving to an GetFilteredOptimizationDataResponse object, or undefined if an error occurs.
 * @throws Will throw an error if the fetch operation fails or if the request/response does not match the expected schema.
 */
export const fetchFilteredOptimizations = async (
  request: GetFilteredOptimizationDataRequest,
): Promise<GetFilteredOptimizationDataResponse | undefined> => {
  try {
    GetFilteredOptimizationDataRequestSchema.parse(request);

    const response = await apiClient.post(
      `${process.env.PLATFORM_API_URL}/v0/optimizer/optimizations:getFilteredData`,
      request,
    );

    return GetFilteredOptimizationDataResponseSchema.parse(response.data);
  } catch (error) {
    handleError(error, 'fetch filtered optimizations');
    return undefined;
  }
};

export default fetchFilteredOptimizations;
