import { useEffect, useMemo, useState } from 'react';

import { fetchFilteredOptimizations } from '@optimizer/services/fetchFilteredOptimizations';
import { candidatesState } from '@optimizer/states/candidatesState';
import { visibleObjectiveMetricsState } from '@optimizer/states/metricsState';
import { plotConfigState } from '@optimizer/states/plotsStates';
import candidateSelectionModeState from '@optimizer/states/selectedCandidateSelectionMode';
import { selectedCandidateState } from '@optimizer/states/selectedCandidateState';
import { selectedConstraintsState } from '@optimizer/states/selectedConstraintsState';
import { visibleSelectedOptimizationsState } from '@optimizer/states/selectedOptimizationsStates';
import {
  getSubsampledSteps,
  stepsRangeFilterState,
} from '@optimizer/states/selectedStepsState';
import uncertaintySelectionModeState from '@optimizer/states/uncertaintySelectionMode';
import { CustomPlotData } from '@optimizer/types/plotTypes';
import {
  GetFilteredOptimizationDataRequest,
  Candidate,
} from '@optimizer/types/services/filteredOptimizationsTypes';
import transformOptimizationDataToLinePlotData from '@optimizer/utils/transformOptimizationDataToLinePlotData';
import { useRecoilValue, useRecoilCallback, useRecoilState } from 'recoil';

/**
 * Custom hook to fetch and transform plot content data for Plotly line plots.
 * This hook ensures the data is ready for rendering based on selected optimizations and metrics.
 *
 * @param {string} plotId - The ID of the plot for which data needs to be fetched and transformed.
 * @returns {isLoading: boolean; plotData:Partial<CustomPlotData>[]} The transformed data ready for Plotly rendering and a loading state.
 */
export const useLinePlotData = (plotId: string) => {
  const plotConfig = useRecoilValue(plotConfigState(plotId));
  const visibleOptimizations = useRecoilValue(
    visibleSelectedOptimizationsState,
  );
  const metrics = useRecoilValue(visibleObjectiveMetricsState);
  const selectedConstraints = useRecoilValue(selectedConstraintsState);
  const selectedCandidate = useRecoilValue(selectedCandidateState);
  const [plotData, setPlotData] = useState<Partial<CustomPlotData>[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const stepsRangeFilter = useRecoilValue(stepsRangeFilterState);
  const candidateSelectionMode = useRecoilValue(candidateSelectionModeState);
  const subsampledSteps = useRecoilValue(getSubsampledSteps);
  const [uncertaintySelectionMode] = useRecoilState(
    uncertaintySelectionModeState,
  );
  const setCandidates = useRecoilCallback(
    ({ set }) =>
      (candidates: Record<string, Candidate>) => {
        Object.entries(candidates).forEach(([id, candidate]) => {
          set(candidatesState(id), candidate);
        });
      },
    [],
  );

  const shouldShowAllCandidates = candidateSelectionMode.marker.length > 2;

  // Memoizing effect dependencies with useMemo
  const plotDataRequestParams: GetFilteredOptimizationDataRequest =
    useMemo(() => {
      return {
        max_step: stepsRangeFilter[1],
        metrics: [plotConfig.metricY as string],
        min_step: stepsRangeFilter[0],
        optimization_ids: visibleOptimizations.map((opt) => opt.id),
        satisfied_constraints: selectedConstraints?.length
          ? selectedConstraints
          : undefined,
        steps: subsampledSteps,
      };
    }, [
      stepsRangeFilter,
      subsampledSteps,
      visibleOptimizations,
      plotConfig.metricY,
      selectedConstraints,
    ]);

  useEffect(() => {
    const getPlotData = async () => {
      if (!plotConfig.metricY || !metrics.length) return;

      const optimizationIds = visibleOptimizations.map((opt) => opt.id);
      if (!optimizationIds.length) return;

      setIsLoading(true);

      try {
        const lineModes = candidateSelectionMode.line;
        const markerModes = candidateSelectionMode.marker;

        const lineRequests = lineModes.map((mode) =>
          fetchFilteredOptimizations({
            ...plotDataRequestParams,
            candidate_selection_per_step: mode,
          }),
        );

        const markerRequests = markerModes.map((mode) =>
          fetchFilteredOptimizations({
            ...plotDataRequestParams,
            candidate_selection_per_step: mode,
          }),
        );

        const allData = await Promise.all([...lineRequests, ...markerRequests]);

        const lineData = allData.slice(0, lineModes.length);
        const markerData = allData.slice(lineModes.length);

        setIsLoading(false);

        if (
          lineData.some((data) => !data) ||
          markerData.some((data) => !data)
        ) {
          console.warn('Some optimization data fetch failed');
          return;
        }

        const lineTransformations = lineData.map(
          (data) =>
            (data &&
              plotConfig.metricY &&
              transformOptimizationDataToLinePlotData(
                visibleOptimizations,
                data?.optimizationData,
                plotConfig?.metricY,
                selectedCandidate?.id || null,
                Boolean(Number(uncertaintySelectionMode)),
                'lines',
              )) || [[], {}],
        );

        const markerTransformations = markerData.map(
          (data) =>
            (data &&
              plotConfig.metricY &&
              transformOptimizationDataToLinePlotData(
                visibleOptimizations,
                data?.optimizationData,
                plotConfig?.metricY,
                selectedCandidate?.id || null,
                Boolean(Number(uncertaintySelectionMode)),
                'markers',
              )) || [[], {}],
        );

        const transformedPlotData = [
          ...lineTransformations.flatMap(([data]) => data),
          ...markerTransformations.flatMap(([data]) => data),
        ];

        const combinedCandidates = [
          ...lineTransformations.map(([, candidates]) => candidates),
          ...markerTransformations.map(([, candidates]) => candidates),
        ].reduce((acc, curr) => ({ ...acc, ...curr }), {});

        setPlotData(transformedPlotData);
        setCandidates(combinedCandidates);
      } catch (error) {
        console.error('Failed to fetch and set plot content:', error);
        // TODO: Handle error using a toast
      }
    };

    getPlotData();
  }, [
    plotConfig,
    visibleOptimizations,
    metrics,
    plotId,
    setCandidates,
    selectedCandidate?.id,
    plotDataRequestParams,
    uncertaintySelectionMode,
    candidateSelectionMode,
    shouldShowAllCandidates,
  ]);

  return { isLoading, plotData };
};

export default useLinePlotData;
