import { type ButtonHTMLAttributes, type ReactNode, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';
import { cva } from 'class-variance-authority';

interface TabProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  selected?: boolean;
}

const layoutClasses =
  'group relative inline-flex items-center justify-center rounded border border-transparent';

const interactionClasses =
  'hover:state-hover active:state-active focus-visible:outline-none focus-visible:state-focus focus-visible:state-focus-inset';
const spacingClasses = 'p-3';
const transitionClasses = 'transition-colors duration-150 ease-out';

const TabStyle = cva(
  cn(
    'cursor-pointer label-1 text-secondary',
    layoutClasses,
    interactionClasses,
    spacingClasses,
    transitionClasses,
  ),
);

const Tab = forwardRef<HTMLButtonElement, TabProps>(
  ({ className, children, selected, onClick, disabled }, ref) => {
    const tabClasses = cn(TabStyle(), {
      'after:absolute after:bg-blue-600 after:block after:bottom-[1px] after:w-full after:h-[1px] after:rounded-[1px] after:state-focus text-primary':
        selected,
      'text-disabled': disabled,
    });

    return (
      <button
        type="button"
        role="tab"
        className={tabClasses}
        ref={ref}
        onClick={onClick}
      >
        {children}
      </button>
    );
  },
);

Tab.displayName = 'Tab';

export { Tab, type TabProps };
