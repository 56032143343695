import { useCallback, useState, type FC } from 'react';

import { OptimizerUrlState } from '@config/optimizerUrl';
import { visibleObjectiveMetricsState } from '@optimizer/states/metricsState';
import { plotConfigState } from '@optimizer/states/plotsStates';
import { PlotType } from '@optimizer/types/plotStateTypes';
import { Icon, ListItem, ListSection } from '@pxui/components/ui';
import { ExcludeProperty } from '@pxui/components/ui/excludeProperty';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@pxui/components/ui/tabs/tabs';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useUrlContext } from '@contexts/UrlQueryParamsContext';

enum tabsConfig {
  x = 'X-axis',
  y = 'Y-axis',
}

const ExcludeProperties: FC = () => {
  const { params, updateParams } = useUrlContext<OptimizerUrlState>();
  const objectiveMetrics = useRecoilValue(visibleObjectiveMetricsState);

  const currentPlot =
    params?.plotConfigData?.find((plot) => plot.highlighted) ?? {};

  const setPlotConfig = useSetRecoilState(plotConfigState(currentPlot.id!));

  const [activeItem, setActiveItem] = useState<string>('');

  const syncUrlParams = useCallback(
    (thisPlotId: string, updates: Record<string, any>) => {
      const updatedPlotConfigData = params.plotConfigData?.map(
        (plot) =>
          plot.id === thisPlotId
            ? {
                ...plot, // Keep original properties
                ...updates, // Apply updates
              }
            : plot, // Keep unchanged plots in the original order
      );
      if (updatedPlotConfigData) {
        updateParams({
          plotConfigData: updatedPlotConfigData,
        });
      }
    },
    [params.plotConfigData, updateParams],
  );

  const getTabsConfig = () => {
    switch (currentPlot.type) {
      case PlotType.LINE:
        return [tabsConfig.y];

      case PlotType.SCATTER:
        return [tabsConfig.x, tabsConfig.y];

      case PlotType.PARALLEL:
        return [tabsConfig.x];

      default:
        return [];
    }
  };

  const tabs = getTabsConfig();

  if (!tabs?.length) {
    return null;
  }

  const items = objectiveMetrics.map((metric) => (
    <ListItem
      key={metric.name}
      isActive={activeItem === metric.name}
      label={metric.name}
      className="flex items-center text-on-surface-subtle p-2"
      prefix={
        <Icon
          size="small"
          className="w-4"
          name={metric.toMinimize ? 'arrow_downward_alt' : 'arrow_upward_alt'}
        />
      }
    />
  ));

  const handleClick = (label: string, value: string) => {
    setActiveItem(label);

    setPlotConfig((prevConfig) => {
      const prop = value === tabsConfig.x ? 'metricX' : 'metricY';

      if (currentPlot.id && prevConfig) {
        syncUrlParams(currentPlot.id, {
          [prop]: label,
        });

        return {
          ...prevConfig,
          [prop]: label,
        };
      }

      return prevConfig;
    });
  };

  const renderTab = (value: tabsConfig) => {
    return (
      <TabsContent value={value} className="flex flex-col gap-4">
        <ExcludeProperty
          items={items}
          handleItemClick={(label: string) => handleClick(label, value)}
        />
      </TabsContent>
    );
  };

  return (
    <ListSection>
      <hr className="border-subtle mb-1" />
      <Tabs defaultValue={tabs[0]} className="w-full" key={tabs[0]}>
        <TabsList>
          {tabs.map((tab) => (
            <TabsTrigger key={tab} className="min-w-16" value={tab}>
              {tab}
            </TabsTrigger>
          ))}
        </TabsList>
        {tabs.includes(tabsConfig.x) && renderTab(tabsConfig.x)}
        {tabs.includes(tabsConfig.y) && renderTab(tabsConfig.y)}
      </Tabs>
    </ListSection>
  );
};

export default ExcludeProperties;
