import { type ReactNode, forwardRef } from 'react';

interface SectionHeaderRightProps {
  children: ReactNode;
}

const SectionHeaderRight = forwardRef<HTMLDivElement, SectionHeaderRightProps>(
  ({ children }, ref) => (
    <div className="flex ml-auto items-center" ref={ref}>
      {children}
    </div>
  ),
);

SectionHeaderRight.displayName = 'SectionHeaderRight';

export { SectionHeaderRight, type SectionHeaderRightProps };
