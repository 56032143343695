import { type FC } from 'react';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@pxui/components/ui';

interface MetricsNamesProps {
  metrics?: string[] | null;
}

const MetricsNames: FC<MetricsNamesProps> = ({ metrics }) => {
  if (!metrics || metrics?.length === 0) return null;

  // TODO: add "+n others"
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="line-clamp-1">{metrics?.join(', ') || ''}</span>
        </TooltipTrigger>

        <TooltipContent>
          <ul className="list-disc ml-4">
            {metrics.map((metric) => (
              <li key={metric}>{metric}</li>
            ))}
          </ul>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default MetricsNames;
