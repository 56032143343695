import { FC, ReactNode } from 'react';

import cn from '@pxui/lib/utils';

interface EmptyStateMessageProps {
  children?: ReactNode;
  className?: string;
  message: string;
}

const EmptyStateMessage: FC<EmptyStateMessageProps> = ({
  message,
  className = '',
  children,
}) => (
  <div
    className={cn(
      'flex items-center justify-center h-full p-20 text-secondary title-3',
      className,
    )}
  >
    <h3>{message}</h3>
    {children}
  </div>
);

export default EmptyStateMessage;
