import { z } from 'zod';

import arrayBufferToBase64 from '@utils/arrayBufferToBase64';

import {
  HTTP_STATUS_BAD_REQUEST,
  HTTP_STATUS_NOT_FOUND,
} from '@constants/httpStatusCodes';

import {
  GetAppIcon64Schema,
  GetAppRegistrationSchema,
} from '@schemas/appsManagementSchemas';

import { LauncherTileRegisteredType } from '@typing/appsManagementTypes';

import apiClient from './interceptors/authInterceptor';

/**
 * Fetch the icon data for a specific app registration as a base64 string or as an array buffer and convert it to a Data URL.
 *
 * @param appName The name of the app registration.
 * @param base64 Whether to fetch the icon data as a base64 string directly.
 * @returns {Promise<string>} The Data URL for the app's icon or an empty string if invalid.
 */
const fetchAppIconUrl = async (
  appName: string,
  base64: boolean,
): Promise<string> => {
  try {
    const response = await apiClient.get(
      `${process.env.PLATFORM_APP_MANAGEMENT_API}/v0/apps/registration/${appName}/icon?asBase64=${base64}`,
      {
        responseType: base64 ? 'json' : 'arraybuffer', // Use 'json' for base64 response or 'arraybuffer' for binary data
      },
    );

    if (base64) {
      // Validate response using schema
      const iconData = GetAppIcon64Schema.parse(response.data);

      if (iconData.iconData && iconData.iconFileFormat) {
        return `data:image/${iconData.iconFileFormat};base64,${iconData.iconData}`;
      }

      console.warn(
        `Invalid icon data for app "${appName}": Missing data or file format.`,
      );
    } else {
      const contentType = response.headers['content-type'];
      const validContentTypes = ['image/png', 'image/jpeg', 'image/webp']; // Define valid content types

      if (
        response.data.byteLength > 0 && // Ensure the response contains data
        validContentTypes.includes(contentType)
      ) {
        const base64Data = arrayBufferToBase64(response.data);
        return `data:${contentType};base64,${base64Data}`;
      }

      console.warn(
        `Invalid icon data for app "${appName}": Invalid content type or empty response.`,
      );
    }
    return '';
  } catch (error: any) {
    // Handle specific HTTP errors
    if (
      error.response &&
      (error.response.status === HTTP_STATUS_BAD_REQUEST ||
        error.response.status === HTTP_STATUS_NOT_FOUND)
    ) {
      console.warn(
        `Icon not found or bad request for app "${appName}": ${error.message}`,
      );
      return ''; // Return empty string for 400 or 404 errors
    }

    // General error handling
    console.error(`Error fetching icon for app "${appName}":`, error);
    return ''; // Return empty string on any other error
  }
};

/**
 * Fetch registered apps from the API, validate, and transform the response using Zod schema.
 * Also fetch icon URLs for each app.
 *
 * @returns {Promise<LauncherTileRegisteredType[]>} An array of validated and transformed app registrations with icon URLs.
 */
export const fetchRegisteredApps = async (): Promise<
  LauncherTileRegisteredType[]
> => {
  try {
    const response = await apiClient.get(
      `${process.env.PLATFORM_APP_MANAGEMENT_API}/v0/apps/registration`,
    );

    // Parse the apps using Zod schema
    const apps = z.array(GetAppRegistrationSchema).parse(response.data);

    // Fetch icon URL for each app and add it to the app data
    const appsWithIcons = await Promise.all(
      apps.map(async (app) => {
        const iconUrl = await fetchAppIconUrl(app.name, true);
        return { ...app, iconUrl };
      }),
    );

    // Sort apps based on their "order" field, placing undefined or negative orders last
    appsWithIcons.sort((a, b) => {
      const orderA = a.order ?? Infinity; // Default to Infinity if undefined
      const orderB = b.order ?? Infinity; // Default to Infinity if undefined
      return orderA - orderB;
    });

    return appsWithIcons;
  } catch (error) {
    console.error('Error fetching registered apps:', error);
    return [];
  }
};

/**
 * Fetch a specific registered app by its ID from the API, validate, and transform the response using Zod schema.
 * Also fetch the icon URL for the app.
 *
 * @param appName The name of the app registration.
 * @returns {Promise<LauncherTileRegisteredType | null>} The validated and transformed app registration with icon URL or null if not found.
 */
export const fetchRegisteredAppById = async (
  appName: string,
): Promise<LauncherTileRegisteredType | null> => {
  try {
    const response = await apiClient.get(
      `${process.env.PLATFORM_APP_MANAGEMENT_API}/v0/apps/registration/${appName}`,
    );

    // Validate response using schema
    const app = GetAppRegistrationSchema.parse(response.data);

    // Fetch icon URL for the app
    const iconUrl = await fetchAppIconUrl(app.name, true);

    return { ...app, iconUrl };
  } catch (error: any) {
    // Handle specific HTTP errors
    if (
      error.response &&
      (error.response.status === HTTP_STATUS_BAD_REQUEST ||
        error.response.status === HTTP_STATUS_NOT_FOUND)
    ) {
      console.warn(
        `App not found or bad request for app "${appName}": ${error.message}`,
      );
      return null;
    }

    // General error handling
    console.error(`Error fetching app by ID "${appName}":`, error);
    return null;
  }
};

// TODO: add error handling
export default fetchRegisteredApps;
