import { FC, useCallback, useEffect, useMemo } from 'react';

import { OptimizerUrlState } from '@config/optimizerUrl';
import { PlotHeader } from '@optimizer/components';
import {
  DEFAULT_PLOT_LAYOUT_CONFIG,
  PLOT_AXIS_LAYOUT_CONFIG,
  PLOT_AXIS_TITLE_LAYOUT_CONFIG,
  PLOT_MARGIN_BOTTOM,
  PLOT_MARGIN_RIGHT,
  PLOT_MARGIN_TOP,
  PLOT_PADDING,
} from '@optimizer/constants/plotConstants';
import useScatterPlotData from '@optimizer/hooks/useScatterPlotData';
import {
  visibleConstraintMetricsState,
  visibleObjectiveMetricsState,
} from '@optimizer/states/metricsState';
import { plotConfigState } from '@optimizer/states/plotsStates';
import { CustomPlotMouseEvent } from '@optimizer/types/plotTypes';
import {
  formatMetricName,
  getAxisTickValues,
  getAxisTitle,
} from '@optimizer/utils/plotUtils';
import {
  Dropdown,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from '@pxui/components/ui';
import cn from '@pxui/lib/utils';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useUrlContext } from '@contexts/UrlQueryParamsContext';

import capitalizeFirstLetter from '@utils/capitalizeFirstLetter';

import PlotlyPlot from './PlotlyPlot';
// Constants for UI Texts
const PLOT_TITLE = '';

interface ScatterPlotProps {
  className?: string;
  onPointClick?: (e: CustomPlotMouseEvent) => void;
  plotId: string;
}

/**
 * Renders a scatter plot with customizable axes and filters for Pareto front and non-Pareto candidates.
 * Allows the user to select X and Y metrics from a dropdown and toggle the display of Pareto front and non-Pareto candidates.
 *
 * @param {ScatterPlotProps} props - The props required for rendering the ScatterPlot.
 * @param {string} props.plotId - Unique identifier for the plot.
 * @param {string} [props.className] - Optional class name for styling.
 * @param {(e: CustomPlotMouseEvent) => void} [props.onPointClick] - Optional callback for handling point click events on the plot.
 * @returns {JSX.Element} - A scatter plot with dropdowns for selecting X and Y axes and filters for Pareto and non-Pareto candidates.
 */
const ScatterPlot: FC<ScatterPlotProps> = ({
  onPointClick,
  plotId,
  className,
}) => {
  const { params, updateParams } = useUrlContext<OptimizerUrlState>();

  const objectiveMetrics = useRecoilValue(visibleObjectiveMetricsState);
  const constraintMetrics = useRecoilValue(visibleConstraintMetricsState);
  const [plotConfig, setPlotConfig] = useRecoilState(plotConfigState(plotId));

  // Fetch plot data and determine if Pareto front exists
  const [plotData, isLoading] = useScatterPlotData(plotId, params.paretoFront);

  const syncUrlParams = useCallback(
    (thisPlotId: string, updates: Record<string, any>) => {
      const updatedPlotConfigData = params.plotConfigData?.map(
        (plot) =>
          plot.id === thisPlotId
            ? {
                ...plot, // Keep original properties
                ...updates, // Apply updates
              }
            : plot, // Keep unchanged plots in the original order
      );
      if (updatedPlotConfigData) {
        updateParams({
          plotConfigData: updatedPlotConfigData,
        });
      }
    },
    [params.plotConfigData, updateParams],
  );

  // Set default metric configuration
  useEffect(() => {
    if (objectiveMetrics[0] && (!plotConfig.metricX || !plotConfig.metricY)) {
      const defaultMetricX = objectiveMetrics[0]?.name;
      const defaultMetricY = objectiveMetrics[1]?.name || defaultMetricX;

      setPlotConfig((prevConfig) => {
        const plotToUpdate = params?.plotConfigData?.find(
          (plot) => plot.id === prevConfig.id,
        );
        if (plotToUpdate && (!plotToUpdate.metricX || !plotToUpdate.metricY)) {
          syncUrlParams(plotConfig.id, {
            metricX: defaultMetricX,
            metricY: defaultMetricY,
          });
          return {
            ...prevConfig,
            metricX: defaultMetricX,
            metricY: defaultMetricY,
            name: `${capitalizeFirstLetter(defaultMetricX)} - ${capitalizeFirstLetter(defaultMetricY)}`,
          };
        }
        return {
          ...prevConfig,
          metricX: plotToUpdate?.metricX,
          metricY: plotToUpdate?.metricY,
          name: `${capitalizeFirstLetter(plotToUpdate?.metricX as string)} - ${capitalizeFirstLetter(plotToUpdate?.metricY as string)}`,
        };
      });
    }
  }, [
    objectiveMetrics,
    params?.plotConfigData,
    plotConfig,
    setPlotConfig,
    syncUrlParams,
  ]);

  const handleMetricChange = (newMetric: string, axis: 'X' | 'Y') => {
    setPlotConfig((prevConfig) => {
      syncUrlParams(prevConfig.id, {
        metricX: axis === 'X' ? newMetric : prevConfig.metricX,
        metricY: axis === 'Y' ? newMetric : prevConfig.metricY,
      });
      return {
        ...prevConfig,
        metricX: axis === 'X' ? newMetric : prevConfig.metricX,
        metricY: axis === 'Y' ? newMetric : prevConfig.metricY,
      };
    });
  };

  const memoizedLayout = useMemo(
    () => ({
      margin: {
        b: PLOT_MARGIN_BOTTOM,
        pad: PLOT_PADDING,
        r: PLOT_MARGIN_RIGHT,
        t: PLOT_MARGIN_TOP,
      },
      ...DEFAULT_PLOT_LAYOUT_CONFIG,
      xaxis: {
        ...PLOT_AXIS_LAYOUT_CONFIG,
        ...getAxisTickValues(plotData, 'x'),
        title: {
          ...PLOT_AXIS_TITLE_LAYOUT_CONFIG,
          text: getAxisTitle(plotConfig?.metricX || '', objectiveMetrics),
        },
      },
      yaxis: {
        ...PLOT_AXIS_LAYOUT_CONFIG,
        ...getAxisTickValues(plotData, 'y'),
        title: {
          ...PLOT_AXIS_TITLE_LAYOUT_CONFIG,
          text: getAxisTitle(plotConfig?.metricY || '', objectiveMetrics),
        },
      },
    }),
    [objectiveMetrics, plotConfig.metricX, plotConfig.metricY, plotData],
  );

  if (!plotConfig.metricX || !plotConfig.metricY) {
    return null; // Return nothing if no metrics are available for X or Y axis
  }

  return (
    <div
      className={cn('flex flex-col relative', className, {
        'animate-pulse': isLoading,
      })}
    >
      {isLoading && (
        <div className="text-on-surface-subtle absolute left-1/2 top-1/2 z-10">
          Loading ...
        </div>
      )}
      <PlotHeader title={PLOT_TITLE}>
        <div className="flex justify-between w-full">
          <div className="flex items-center">
            <Dropdown
              buttonText={
                objectiveMetrics.some(
                  (metric) => metric.name === plotConfig.metricX,
                )
                  ? formatMetricName(
                      capitalizeFirstLetter(plotConfig.metricX),
                      objectiveMetrics.find(
                        (m) => m.name === plotConfig.metricX,
                      )?.toMinimize ?? false,
                    )
                  : capitalizeFirstLetter(plotConfig.metricX) || ''
              }
              variant="ghost"
            >
              {objectiveMetrics.map((metric) => (
                <DropdownMenuItem
                  key={metric.name}
                  onClick={() => handleMetricChange(metric.name, 'X')}
                  className="label-1 text-secondary cursor-pointer select-none hover:bg-hover focus:bg-focus transition-colors"
                >
                  {formatMetricName(metric.name, metric.toMinimize)}
                </DropdownMenuItem>
              ))}
              {constraintMetrics.length > 0 && objectiveMetrics.length > 0 && (
                <DropdownMenuSeparator />
              )}
              {constraintMetrics.map((metric) => (
                <DropdownMenuItem
                  key={metric.name}
                  onClick={() => handleMetricChange(metric.name, 'X')}
                  className="label-1 text-secondary cursor-pointer select-none hover:bg-hover focus:bg-focus transition-colors"
                >
                  {metric.name}
                </DropdownMenuItem>
              ))}
            </Dropdown>

            <span className="text-primary">-</span>

            <Dropdown
              buttonText={
                objectiveMetrics.some(
                  (metric) => metric.name === plotConfig.metricY,
                )
                  ? formatMetricName(
                      capitalizeFirstLetter(plotConfig.metricY),
                      objectiveMetrics.find(
                        (m) => m.name === plotConfig.metricY,
                      )?.toMinimize ?? false,
                    )
                  : capitalizeFirstLetter(plotConfig.metricY) || ''
              }
              variant="ghost"
            >
              {objectiveMetrics.map((metric) => (
                <DropdownMenuItem
                  key={metric.name}
                  onClick={() => handleMetricChange(metric.name, 'Y')}
                  className="label-1 text-secondary cursor-pointer select-none hover:bg-hover focus:bg-focus transition-colors"
                >
                  {formatMetricName(metric.name, metric.toMinimize)}
                </DropdownMenuItem>
              ))}
              {constraintMetrics.length > 0 && objectiveMetrics.length > 0 && (
                <DropdownMenuSeparator />
              )}
              {constraintMetrics.map((metric) => (
                <DropdownMenuItem
                  key={metric.name}
                  onClick={() => handleMetricChange(metric.name, 'Y')}
                  className="label-1 text-secondary cursor-pointer select-none hover:bg-hover focus:bg-focus transition-colors"
                >
                  {metric.name}
                </DropdownMenuItem>
              ))}
            </Dropdown>
          </div>
        </div>
      </PlotHeader>

      {plotData && plotConfig.metricX ? (
        <PlotlyPlot
          data={plotData}
          layout={memoizedLayout}
          onPointClick={onPointClick}
          isActive={params.plotConfigData?.some(
            (plot) => plot.highlighted && plot.id === plotId,
          )}
        />
      ) : (
        <div /> // TODO: Add empty state handling
      )}
    </div>
  );
};

export default ScatterPlot;
