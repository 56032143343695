import moment from 'moment';
import { z } from 'zod';

import {
  MAX_COMMIT_SHA_LENGTH,
  MAX_DESCRIPTION_LENGTH,
  MAX_NAME_LENGTH,
  MIN_LENGTH,
  MAX_ICON_DATA_LENGTH,
} from '../constants/common';

const INVALID_DATETIME_MSG =
  'Invalid datetime string! Must be a valid ISO 8601 date.';
const ICON_VALIDATION_MSG =
  'Both icon data and file format must be provided together.';

const AuditMetadataSchema = z.object({
  created_by: z.string(),
  created_on: z
    .string()
    .refine((val) => moment(val).isValid(), {
      message: INVALID_DATETIME_MSG,
    })
    .transform((val) => moment.utc(val).local().toDate()),
  last_updated_by: z.string(),
  last_updated_on: z
    .string()
    .refine((val) => moment(val).isValid(), {
      message: INVALID_DATETIME_MSG,
    })
    .transform((val) => moment.utc(val).local().toDate()),
});

const BaseAppRegistrationSchema = z.object({
  commit_sha: z
    .string()
    .max(MAX_COMMIT_SHA_LENGTH)
    .regex(/^[a-zA-Z0-9_-]+$/)
    .optional()
    .nullable(),
  description: z.string().min(MIN_LENGTH).max(MAX_DESCRIPTION_LENGTH),
  display_name: z.string().min(MIN_LENGTH).max(MAX_NAME_LENGTH),
  embedded: z.boolean(),
  inference_api_url: z.string().url().optional().nullable(),
  order: z.number().int().default(-1).optional(),
  show_inference_link: z.boolean().optional().nullable(),
  type: z
    .string()
    .min(MIN_LENGTH)
    .max(MAX_NAME_LENGTH)
    .regex(/^[a-zA-Z0-9_-]+$/),
  url: z.string().url(),
  visible: z.boolean(),
});

const AppNameRegistrationSchema = z.object({
  name: z
    .string()
    .min(MIN_LENGTH)
    .max(MAX_NAME_LENGTH)
    .regex(/^[a-zA-Z0-9_-]+$/),
});

const IconDefinitionSchema = z
  .object({
    icon_data: z
      .string()
      .min(MIN_LENGTH)
      .max(MAX_ICON_DATA_LENGTH)
      .nullable()
      .optional(),
    icon_file_format: z.enum(['png', 'svg', 'webp']).nullable().optional(),
  })
  .superRefine((data, ctx) => {
    if (
      (data.icon_data && !data.icon_file_format) ||
      (!data.icon_data && data.icon_file_format)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: ICON_VALIDATION_MSG,
      });
    }
  });

export const GetAppRegistrationSchema = z
  .object({})
  .merge(AuditMetadataSchema)
  .merge(BaseAppRegistrationSchema)
  .merge(AppNameRegistrationSchema)
  .superRefine((data, ctx) => {
    if (!data.visible && !data.embedded) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'App must be either visible or embedded.',
      });
    }
  })
  .transform(
    ({
      commit_sha,
      created_by,
      created_on,
      display_name,
      inference_api_url,
      last_updated_by,
      last_updated_on,
      show_inference_link,
      ...rest
    }) => ({
      commit_sha,
      created_by,
      created_on,
      displayName: display_name,
      inferenceApiUrl: inference_api_url,
      lastUpdatedBy: last_updated_by,
      lastUpdatedOn: last_updated_on,
      showInferencelink: show_inference_link,
      ...rest,
    }),
  );

export type PxGetAppRegistrationSchema = z.infer<
  typeof GetAppRegistrationSchema
>;

export const GetAppIcon64Schema = IconDefinitionSchema.transform((data) => ({
  iconData: data.icon_data,
  iconFileFormat: data.icon_file_format,
}));

export const GetAppIconSchema = z.object({
  iconUrl: z.string(),
});

export type PxGetAppIcon64Schema = z.infer<typeof GetAppIcon64Schema>;
