import { useEffect, useMemo, type FC } from 'react';
import { useSearchParams } from 'react-router';

import { OptimizerUrlState } from '@config/optimizerUrl';
import CandidateSelectionModeList from '@optimizer/components/InspectorPage/InspectorSidebar/CandidateSelectionModeList/CandidateSelectionModeList';
import ConstraintsList from '@optimizer/components/InspectorPage/InspectorSidebar/ConstraintsList/ConstraintsList';
import ExcludeProperties from '@optimizer/components/InspectorPage/InspectorSidebar/ExcludeProperties/ExcludeProperties';
import OptimizationsList from '@optimizer/components/InspectorPage/InspectorSidebar/OptimizationsList/OptimizationsList';
import PlotsList from '@optimizer/components/InspectorPage/InspectorSidebar/PlotsList/PlotsList';
import StepsRange from '@optimizer/components/InspectorPage/InspectorSidebar/StepsRange/StepsRange';
import StepsSpacing from '@optimizer/components/InspectorPage/InspectorSidebar/StepsSpacing/StepsSpacing';
import UncertaintyMode from '@optimizer/components/InspectorPage/InspectorSidebar/UncertaintyMode/UncertaintyMode';
import useBatchOptimizations from '@optimizer/hooks/useBatchOptimizations';
import { optimizationsState } from '@optimizer/states/optimizationsState';
import { selectedOptimizationsStaticDataState } from '@optimizer/states/selectedOptimizationsStates';
import {
  MetricSpec,
  Optimization,
} from '@optimizer/types/services/allOptimizationsTypes';
import { List } from '@pxui/components/ui';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@pxui/components/ui/tabs/tabs';
import { useRecoilState } from 'recoil';

import { useUrlContext } from '@contexts/UrlQueryParamsContext';
import useAppNavigate from '@hooks/useAppNavigation';

enum tabsConfig {
  plot = 'plot',
  layout = 'layout',
}

const InspectorSidebar: FC = () => {
  const { params, updateParams } = useUrlContext<OptimizerUrlState>();

  const [selectedOptimizations, setSelectedOptimizations] = useRecoilState(
    selectedOptimizationsStaticDataState,
  );
  const [optimizations, setOptimizations] =
    useRecoilState<Optimization[]>(optimizationsState);
  const navigate = useAppNavigate();
  const [searchParams] = useSearchParams();
  const optId = searchParams.get('optimizationIds');

  const optimizationsArray = useMemo(
    () =>
      Array.from(
        new Set(
          [
            ...(optId ? JSON.parse(decodeURIComponent(optId)) : [null]),
            ...(params?.optimizationIds ?? []),
          ].filter(Boolean),
        ),
      ),

    [optId, params],
  );

  // Custom hook to fetch optimizations by their ids
  const { optimizations: newOpts, isLoading } = useBatchOptimizations(
    optimizationsArray as string[],
  );

  // Save loaded optimizations into state when `optimization` or `optId` changes,
  useEffect(() => {
    if (optimizationsArray.length && newOpts.length) {
      const loadedOptimizationIds = optimizations.map((opt) => opt.id);

      if (newOpts?.length) {
        const optimizationsToAdd = newOpts.filter(
          (el) => !loadedOptimizationIds.includes(el.id),
        );
        if (optimizationsToAdd.length) {
          setOptimizations((prev) => [...prev, ...optimizationsToAdd]);
        }
      }
    }
  }, [newOpts, setOptimizations, optimizations, optimizationsArray.length]);

  // Set selected optimizations when `optimization` or `optId` changes
  useEffect(() => {
    if (newOpts) {
      const newSelectedOpts = newOpts.filter(
        (newOpt) => !selectedOptimizations.some((opt) => newOpt.id === opt.id),
      );
      if (newSelectedOpts.length) {
        setSelectedOptimizations((prev) => [...prev, ...newSelectedOpts]);
      }
    }
  }, [newOpts, selectedOptimizations, setSelectedOptimizations]);

  // Load new metrics into the url
  useEffect(() => {
    const newOptimizationMetrics: MetricSpec[] = selectedOptimizations
      ?.map((el) => el.metricSpecs)
      .flat();

    const uniqueMetrics = Array.from(
      new Set(newOptimizationMetrics.map((item) => JSON.stringify(item))),
    ).map((item) => JSON.parse(item));

    const newMetrics = uniqueMetrics?.filter(
      (newMetric) =>
        !params?.metrics?.some((metrName) => newMetric.name !== metrName),
    );

    if (newMetrics.length) {
      updateParams({
        metrics: uniqueMetrics.map((el) => el.name),
      });
    }
  }, [params?.metrics, selectedOptimizations, updateParams]);

  // Navigate away if no optimizations are selected
  useEffect(() => {
    if (!selectedOptimizations[0] && !newOpts.length && !isLoading) {
      navigate();
    }
  }, [selectedOptimizations, newOpts, navigate, isLoading]);

  return (
    <section className="h-full">
      <List>
        <Tabs defaultValue={tabsConfig.plot} className="w-full">
          <TabsList>
            <TabsTrigger value={tabsConfig.layout}>Layout config</TabsTrigger>
            <TabsTrigger value={tabsConfig.plot}>Plot config</TabsTrigger>
          </TabsList>
          <TabsContent
            value={tabsConfig.layout}
            className="flex flex-col gap-4"
          >
            <OptimizationsList />
            <StepsRange />
            <StepsSpacing />
            <ConstraintsList />
          </TabsContent>
          <TabsContent value={tabsConfig.plot} className="flex flex-col gap-4">
            <PlotsList />
            <div>
              <div className="pb-2">
                <CandidateSelectionModeList />
              </div>
              <ExcludeProperties />
            </div>
            <UncertaintyMode />
          </TabsContent>
        </Tabs>
      </List>
    </section>
  );
};

export default InspectorSidebar;
