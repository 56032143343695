import { atom } from 'recoil';

export type UncertaintySelectionModeValue = '0' | '1';

export const uncertaintySelectionModeState =
  atom<UncertaintySelectionModeValue>({
    default: '0',
    key: 'uncertaintySelectionModeState',
  });

export default uncertaintySelectionModeState;
