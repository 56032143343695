import { useState, type FC } from 'react';

import { LINK_COPIED_TIMEOUT } from '@flow/constants';
import { Icon } from '@pxui/components/ui';
import cn from '@pxui/lib/utils';

interface GridItemMetadataProps {
  candidateId: string;
  optimizationId: string;
}

type MetadataInfoStates = {
  candidateId: boolean;
  optimizationId: boolean;
};

const METADATA_INFO_INIT_STATE = {
  candidateId: false,
  optimizationId: false,
};

const buttonClasses = 'cursor-pointer';
const labelClasses =
  'label-1 flex items-center gap-2 text-on-surface-subtle px-2 py-1';
const successTextClasses =
  'label-1 text-key-success ml-2 opacity-0 transition-opacity duration-300 cursor-default';
const titleClasses = 'label-1-strong text-on-surface';

const GridItemMetadata: FC<GridItemMetadataProps> = ({
  candidateId,
  optimizationId,
}) => {
  const [showHelperText, setShowHelperText] = useState<MetadataInfoStates>(
    METADATA_INFO_INIT_STATE,
  );
  const [isClicking, setIsClicking] = useState<MetadataInfoStates>(
    METADATA_INFO_INIT_STATE,
  );

  const copyToClipboard = (text: string) => navigator.clipboard.writeText(text);

  const handleIconClick = (key: keyof MetadataInfoStates, text: string) => {
    copyToClipboard(text);
    setIsClicking((prev) => ({ ...prev, [key]: true }));
    setShowHelperText((prev) => ({ ...prev, [key]: true }));
    setTimeout(() => {
      setShowHelperText((prev) => ({ ...prev, [key]: false }));
    }, LINK_COPIED_TIMEOUT);
  };

  const resetIconClick = (key: keyof MetadataInfoStates) => {
    setIsClicking((prev) => ({ ...prev, [key]: false }));
  };

  return (
    <div className="flex flex-col">
      <div className="p-2">
        <div className={titleClasses}>Metadata</div>
      </div>
      <div className={labelClasses}>
        <span>Optimization ID</span>
        <button
          className={cn(buttonClasses, {
            'opacity-50': isClicking.optimizationId,
          })}
          onMouseDown={() => handleIconClick('optimizationId', optimizationId)}
          onMouseUp={() => resetIconClick('optimizationId')}
          type="button"
        >
          <Icon
            name="content_copy"
            className="text-secondary hover:text-primary cursor-pointer"
          />
        </button>
        <span
          className={cn(successTextClasses, {
            'opacity-100': showHelperText.optimizationId,
          })}
        >
          Copied
        </span>
      </div>
      <div className={cn(labelClasses, 'relative')}>
        <span>Candidate ID</span>
        <button
          className={cn(buttonClasses, {
            'opacity-50': isClicking.candidateId,
          })}
          onMouseDown={() => handleIconClick('candidateId', candidateId)}
          onMouseUp={() => resetIconClick('candidateId')}
          type="button"
        >
          <Icon
            name="content_copy"
            className="text-secondary hover:text-primary cursor-pointer"
          />
        </button>
        <span
          className={cn(successTextClasses, {
            'opacity-100': showHelperText.candidateId,
          })}
        >
          Copied
        </span>
      </div>
    </div>
  );
};

export default GridItemMetadata;
