import { atom, selector } from 'recoil';

import { baselineCandidateIdState } from './baselineCandidateIdState';
import { comparisonState } from './comparisonState';

/*
The atom state is used to track whether the comparison state is on or off. The selector's set method selects the first candidate from the comparison state array as the baseline candidate if there are at least two candidates in the array.

It turns off the comparison state if there is only one candidate in the comparison state array.
*/

export const showDiffState = atom<boolean>({
  default: false,
  key: 'showDiffState',
});

export const showDiffSelector = selector<boolean>({
  get: ({ get }) => get(showDiffState),
  key: 'showDiffSelector',
  set: ({ get, set }, newValue) => {
    const isBaselineCandidate = get(baselineCandidateIdState);
    const comparisonCandidates = get(comparisonState);
    const areAtLestTwoCandidates = comparisonCandidates.length > 1;

    if (!isBaselineCandidate && areAtLestTwoCandidates) {
      set(baselineCandidateIdState, comparisonCandidates[0].candidate.id);
    }

    if (!areAtLestTwoCandidates || !newValue) {
      set(baselineCandidateIdState, null);
    }

    set(showDiffState, newValue);
  },
});
