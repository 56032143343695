import { useEffect, useMemo, useState } from 'react';

import { OptimizerUrlState } from '@config/optimizerUrl';
import { fetchFilteredOptimizations } from '@optimizer/services/fetchFilteredOptimizations';
import { candidatesState } from '@optimizer/states/candidatesState';
import { visibleMetricsState } from '@optimizer/states/metricsState';
import { plotConfigState } from '@optimizer/states/plotsStates';
import selectedCandidateState from '@optimizer/states/selectedCandidateState';
import { selectedConstraintsState } from '@optimizer/states/selectedConstraintsState';
import { visibleSelectedOptimizationsState } from '@optimizer/states/selectedOptimizationsStates';
import {
  getSubsampledSteps,
  stepsRangeFilterState,
} from '@optimizer/states/selectedStepsState';
import uncertaintySelectionModeState from '@optimizer/states/uncertaintySelectionMode';
import { CustomPlotData } from '@optimizer/types/plotTypes';
import {
  GetFilteredOptimizationDataRequest,
  Candidate,
} from '@optimizer/types/services/filteredOptimizationsTypes';
import transformOptimizationDataToScatterPlotData from '@optimizer/utils/transformOptimizationDataToScatterPlotData';
import { useRecoilValue, useRecoilCallback, useRecoilState } from 'recoil';

import { useUrlContext } from '@contexts/UrlQueryParamsContext';

/**
 * Custom hook to fetch and transform data for Plotly scatter plots.
 * Fetches data based on selected optimizations and metrics, then transforms it for rendering.
 *
 * @param {string} plotId - The ID of the plot for which data needs to be fetched and transformed.
 * @param {boolean} [includeParetoFront=true] - Whether to include Pareto front data in the plot.
 * @param {boolean} [includeNonParetoCandidates=true] - Whether to include non-Pareto candidates in the plot.
 * @returns {[Partial<CustomPlotData>[], boolean, boolean]} - An array of data formatted for Plotly rendering and a flag indicating if Pareto front data exists and loading state.
 */
const useScatterPlotData = (
  plotId: string,
  selection: OptimizerUrlState['paretoFront'],
): [Partial<CustomPlotData>[], boolean] => {
  const plotConfig = useRecoilValue(plotConfigState(plotId));
  const visibleOptimizations = useRecoilValue(
    visibleSelectedOptimizationsState,
  );
  const { params } = useUrlContext<OptimizerUrlState>();
  const metrics = useRecoilValue(visibleMetricsState);
  const selectedConstraints = useRecoilValue(selectedConstraintsState);
  const selectedCandidate = useRecoilValue(selectedCandidateState);
  const [plotData, setPlotData] = useState<Partial<CustomPlotData>[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const stepsRangeFilter = useRecoilValue(stepsRangeFilterState);
  const subsampledSteps = useRecoilValue(getSubsampledSteps);
  const [uncertaintySelectionMode] = useRecoilState(
    uncertaintySelectionModeState,
  );

  const setCandidates = useRecoilCallback(
    ({ set }) =>
      (candidates: Record<string, Candidate>) => {
        Object.entries(candidates).forEach(([id, candidate]) => {
          set(candidatesState(id), candidate);
        });
      },
    [],
  );

  const plotDataRequestParams: GetFilteredOptimizationDataRequest =
    useMemo(() => {
      const optimizationIds = visibleOptimizations.map((opt) => opt.id);

      return {
        max_step: stepsRangeFilter[1],
        metrics: [plotConfig?.metricX as string, plotConfig?.metricY as string],
        min_step: stepsRangeFilter[0],
        optimization_ids: optimizationIds,
        satisfied_constraints: selectedConstraints?.length
          ? selectedConstraints
          : undefined,
        steps: subsampledSteps,
      };
    }, [
      plotConfig?.metricX,
      plotConfig?.metricY,
      selectedConstraints,
      stepsRangeFilter,
      subsampledSteps,
      visibleOptimizations,
    ]);

  useEffect(() => {
    const getPlotData = async () => {
      if (
        !plotConfig.metricX ||
        !plotConfig.metricY ||
        (!metrics.length && !params.metrics?.length)
      )
        return;

      const optimizationIds = visibleOptimizations.map((opt) => opt.id);
      if (!optimizationIds.length && !params.optimizationIds?.length) return;

      setIsLoading(true);

      try {
        const dataMarker = await fetchFilteredOptimizations({
          ...plotDataRequestParams,
          include_pareto_front: selection?.marker === 'Pareto',
        });
        const dataLine = await fetchFilteredOptimizations({
          ...plotDataRequestParams,
          include_pareto_front: selection?.line === 'Pareto',
        });

        setIsLoading(false);
        if (!dataMarker || !dataLine) return;

        const [transformedDataMarker, candidatesMarker] =
          transformOptimizationDataToScatterPlotData(
            visibleOptimizations,
            dataMarker.optimizationData,
            plotConfig.metricX,
            plotConfig.metricY,
            selection?.marker === 'Pareto',
            selection?.marker === 'All candidates',
            selectedCandidate?.id || undefined,
            Boolean(Number(uncertaintySelectionMode)),
            'markers',
          );

        const [transformedDataLine, candidatesLine] =
          transformOptimizationDataToScatterPlotData(
            visibleOptimizations,
            dataLine.optimizationData,
            plotConfig.metricX,
            plotConfig.metricY,
            selection?.line === 'Pareto',
            false,
            selectedCandidate?.id || undefined,
            Boolean(Number(uncertaintySelectionMode)),
            'lines',
          );

        setPlotData([...transformedDataMarker, ...transformedDataLine]);
        setCandidates({ ...candidatesMarker, ...candidatesLine });
      } catch (error) {
        console.error('Failed to fetch and set plot data:', error);
        // TODO: Handle error using a toast
      }
    };

    getPlotData();
  }, [
    plotConfig,
    visibleOptimizations,
    metrics,
    plotId,
    setCandidates,
    selectedConstraints,
    selectedCandidate?.id,
    plotDataRequestParams,
    params.metrics?.length,
    params.optimizationIds?.length,
    uncertaintySelectionMode,
    selection?.marker,
    selection?.line,
  ]);

  return [plotData, isLoading];
};

export default useScatterPlotData;
