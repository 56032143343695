import React from 'react';

import { GeometryInspectorBottomPanel } from '@optimizer/components';
import { GeometryInspectorPanel } from '@pxui/components/ui';

interface GeometryInspectorPanelWrapperProps {
  buttons: React.ReactNode;
  bottomButtons?: React.ReactNode;
  geometries: {
    filename: string;
    name: string;
    url: string;
  }[];
  isLoading: boolean;
  onClose: () => void;
  onExpand: () => void;
  open: boolean;
  optimizationId: string;
  selectedCandidateId?: string;
  selectedCandidateStep?: number;
}

/**
 * Wrapper for the GeometryInspectorPanel with bottom panel integration.
 */
const GeometryInspectorPanelWrapper: React.FC<
  GeometryInspectorPanelWrapperProps
> = ({
  bottomButtons,
  open,
  geometries,
  isLoading,
  optimizationId,
  selectedCandidateId,
  selectedCandidateStep,
  buttons,
  onClose,
  onExpand,
}) => {
  return (
    <GeometryInspectorPanel
      open={open}
      geometries={geometries}
      isLoading={isLoading}
      buttons={buttons}
      onClose={onClose}
      onExpand={onExpand}
    >
      {selectedCandidateId && (
        <GeometryInspectorBottomPanel
          bottomButtons={bottomButtons}
          optimizationId={optimizationId}
          selectedCandidateId={selectedCandidateId}
          step={selectedCandidateStep}
        />
      )}
    </GeometryInspectorPanel>
  );
};

export default React.memo(GeometryInspectorPanelWrapper);
