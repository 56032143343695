import type { FC } from 'react';

interface ErrorTextProps {
  errorText?: string;
  size?: 'small' | 'medium';
}

const ErrorText: FC<ErrorTextProps> = ({ errorText, size = 'medium' }) => {
  if (!errorText) return null;

  const labelSize = size === 'small' ? 'label-2' : 'label-1';

  return <span className={`${labelSize} text-error`}>{errorText}</span>;
};

export { ErrorText, type ErrorTextProps };
