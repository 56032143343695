import moment from 'moment';

const TIME_FORMAT = 'hh:mm A';

/**
 * Formats a given date object into a time string using the specified format.
 * Throws an error if the date is invalid.
 *
 * @param {Date} date - The date object to format.
 * @returns {string} - The formatted time string.
 * @throws {Error} - Throws an error if the provided date is invalid.
 */
export default function formatTime(date: Date | string): string {
  if (!date || Number.isNaN(new Date(date).getTime())) {
    throw new Error('Invalid date provided');
  }

  return moment(date).format(TIME_FORMAT);
}
