import { MetricTypeEnum } from '@optimizer/schemas/allOptimizationsSchema';
import {
  MetricSpec,
  ObjectiveMetricSpec,
  OutcomeConstraintMetricSpec,
  ParameterSpec,
  TrackingMetricSpec,
} from '@optimizer/types/services/allOptimizationsTypes';
import { selector } from 'recoil';

import { visibleSelectedOptimizationsState } from './selectedOptimizationsStates';

export const visibleMetricsState = selector<MetricSpec[]>({
  get: ({ get }) => {
    const visibleOptimizations = get(visibleSelectedOptimizationsState);

    const allMetrics = visibleOptimizations.flatMap(
      (optimization) => optimization.metricSpecs,
    );

    const uniqueMetrics = Array.from(
      new Set(allMetrics.map((metric) => metric.name)),
    ).map((metricName) =>
      allMetrics.find((metric) => metric.name === metricName),
    );

    return uniqueMetrics as MetricSpec[];
  },
  key: 'visibleMetricsState',
});

export const visibleObjectiveMetricsState = selector<ObjectiveMetricSpec[]>({
  get: ({ get }) => {
    const metrics = get(visibleMetricsState);

    return metrics.filter((metric) => metric.type === MetricTypeEnum.OBJECTIVE);
  },
  key: 'visibleObjectiveMetricsState',
});

export const visibleConstraintMetricsState = selector<
  OutcomeConstraintMetricSpec[]
>({
  get: ({ get }) => {
    const metrics = get(visibleMetricsState);

    return metrics.filter(
      (metric) => metric.type === MetricTypeEnum.OUTCOME_CONSTRAINT,
    );
  },
  key: 'visibleConstraintMetricsState',
});

export const visibleTrackingMetricsState = selector<TrackingMetricSpec[]>({
  get: ({ get }) => {
    const metrics = get(visibleMetricsState);

    return metrics.filter((metric) => metric.type === MetricTypeEnum.TRACKING);
  },
  key: 'visibleTrackingMetricsState',
});

export const visibleParametersState = selector<ParameterSpec[]>({
  get: ({ get }) => {
    const visibleOptimizations = get(visibleSelectedOptimizationsState);

    const allParameters = visibleOptimizations
      .flatMap((optimization) => optimization?.parameterSpecs)
      .filter(Boolean); // Remove undefined entries here

    const uniqueParameters = Array.from(
      new Set(allParameters.map((params) => params?.name)),
    ).map((paramName) =>
      allParameters.find((params) => params?.name === paramName),
    );

    return uniqueParameters as ParameterSpec[];
  },
  key: 'visibleParametersState',
});
