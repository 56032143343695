import {
  NavigateOptions,
  To,
  useNavigate,
  useOutletContext,
} from 'react-router';

export const useAppNavigate = () => {
  const navigate = useNavigate();
  const pathname = useOutletContext<string>();

  return (to?: To, options?: NavigateOptions | undefined) => {
    const normalizedPathname = pathname.endsWith('/')
      ? pathname.slice(0, -1)
      : pathname;

    navigate(to ? `${normalizedPathname}/${to}` : pathname, options);
  };
};

export default useAppNavigate;
