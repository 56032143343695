import {
  compareViewState,
  comparisonState,
} from '@optimizer/states/comparisonState';
import selectedCandidateState from '@optimizer/states/selectedCandidateState';
import { Button } from '@pxui/components/ui';
import { useRecoilState, useRecoilValue } from 'recoil';

const CompareButton = () => {
  const candidates = useRecoilValue(comparisonState);
  const [isOnCompareView, setCompareView] = useRecoilState(compareViewState);
  const [selectedCandidate, setSelectedCandidate] = useRecoilState(
    selectedCandidateState,
  );
  const candidatesLength = candidates.length;
  const isDisabled = candidatesLength < 2;

  const handleClick = () => {
    if (!selectedCandidate && !isDisabled) {
      const {
        candidate: { id },
        optimizationId,
        step,
      } = candidates[candidates.length - 1] ?? {};

      setSelectedCandidate({
        id,
        optimizationId: optimizationId as string,
        step,
      });

      setCompareView(true);

      return;
    }

    setCompareView(!isOnCompareView);
  };

  const renderLabel = () => {
    const text = `Compare View (${candidatesLength})`;

    if (isDisabled) {
      return text;
    }

    if (isOnCompareView && selectedCandidate) {
      return 'Candidate View';
    }

    return text;
  };

  return (
    <Button
      className="mr-2"
      disabled={isDisabled}
      layout="textOnly"
      onClick={handleClick}
      variant="primary"
    >
      {renderLabel()}
    </Button>
  );
};

export default CompareButton;
