import { type FC } from 'react';

import { InfoSection } from '@optimizer/components';
import OptimizationIdentifier from '@optimizer/components/common/InfoSection/OptimizationIdentifier';
import { optimizationsDataState } from '@optimizer/states/optimizationsState';
import { selectedOptimizationsDynamicDataState } from '@optimizer/states/selectedOptimizationsStates';
import { useRecoilValue } from 'recoil';

interface GeometryInspectorBottomPanelProps {
  bottomButtons?: React.ReactNode;
  optimizationId: string;
  selectedCandidateId: string;
  step?: number;
}

const GeometryInspectorBottomPanel: FC<GeometryInspectorBottomPanelProps> = ({
  bottomButtons,
  optimizationId,
  selectedCandidateId,
  step,
}) => {
  const optimization = useRecoilValue(optimizationsDataState(optimizationId));
  const dynamicData = useRecoilValue(
    selectedOptimizationsDynamicDataState(optimizationId),
  );

  return (
    <div className="w-full flex flex-col gap-6">
      <div className="flex flex-row justify-end items-center pr-8">
        {Boolean(bottomButtons) && bottomButtons}
      </div>
      <OptimizationIdentifier
        color={dynamicData.color}
        name={optimization?.name ?? ''}
        step={step}
      />
      <InfoSection
        optimizationId={optimizationId}
        selectedCandidateId={selectedCandidateId}
      />
    </div>
  );
};

export default GeometryInspectorBottomPanel;
