import { toast } from '@pxui/components/ui/toast/useToast';

import apiClient from '@services/interceptors/authInterceptor';

export const deleteOptimization = async (
  optimizationId: string,
  name: string | null,
): Promise<undefined> => {
  try {
    await apiClient.delete(
      `${process.env.PLATFORM_API_URL}/optimizer/optimizations/${optimizationId}`,
    );
    toast({
      description: `Optimization ${name || ''} was deleted successfully`,
      title: 'Optimization deleted',
      variant: 'success',
    });
  } catch (error) {
    toast({
      description: `Failed to delete optimization ${name} with id: ${optimizationId}`,
      title: 'Error',
      variant: 'error',
    });
    return undefined;
  }
};

export default deleteOptimization;
