import { SelectedOptimizationDynamicData } from '@optimizer/states/selectedOptimizationsStates';
import { CustomPlotData, CustomPlotDatum } from '@optimizer/types/plotTypes';
import { Optimization } from '@optimizer/types/services/allOptimizationsTypes';
import { Candidate } from '@optimizer/types/services/filteredOptimizationsTypes';
import { Datum } from 'plotly.js';
/**
 * Transforms optimization data into a format suitable for Plotly line plots.
 *
 * @param {Optimization[]} visibleOptimizations - Array of visible optimizations with color.
 * @param {Record<string, Step[]>} optimizationData - The fetched optimization data.
 * @param {string} metricY - The Y-axis metric for the plot.
 * @param {string | null} selectedCandidateId - The ID of the selected candidate to highlight.
 * @returns {[Partial<CustomPlotData>[], Record<string, Candidate>]} The transformed data ready for Plotly rendering and the candidates.
 */
export const transformOptimizationDataToLinePlotData = (
  visibleOptimizations: (Optimization & SelectedOptimizationDynamicData)[],
  optimizationData: Optimization[],
  metricY: string,
  selectedCandidateId: string | null,
  areErrorsVisible: boolean | undefined,
  dataSeriesMode: 'lines' | 'markers',
): [Partial<CustomPlotData>[], Record<string, Candidate>] => {
  const plotData: Partial<CustomPlotData>[] = [];
  const allCandidates: Record<string, Candidate> = {};

  optimizationData.forEach(
    ({ id: optimizationId, filteredStepData: steps }) => {
      const xValues: Datum[] = [];
      const yValues: Datum[] = [];
      const customdata: CustomPlotDatum[] = [];
      const selectedpoints: number[] = [];
      let pointCount = 0;
      const uncertainties: number[] = [];

      steps?.forEach((step) => {
        const candidate = step.selectedCandidatesByMetric?.[metricY];
        if (candidate?.id && optimizationId) {
          const metric = candidate.metrics.find((m) => m.name === metricY);

          if (metric?.value) {
            allCandidates[candidate.id] = candidate;
            xValues.push(step.step);
            yValues.push(metric.value);
            uncertainties.push(metric.uncertainty || 0);
            customdata.push([candidate.id, optimizationId, step.step]);

            if (candidate.id === selectedCandidateId) {
              selectedpoints.push(pointCount);
            }
            pointCount++;
          }
        }
      });

      plotData.push({
        customdata,
        error_y: {
          array: uncertainties,
          color:
            visibleOptimizations.find((opt) => opt.id === optimizationId)
              ?.color || '',
          opacity: 0.5,
          thickness: 1,
          type: 'data',
          visible: areErrorsVisible,
          width: 2,
        },
        hoverinfo: 'text',
        line: {
          color:
            visibleOptimizations.find((opt) => opt.id === optimizationId)
              ?.color || '',
          width: 1,
        },
        marker: {
          opacity: 1,
          size: 6,
          symbol: Object.values(allCandidates).map(({ isFeasible }) =>
            isFeasible ? 'circle' : 'circle-open',
          ),
        },
        mode: dataSeriesMode,
        name: '',
        selected: {
          marker: {
            color: 'white',
            opacity: 1,
            size: 12,
            symbol: ['square'],
          },
        },
        selectedpoints,
        text: xValues.map(
          (x, index) =>
            `X: ${x} ±0<br>Y: ${yValues[index]}<br>±${uncertainties[index]}`,
        ),
        type: 'scatter',
        unselected: {
          marker: {
            opacity: 1,
          },
        },
        x: xValues,
        y: yValues,
      } as Partial<CustomPlotData>);
    },
  );

  return [plotData, allCandidates];
};

export default transformOptimizationDataToLinePlotData;
