import { PLOT_COLORS } from '@optimizer/constants/plotConstants';

export const colorCache: Record<string, string> = {};

let nextColor = 0;
const colors = [
  PLOT_COLORS.golden,
  PLOT_COLORS.blue,
  PLOT_COLORS.red,
  PLOT_COLORS.rose,
  PLOT_COLORS.emerald,
  PLOT_COLORS.brown,
  PLOT_COLORS.ivory,
  PLOT_COLORS.khaki,
  PLOT_COLORS.orange,
  PLOT_COLORS.lightGreen,
  PLOT_COLORS.papyrus,
  PLOT_COLORS.beige,
];
/**
 * Generates and caches a unique color for each optimization based on its ID.
 * @param {string} optimizationId - The unique identifier for the optimization.
 * @returns {string} The hexadecimal color string associated with the given optimization ID.
 * If the optimization ID has been seen before, returns the cached color. Otherwise,
 * generates a new color, caches it, and then returns it.
 */
export default function getColorForOptimization(
  optimizationId: string,
): string {
  if (colorCache[optimizationId]) {
    return colorCache[optimizationId];
  }

  // Generate a new color in hexadecimal format. The magic number 16777215 converts to 'ffffff' in hexadecimal,
  // representing the highest value in 24-bit color. Using `Math.random`, we generate a color within this range.
  // const newColor = `#${Math.floor(Math.random() * FFFFFF).toString(hexBase)}`;
  const newColor = colors[nextColor % colors.length];
  nextColor += 1;
  colorCache[optimizationId] = newColor;
  return newColor;
}
