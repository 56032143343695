import { useCallback, useEffect, type FC } from 'react';

import { OptimizerUrlState } from '@config/optimizerUrl';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  plotConfigState,
  plotsStaticDataState,
} from '@optimizer/states/plotsStates';
import { PlotType } from '@optimizer/types/plotStateTypes';
import { Icon, ListItem } from '@pxui/components/ui';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { useUrlContext } from '@contexts/UrlQueryParamsContext';

interface PlotsListItemProps {
  plotId: string;
}

const PlotsListItem: FC<PlotsListItemProps> = ({ plotId }) => {
  const { params, updateParams } = useUrlContext<OptimizerUrlState>();
  const [plotConfig, setPlotConfig] = useRecoilState(plotConfigState(plotId));
  const [plotsStaticData, setPlotsStaticData] =
    useRecoilState(plotsStaticDataState);
  const resetPlotConfig = useResetRecoilState(plotConfigState(plotId));

  // Enable drag-and-drop for this item using useSortable
  const {
    attributes, // Drag attributes
    listeners, // Event listeners for dragging
    setNodeRef, // Ref for the draggable item
    transform, // Transformation applied to the item while dragging
    transition, // Transition while dragging
  } = useSortable({ id: plotId });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleToggleHighlight = useCallback(() => {
    setPlotConfig((currentPlot) => {
      const updatedPlotConfigData = params?.plotConfigData?.map((el) => ({
        ...el,
        highlighted: el.id === currentPlot.id,
      }));

      if (updatedPlotConfigData) {
        updateParams({ plotConfigData: updatedPlotConfigData });
      }

      return {
        ...currentPlot,
        highlighted: !currentPlot.highlighted,
      };
    });
  }, [params?.plotConfigData, setPlotConfig, updateParams]);

  useEffect(() => {
    if (!params?.plotConfigData?.find((plot) => plot.highlighted)) {
      if (plotsStaticData[0].id === plotId) {
        handleToggleHighlight();
      }
    }
  }, [handleToggleHighlight, params?.plotConfigData, plotId, plotsStaticData]);

  if (!plotConfig?.name) return null;

  const handleDeletePlot = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); // Prevents the toggle highlight function from being triggered
    if (plotsStaticData.length > 1) {
      setPlotsStaticData((prevPlots) =>
        prevPlots.filter((plot) => plot.id !== plotId),
      );
      updateParams({
        plotConfigData: [
          ...(params.plotConfigData?.filter((el) => el.id !== plotId) ?? []),
        ],
      });
      resetPlotConfig();
    }
  };

  const renderChartTypeIcon = () => {
    if (plotConfig.type === PlotType.SCATTER) {
      return <Icon name="scatter_plot" />;
    }

    if (plotConfig.type === PlotType.PARALLEL) {
      return <Icon name="graphic_eq" />;
    }

    return <Icon name="timeline" />;
  };

  return (
    <div ref={setNodeRef} style={style}>
      <ListItem
        onClick={handleToggleHighlight}
        label={plotConfig.name}
        isActive={
          params?.plotConfigData?.find((plot) => plot.id === plotId)
            ?.highlighted
        }
        className="flex items-center justify-between"
        prefix={
          <div className="text-on-surface-subtle">{renderChartTypeIcon()}</div>
        }
        suffix={
          <>
            <button
              type="button"
              aria-label="Drag plot"
              className="flex items-center justify-center opacity-50 hover:opacity-100"
              onClick={(e) => e.stopPropagation()}
              {...attributes}
              {...listeners} // do not move, otherwise delete is not clickable
            >
              <Icon name="drag_indicator" className="text-on-surface-subtle" />
            </button>
            {plotsStaticData.length > 1 && (
              <button
                type="button"
                aria-label="Delete plot"
                className="flex items-center justify-center opacity-50 hover:opacity-100"
                onClick={handleDeletePlot}
              >
                <Icon name="close" className="text-on-surface-subtle" />
              </button>
            )}
          </>
        }
      />
    </div>
  );
};

export default PlotsListItem;
