import { type HTMLAttributes, type ReactNode, forwardRef } from 'react';

import { buttonVariants } from '@pxui/components/ui/button';
import {
  DropdownMenuContent,
  DropdownMenuTriggerButton,
} from '@pxui/components/ui/dropdown';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { VariantProps } from 'class-variance-authority';

import { ScrollArea, ScrollBar } from '../scroll-area';

const DropdownMenu = DropdownMenuPrimitive.Root;
const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;

interface DropdownProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof buttonVariants> {
  buttonText: string | ReactNode;
  contentClassName?: string;
  buttonClassName?: string;
  disabled?: boolean;
  hideArrow?: boolean;
}

const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      className,
      contentClassName,
      buttonClassName,
      children,
      buttonText,
      variant = 'primary',
      disabled,
      hideArrow = false,
      ...props
    },
    ref,
  ) => (
    <div className={className} ref={ref} {...props}>
      <DropdownMenu>
        <DropdownMenuTrigger disabled={disabled} asChild className="w-full">
          <DropdownMenuTriggerButton
            disabled={disabled}
            variant={variant}
            hideArrow={hideArrow}
            className={buttonClassName}
          >
            {buttonText}
          </DropdownMenuTriggerButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent className={contentClassName}>
          <ScrollArea>
            {children}
            <ScrollBar orientation="vertical" />
          </ScrollArea>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  ),
);

Dropdown.displayName = 'Dropdown';

export { Dropdown, DropdownMenu, DropdownMenuTrigger, type DropdownProps };
