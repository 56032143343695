import { useEffect, useState, useCallback, useRef } from 'react';

import { OptimizationStatusEnum } from '@optimizer/schemas/allOptimizationsSchema';
import { fetchAllOptimizations } from '@optimizer/services/fetchAllOptimizations';
import { optimizationsState } from '@optimizer/states/optimizationsState';
import paginationState from '@optimizer/states/paginationState';
import { Optimization } from '@optimizer/types/services/allOptimizationsTypes';
import pollOptimizationStatus from '@optimizer/utils/pollOptimizationStatus';
import { useRecoilState } from 'recoil';

import { PAGE_SIZE, POLLING_INTERVAL } from '@constants/common';

/**
 * Custom hook to manage and fetch optimizations with pagination and real-time status updates.
 * Includes polling for optimizations with a "RUNNING" status until they complete.
 *
 * @returns {{
 *   error: string | null;
 *   fetchMore: () => Promise<number | void>;
 *   isLoading: boolean;
 *   optimizations: Optimization[];
 * }} - Returns error, fetchMore function, loading state, and optimizations array.
 */
const useOptimizations = () => {
  const [{ pageToken }, setPagination] = useRecoilState(paginationState);
  const [optimizations, setOptimizations] =
    useRecoilState<Optimization[]>(optimizationsState);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const cancelPollingMap = useRef<Map<string, () => void>>(new Map());
  const isMounted = useRef(true);

  /**
   * Updates optimization data in the Recoil state.
   *
   * @param {Optimization} updatedOptimization - The updated optimization object.
   */
  const updateOptimizationData = useCallback(
    (updatedOptimization: Optimization) => {
      if (isMounted.current) {
        setOptimizations((prev) =>
          prev.map((opt) =>
            opt.id === updatedOptimization.id ? updatedOptimization : opt,
          ),
        );
      }
    },
    [setOptimizations],
  );

  /**
   * Starts polling the status of a single optimization.
   *
   * @param {Optimization} optimization - The optimization to start polling.
   */
  const startPolling = useCallback(
    (optimization: Optimization) => {
      if (cancelPollingMap.current.has(optimization.id)) return;

      const cancel = pollOptimizationStatus(
        optimization.id,
        optimization.stepsCompleted,
        optimization.status,
        ({
          error: pollError,
          optimization: updatedOptimization,
        }: {
          error?: boolean;
          optimization: Optimization;
        }) => {
          if (pollError && isMounted.current) {
            setError(`Update error for optimization ${optimization.id}`);
          }
          updateOptimizationData(updatedOptimization);
        },
      );

      cancelPollingMap.current.set(optimization.id, cancel);
    },
    [updateOptimizationData],
  );

  /**
   * Fetches more optimizations for pagination, updating pagination token and appending new optimizations to state.
   *
   * @returns {Promise<number | void>} - Number of new optimizations fetched.
   */
  const fetchMore = useCallback(async () => {
    if (
      (!pageToken && optimizations.length > 0) ||
      isLoading ||
      error ||
      isEmpty
    )
      return;

    setIsLoading(true);
    setError(null);

    try {
      const allOptimizations = await fetchAllOptimizations(
        PAGE_SIZE,
        pageToken,
      );
      if (!allOptimizations) {
        setError('Failed to fetch optimizations');
        return;
      }

      const { optimizations: newOptimizations, nextPageToken = null } =
        allOptimizations;
      if (newOptimizations.length === 0 && nextPageToken === null) {
        setIsEmpty(true);
        return;
      }

      // Add loaded optimizations after the previous optimizations, as they belong to the next page
      setOptimizations((prevOptimizations) => [
        ...prevOptimizations,
        ...newOptimizations.filter(
          (newOpt) => !prevOptimizations.some((opt) => opt.id === newOpt.id),
        ),
      ]);

      setPagination((prevState) => ({
        ...prevState,
        pageToken: nextPageToken,
      }));

      // Start polling for any new optimizations with status RUNNING
      newOptimizations
        .filter((opt) => opt.status === OptimizationStatusEnum.RUNNING)
        .forEach(startPolling);

      return newOptimizations.length;
    } catch (fetchError) {
      console.error('Error fetching optimizations:', fetchError);
      setError('Failed to fetch optimizations');
    } finally {
      setIsLoading(false);
    }
  }, [
    pageToken,
    optimizations.length,
    isLoading,
    error,
    isEmpty,
    setOptimizations,
    setPagination,
    startPolling,
  ]);

  /**
   * Fetches only the latest optimizations created after the last optimization in the state.
   */
  const fetchUpdates = useCallback(async () => {
    if (optimizations.length === 0) return;

    // Determine the "createdAfter" timestamp based on the latest optimization
    const lastCreatedAt = optimizations[0].createdAt;

    try {
      const allOptimizations = await fetchAllOptimizations(
        PAGE_SIZE,
        null,
        lastCreatedAt,
      );
      if (!allOptimizations) return;

      const { optimizations: newOptimizations } = allOptimizations;
      if (newOptimizations.length === 0) return;

      // Use setOptimizations to conditionally update optimizationsDataState
      setOptimizations((prevOptimizations) => [
        ...prevOptimizations,
        ...newOptimizations.filter(
          (newOpt) => !prevOptimizations.some((opt) => opt.id === newOpt.id),
        ),
      ]);

      // Start polling for any new RUNNING optimizations
      newOptimizations
        .filter((opt) => opt.status === OptimizationStatusEnum.RUNNING)
        .forEach(startPolling);
    } catch (fetchError) {
      console.error('Error fetching new optimizations:', fetchError);
      setError('Failed to fetch new optimizations');
    }
  }, [optimizations, setOptimizations, startPolling]);

  // Poll for updates at a regular interval
  useEffect(() => {
    const id = setInterval(() => {
      fetchUpdates();
    }, POLLING_INTERVAL);

    return () => clearInterval(id);
  }, [fetchUpdates]);

  // Cleanup polling on component unmount
  useEffect(() => {
    isMounted.current = true;
    const cancelMap = cancelPollingMap.current;

    return () => {
      isMounted.current = false;
      cancelMap.forEach((cancel) => cancel());
      cancelMap.clear();
    };
  }, []);

  return {
    error,
    fetchMore,
    isLoading,
    optimizations,
  };
};

export default useOptimizations;
