import { type FC } from 'react';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@pxui/components/ui/tooltip';
import cn from '@pxui/lib/utils';

interface TextWithEllipsisProps {
  className?: string;
  maxLines?: number;
  text: string;
}

// TODO: Do we need this in common UI components or just in the optimizations table?
const TextWithEllipsis: FC<TextWithEllipsisProps> = ({
  text,
  maxLines = 2,
  className,
}) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>
        <span
          className={cn(
            {
              'line-clamp-1': maxLines === 1,
              'line-clamp-2': maxLines === 2,
            },
            className,
          )}
        >
          {text}
        </span>
      </TooltipTrigger>

      <TooltipContent>{text}</TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

export default TextWithEllipsis;
