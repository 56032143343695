import {
  PlotConfigData,
  PlotDynamicData,
  PlotStaticData,
  PlotType,
} from '@optimizer/types/plotStateTypes';
import {
  atom,
  atomFamily,
  selectorFamily,
  DefaultValue,
  selector,
} from 'recoil';

import capitalizeFirstLetter from '@utils/capitalizeFirstLetter';

// Atom to store static data
export const plotsStaticDataState = atom<PlotStaticData[]>({
  default: [],
  key: 'plotsStaticDataState',
});

// AtomFamily to store dynamic data
export const plotsDynamicDataState = atomFamily<PlotDynamicData | null, string>(
  {
    default: null,
    key: 'plotsDynamicDataState',
  },
);

// SelectorFamily to combine static and dynamic data into a complete PlotConfigData object
export const plotConfigState = selectorFamily<PlotConfigData, string>({
  get:
    (id) =>
    ({ get }) => {
      const plotsStaticData = get(plotsStaticDataState);
      const plot = plotsStaticData.find((p) => p.id === id);
      if (!plot) {
        throw new Error(`Plot with id ${id} not found`);
      }

      const plotDynamicData = get(plotsDynamicDataState(id)) || {
        highlighted: false,
        metricX: plot.type !== PlotType.SCATTER ? undefined : '',
        metricY: plot.type === PlotType.PARALLEL ? undefined : '',
        name: '',
        selectedMetrics: plot.type !== PlotType.PARALLEL ? undefined : [],
        selectedOptimization: undefined,
        selectedParams: plot.type !== PlotType.PARALLEL ? undefined : [],
      };

      return { ...plot, ...plotDynamicData };
    },
  key: 'plotConfigState',
  set:
    (id) =>
    ({ get, set }, newValue) => {
      // Handle the case where newValue is a DefaultValue
      if (newValue instanceof DefaultValue) {
        set(plotsDynamicDataState(id), newValue);
        return;
      }

      const plot = get(plotConfigState(id));

      // Ensure newValue is of type PlotConfigData
      const updatedName = (() => {
        if (plot.type === PlotType.LINE && newValue.metricY) {
          return `${capitalizeFirstLetter(newValue.metricY || plot.metricY || '')} Evaluation`;
        }
        if (
          plot.type === PlotType.SCATTER &&
          (newValue.metricX || newValue.metricY)
        ) {
          return `${capitalizeFirstLetter(newValue.metricX || plot.metricX || '')} - ${capitalizeFirstLetter(newValue.metricY || plot.metricY || '')}`;
        }
        if (
          plot.type === PlotType.PARALLEL &&
          newValue.selectedMetrics &&
          newValue.selectedOptimization
        ) {
          return `${newValue.selectedOptimization.name || plot.selectedOptimization?.name || ''}`;
        }
        return plot.name;
      })();

      set(plotsDynamicDataState(id), {
        ...plot,
        ...newValue,
        name: updatedName, // Update the name dynamically based on new metric values
      });
    },
});

// Create a selector to reset all dynamic data atoms
export const resetAllPlotsDynamicDataState = selector({
  get: () => null,
  key: 'resetAllPlotsDynamicDataState',
  set: ({ get, set }) => {
    const plotsStaticData = get(plotsStaticDataState);

    plotsStaticData.forEach((plot) => {
      set(plotsDynamicDataState(plot.id), null);
    });
  },
});
