import { type FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import Plot, { Figure } from 'react-plotly.js';

import {
  CustomPlotData,
  CustomPlotMouseEvent,
} from '@optimizer/types/plotTypes';
import cn from '@pxui/lib/utils';
import isEqual from 'lodash/isEqual';

import type { Layout, PlotMouseEvent } from 'plotly.js';

export interface PlotlyLinePlotProps {
  /**
   * The data to be plotted, adhering to Plotly's data structure.
   */
  data?: Partial<CustomPlotData>[];

  /**
   * The layout configuration for the Plotly plot.
   */
  layout?: Partial<Layout>;

  isActive?: boolean;

  /**
   * Callback function triggered when a point on the plot is clicked.
   * @param {CustomPlotMouseEvent} event - The Plotly mouse event.
   */
  onPointClick?: (event: CustomPlotMouseEvent) => void;
}

interface PlotlyFigure {
  data: Plotly.Data[];
  frames?: Plotly.Frame[];
  layout: Partial<Plotly.Layout>;
}

/**
 * PlotlyPlot component wraps Plotly's plotting capabilities in a React component.
 * It supports dynamic data and layout updates with efficient state management.
 *
 * @param {PlotlyLinePlotProps} props - The props for the component.
 * @returns {JSX.Element} - A Plotly plot component.
 */
const PlotlyPlot: FC<PlotlyLinePlotProps> = ({
  data,
  layout,
  onPointClick,
  isActive,
}) => {
  const plotEndRef = useRef<HTMLDivElement | null>(null);

  const [figure, setFigure] = useState<PlotlyFigure>({
    data: data ?? [],
    frames: undefined,
    layout: layout ?? {},
  });

  useEffect(() => {
    if (data && !isEqual(data, figure.data)) {
      setFigure((prevFigure) => ({
        ...prevFigure,
        data,
      }));
    }
  }, [data, figure.data]);

  useEffect(() => {
    if (layout && !isEqual(layout, figure.layout)) {
      setFigure((prevFigure) => ({
        ...prevFigure,
        layout,
      }));
    }
  }, [layout, figure.layout]);

  useEffect(() => {
    if (isActive && plotEndRef.current) {
      plotEndRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [isActive]);

  /**
   * Handles updates to the Plotly figure, ensuring that the component's state is in sync.
   *
   * @param {Figure} fig - The updated Plotly figure.
   */
  const handleUpdate = useCallback(
    (fig: Figure) => {
      if (!isEqual(fig, figure)) {
        setFigure({
          data: fig.data,
          frames: fig.frames ?? undefined,
          layout: fig.layout,
        });
      }
    },
    [figure],
  );

  /**
   * Click handler for the Plotly plot points.
   *
   * @param {PlotMouseEvent} event - The Plotly click event.
   */
  const handleClick = useCallback(
    (event: Readonly<PlotMouseEvent>) => {
      if (onPointClick) {
        onPointClick(event as CustomPlotMouseEvent);
      }
    },
    [onPointClick],
  );

  const classNames = cn(
    isActive &&
      'outline outline-state-focus outline-2 outline-offset-8 rounded-sm',
  );

  return (
    <>
      <Plot
        className={classNames}
        data={figure.data}
        layout={figure.layout}
        frames={figure.frames}
        onClick={handleClick}
        onInitialized={handleUpdate}
        onUpdate={handleUpdate}
        config={{
          scrollZoom: false,
        }}
        useResizeHandler
        style={{ height: '100%', width: '100%' }}
      />
      <div ref={plotEndRef} />
    </>
  );
};

export default memo(PlotlyPlot);
