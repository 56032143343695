import { OptimizationStatusEnum } from '@optimizer/schemas/allOptimizationsSchema';
import fetchOptimization from '@optimizer/services/fetchOptimization';
import { Optimization } from '@optimizer/types/services/allOptimizationsTypes';

import { POLLING_INTERVAL, POLLING_LIMIT } from '@constants/common';

type PollingCallback = (data: {
  error?: boolean;
  optimization: Optimization;
}) => void;

// Create a Ref-like object to track polling counts per optimization ID
const pollingCountsRef = new Map<string, number>();

/**
 * Polls an optimization's status at intervals until conditions are met.
 * Stops polling when:
 * - Status changes from "RUNNING" to another status.
 * - The polling limit is reached for optimizations without a flowRunId.
 *
 * @param {string} id - ID of the optimization.
 * @param {number} initialSteps - Initial steps completed by the optimization.
 * @param {OptimizationStatusEnum} initialStatus - Initial status of the optimization.
 * @param {PollingCallback} onStatusUpdate - Callback to update full optimization data and error dynamically.
 * @returns {() => void} - Function to cancel the polling manually.
 */
export const pollOptimizationStatus = (
  id: string,
  initialSteps: Optimization['stepsCompleted'],
  initialStatus: OptimizationStatusEnum,
  onStatusUpdate: PollingCallback,
): (() => void) => {
  // Initialize polling count for the given ID if not already set
  if (!pollingCountsRef.has(id)) {
    pollingCountsRef.set(id, 0);
  }

  const intervalId = setInterval(async () => {
    if (initialStatus !== OptimizationStatusEnum.RUNNING) {
      clearInterval(intervalId);
      return;
    }

    const updatedOptimization = await fetchOptimization(id);
    if (!updatedOptimization) return;

    // Retrieve the current polling count for this optimization
    let pollingCount = pollingCountsRef.get(id) || 0;

    // Check if the optimization has made progress since the last fetch
    if (initialSteps === updatedOptimization.stepsCompleted) {
      pollingCount += 1;
    } else {
      pollingCount = 0; // Reset count if progress is detected
    }

    // Update the polling count in the Ref
    pollingCountsRef.set(id, pollingCount);

    const possiblyBrokenJupyterOptimization =
      pollingCount >= POLLING_LIMIT &&
      !updatedOptimization.flowRunId &&
      (!updatedOptimization.pxo_version || !updatedOptimization.flux_version);

    const shouldStopPolling =
      updatedOptimization.status !== OptimizationStatusEnum.RUNNING ||
      possiblyBrokenJupyterOptimization;

    // Update status to "FAILED" if polling limit is reached without progress
    const finalStatus = possiblyBrokenJupyterOptimization
      ? OptimizationStatusEnum.FAILED
      : updatedOptimization.status;

    // Call the callback to provide the updated optimization data and set error if necessary
    onStatusUpdate({
      error: finalStatus === OptimizationStatusEnum.FAILED,
      optimization: {
        ...updatedOptimization,
        status: finalStatus,
      },
    });

    // Stop polling if conditions are met
    if (shouldStopPolling) {
      clearInterval(intervalId);
    }
  }, POLLING_INTERVAL);

  // Return a cleanup function to cancel polling
  return () => {
    clearInterval(intervalId);
  };
};

export default pollOptimizationStatus;
