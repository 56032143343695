import { Optimization } from '@optimizer/types/services/allOptimizationsTypes';
import {
  Candidate,
  Step,
} from '@optimizer/types/services/filteredOptimizationsTypes';
import { atom } from 'recoil';

interface SelectedCandidateState {
  id: Candidate['id'];
  optimizationId: Optimization['id'];
  step: Step['step'];
}

export const selectedCandidateState = atom<SelectedCandidateState | null>({
  default: null,
  key: 'selectedCandidateState',
});

export default selectedCandidateState;
