import { useEffect, type FC } from 'react';

import {
  defaultConfigDataDummy,
  OptimizerUrlState,
} from '@config/optimizerUrl';
import { AddPlotButton } from '@optimizer/components';
import { visibleMetricsState } from '@optimizer/states/metricsState';
import { plotsStaticDataState } from '@optimizer/states/plotsStates';
import { PlotType } from '@optimizer/types/plotStateTypes';
import { SectionHeader, SectionHeaderRight } from '@pxui/components/ui';
import cn from '@pxui/lib/utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import EmptyStateMessage from '@components/EmptyStateMessage/EmptyStateMessage';
import { Section } from '@components/index';

import { useUrlContext } from '@contexts/UrlQueryParamsContext';

import CopyViewButton from './CopyViewButton/CopyViewButton';
import InspectorPlot from './InspectorPlot/InspectorPlot';

// 270px - width of the sidebar
const layoutClasses =
  'surface-0 min-w-[calc(35vw - 270px)] border-x overflow-y-auto';
const colorClasses = 'border-00-subtle';
const spacingClasses = 'pb-8';

const gridLayoutClasses = 'grid gap-4 p-4 overflow-y-auto';
const gridResponsiveClasses =
  'grid-cols-1 sm:grid-cols-[repeat(auto-fit,_minmax(500px,_1fr))]';

const InspectorLayout: FC = () => {
  const { params } = useUrlContext<OptimizerUrlState>();
  const [plotsStaticData, setPlotStaticData] =
    useRecoilState(plotsStaticDataState);

  useEffect(() => {
    // ensures the uuid are synced between atom and url for plotStaticData state
    if (params.plotConfigData?.length) {
      setPlotStaticData(
        params.plotConfigData.map(({ id, type }) => {
          return {
            id: id ?? uuidv4(),
            type: type ?? PlotType.LINE,
          };
        }),
      );
    } else {
      // if no plots provided by url initialise with default config
      setPlotStaticData(defaultConfigDataDummy);
    }
  }, [params.plotConfigData, setPlotStaticData]);

  const metrics = useRecoilValue(visibleMetricsState);

  // TODO replace with EmptyState component
  if (!plotsStaticData.length) {
    return <EmptyStateMessage message="No plots available" />;
  }

  if (!metrics.length && !params.metrics?.length) {
    return <EmptyStateMessage message="No data available for plot display" />;
  }

  return (
    <Section className={cn(layoutClasses, colorClasses, spacingClasses)}>
      <SectionHeader className="pr-2">
        <SectionHeaderRight>
          <CopyViewButton />
          <AddPlotButton />
        </SectionHeaderRight>
      </SectionHeader>
      <div className={cn(gridLayoutClasses, gridResponsiveClasses)}>
        {plotsStaticData.map(({ id }) => (
          <InspectorPlot key={id} id={id} />
        ))}
      </div>
    </Section>
  );
};

export default InspectorLayout;
