import { useEffect, useState, type FC } from 'react';

import { InfoSection } from '@optimizer/components';
import OptimizationIdentifier from '@optimizer/components/common/InfoSection/OptimizationIdentifier';
import candidatesPresignedUrlsState from '@optimizer/states/candidatesPresignedUrlsState';
import { optimizationsDataState } from '@optimizer/states/optimizationsState';
import { selectedOptimizationsDynamicDataState } from '@optimizer/states/selectedOptimizationsStates';
import { GeometryData } from '@optimizer/types/geometryTypes';
import { extractFilenameFromUrl } from '@optimizer/utils/geometryUtils';
import { useRecoilValue } from 'recoil';

import ComparisonGridItemControls from './ComparisonGridItemControls';

import type { ComparisonStateInterface } from '@optimizer/states/comparisonState';

// import LoadingIndicator from '@components/vtk-viewer-wasm/components/LoadingIndicator/LoadingIndicator';
// import useMeshVisualiser from '@components/vtk-viewer-wasm/hooks/useMeshVisualiser';

interface ComparisonGridItemProps {
  item: ComparisonStateInterface;
}

const ComparisonGridItem: FC<ComparisonGridItemProps> = ({ item }) => {
  const optimization = useRecoilValue(
    optimizationsDataState(item.optimizationId || ''),
  );
  const presignedUrls = useRecoilValue(
    candidatesPresignedUrlsState(item.candidate.id),
  );
  const dynamicData = useRecoilValue(
    selectedOptimizationsDynamicDataState(item.optimizationId || ''),
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedGeometry, setSelectedGeometry] = useState<GeometryData | null>(
    null,
  );
  const [geometries, setGeometries] = useState<GeometryData[]>([]); // Store all geometries

  // const { addMesh, initialized, isLoading, canvasRef } = useMeshVisualiser({});

  // Set default geometry and collect all geometries
  useEffect(() => {
    if (
      item.candidate.geometryNames &&
      item.candidate.geometryNames.length > 0
    ) {
      const newGeometries: GeometryData[] = item.candidate.geometryNames.map(
        (geometryName) => ({
          filename: extractFilenameFromUrl(presignedUrls[geometryName] || ''),
          name: geometryName,
          url: presignedUrls[geometryName] || '',
        }),
      );
      setGeometries(newGeometries);
      setSelectedGeometry(newGeometries[0]); // Set the first geometry by default
    }
  }, [item.candidate.geometryNames, presignedUrls]);

  // TODO: fix problem with rendering multiple canvas elements on the same screen
  // useEffect(() => {
  //   if (selectedGeometry && initialized && selectedGeometry.url) {
  //     addMesh(selectedGeometry.filename, selectedGeometry.url);
  //   }
  // }, [addMesh, selectedGeometry, initialized]);

  if (!optimization) return;

  return (
    <div className="flex flex-col justify-between w-[440px] h-full p-2 relative group">
      <div className="w-full">
        <ComparisonGridItemControls
          candidateId={item.candidate.id}
          geometries={geometries}
        />
        {/* <div className="flex flex-col gap-2 justify-center items-center w-full h-[364px] bg-surface-container-low relative">
          {isLoading && (
            <div className="w-full flex justify-center">
              <LoadingIndicator />
            </div>
          )}

          {!selectedGeometry?.url && (
            <div className="flex justify-center items-center text-primary w-full">
              Geometry for this candidate is not available
            </div>
          )}
          <canvas id="canvas" ref={canvasRef} />
        </div> */}
        <div className="flex flex-col gap-y-6">
          <OptimizationIdentifier
            color={dynamicData.color}
            name={optimization?.name ?? ''}
            step={item.step}
          />
          <InfoSection
            optimizationId={optimization.id}
            selectedCandidateId={item.candidate.id}
            singleColumn
          />
        </div>
      </div>
    </div>
  );
};

export default ComparisonGridItem;
