import { ParamsType } from '@optimizer/types/plotStateTypes';
import { PlotlyDimensionType } from '@optimizer/types/plotTypes';
import { Optimization } from '@optimizer/types/services/allOptimizationsTypes';
import { Candidate } from '@optimizer/types/services/filteredOptimizationsTypes';
import { PlotData } from 'plotly.js';

/**
 * Transforms optimization data into a format suitable for Plotly parallel coordinate plots.
 *
 * @param {Optimization[]} visibleOptimizations - Array of visible optimizations with color.
 * @param {Record<string, Step[]>} optimizationData - The fetched optimization data.
 * @param {string} metricY - The selected metrics for the plot.
 * @param {string | null} selectedCandidateId - The ID of the selected candidate to highlight.
 * @returns {[Partial<PlotData & {dimensions: PlotlyDimensionType}>[], Record<string, Candidate>]} The transformed data ready for Plotly rendering and the candidates.
 */

const transformOptimizationDataToParallelPlotData = (
  selectedOptimization: Partial<Optimization & { color: string }>,
  optimizationData: Optimization[],
  metrics: string[],
  selectedParams: ParamsType[],
  selectedCandidateId: string | null,
): [
  Partial<PlotData & { dimensions: PlotlyDimensionType[] }>[],
  Record<string, Candidate>,
] => {
  const plotData: Partial<PlotData>[] = [];
  const allCandidates: Record<string, Candidate> = {};

  const dimensions: PlotlyDimensionType[] = [];
  const colorIndices: number[] = [];

  // Create a color scale mapping numerical indices to the given hex colors
  const colorScale = [
    [1, '#FF0000'], // to highlight the selected candidate, has to be bright to show against all colors
    [0, selectedOptimization.color],
  ];

  // Initialize dimensions for each metric
  let initCandidates: { [metricName: string]: PlotlyDimensionType } = {};
  metrics.forEach((metric) => {
    initCandidates = {
      ...initCandidates,
      [metric]: {
        label: metric,
        range: [],
        values: [],
      },
    };
  });

  let combinedMetricsAndParams: { [name: string]: PlotlyDimensionType } = {};

  optimizationData.forEach(
    ({ id: _optimizationId, filteredStepData: steps }) => {
      let initParams: { [paramName: string]: PlotlyDimensionType } = {};
      if (selectedParams.length > 0) {
        selectedParams.forEach((param) => {
          initParams = {
            ...initParams,
            [param.name]: {
              label: param.name,
              range: [param.lowerBound, param.upperBound],
              values: [],
            },
          };
        });
      }

      // add the metric & params values
      steps?.forEach((step, _stepIndex) => {
        if (step?.candidates && step?.candidates?.length > 0) {
          step?.candidates?.forEach((candidate) => {
            if (candidate?.id) {
              allCandidates[candidate.id] = candidate;

              Object.keys(initCandidates).forEach((metricName) => {
                const metricValuesCopy: number[] =
                  initCandidates[metricName].values;

                const metricValue =
                  candidate.metrics.find((m) => m.name === metricName)?.value ??
                  0;
                initCandidates[metricName].values = [
                  ...metricValuesCopy,
                  metricValue,
                ];
              });

              Object.keys(initParams).forEach((paramName) => {
                const paramValuesCopy: number[] = initParams[paramName].values;

                const paramValue = candidate.parameters?.[paramName] ?? 0;
                initParams[paramName].values = [...paramValuesCopy, paramValue];
              });

              // Add the numerical index for this optimization to color this line
              colorIndices.push(candidate.id === selectedCandidateId ? 1 : 0);
            }
          });
        }
      });
      combinedMetricsAndParams = {
        ...initCandidates,
        ...initParams,
      };
    },
  );

  // set range attribute
  Object.keys(combinedMetricsAndParams).forEach((key) => {
    const allValues = combinedMetricsAndParams[key].values;
    if (combinedMetricsAndParams[key].range.length === 0) {
      combinedMetricsAndParams[key].range = [
        Math.min(...allValues),
        Math.max(...allValues),
      ];
    } // TODO: refactor to use the range provided by the API for the OUTPUT_CONSTRAINTS fields
    dimensions.push(combinedMetricsAndParams[key]);
  });

  plotData.push({
    dimensions,
    line: {
      color: colorIndices,
      colorscale: colorScale,
      width: 2,
    },
    type: 'parcoords',
  } as Partial<PlotData> & { dimensions: PlotlyDimensionType[] });

  return [plotData, allCandidates];
};

export default transformOptimizationDataToParallelPlotData;
