import { FC, useCallback, useEffect, useState } from 'react';

import { OptimizerUrlState } from '@config/optimizerUrl';
import { colors } from '@design-tokens/primitive-tokens';
import {
  DEFAULT_PLOT_LAYOUT_CONFIG,
  PLOT_MARGIN_BOTTOM,
  PLOT_MARGIN_RIGHT,
} from '@optimizer/constants/plotConstants';
import useParallelPlotData from '@optimizer/hooks/useParallelPlotData';
import {
  visibleConstraintMetricsState,
  visibleObjectiveMetricsState,
  visibleParametersState,
  visibleTrackingMetricsState,
} from '@optimizer/states/metricsState';
import { plotConfigState } from '@optimizer/states/plotsStates';
import {
  selectedOptimizationsStaticDataState,
  visibleSelectedOptimizationsState,
} from '@optimizer/states/selectedOptimizationsStates';
import { ParamsType } from '@optimizer/types/plotStateTypes';
import {
  CustomPlotData,
  CustomPlotMouseEvent,
} from '@optimizer/types/plotTypes';
import formatOptimizationLabel from '@optimizer/utils/formatOptimizationLabel';
import { formatMetricName } from '@optimizer/utils/plotUtils';
import {
  CheckedIcon,
  Dropdown,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  UncheckedIcon,
} from '@pxui/components/ui';
import cn from '@pxui/lib/utils';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useUrlContext } from '@contexts/UrlQueryParamsContext';

import PlotHeader from './PlotHeader/PlotHeader';
import PlotlyPlot from './PlotlyPlot';

interface ParallelCoordinatePlotProps {
  className?: string;
  onPointClick?: (e: CustomPlotMouseEvent) => void;
  plotId: string;
}

const ParallelCoordinatePlot: FC<ParallelCoordinatePlotProps> = ({
  onPointClick,
  plotId,
  className,
}) => {
  const { params, updateParams } = useUrlContext<OptimizerUrlState>();

  const visibleOptimizations = useRecoilValue(
    visibleSelectedOptimizationsState,
  );
  const selectedOptimizations = useRecoilValue(
    selectedOptimizationsStaticDataState,
  );
  const objectiveMetrics = useRecoilValue(visibleObjectiveMetricsState);
  const constraintMetrics = useRecoilValue(visibleConstraintMetricsState);
  const trackingMetrics = useRecoilValue(visibleTrackingMetricsState);
  const { isLoading, plotData } = useParallelPlotData(plotId);
  const plotParameters = useRecoilValue(visibleParametersState);

  const [plotConfig, setPlotConfig] = useRecoilState(plotConfigState(plotId));
  const [visibleMetrics, setVisibleMetrics] = useState<string[]>([]);
  const [visibleParams, setVisibleParams] = useState<ParamsType[]>([]);

  const syncUrlParams = useCallback(
    (thisPlotId: string, updates: Record<string, any>) => {
      const updatedPlotConfigData = params.plotConfigData?.map(
        (plot) =>
          plot.id === thisPlotId
            ? {
                ...plot, // Keep original properties
                ...updates, // Apply updates
              }
            : plot, // Keep unchanged plots in the original order
      );
      if (updatedPlotConfigData) {
        updateParams({
          plotConfigData: updatedPlotConfigData,
        });
      }
    },
    [params.plotConfigData, updateParams],
  );

  // Set default metric on component mount
  useEffect(() => {
    const allVisOptimizationIds = visibleOptimizations.map((el) => el.id);
    // optimization specific default metrics
    const defaultMetrics = [
      ...(visibleOptimizations[0]?.metricSpecs.map((el) => el?.name) ?? []),
    ];
    const defaultParams = [...(visibleOptimizations[0]?.parameterSpecs ?? [])];
    if (!selectedOptimizations.length || !visibleOptimizations.length) return;

    if (
      plotConfig.selectedMetrics?.length === 0 ||
      !plotConfig.selectedOptimization
    ) {
      setVisibleMetrics(defaultMetrics);
      setVisibleParams(defaultParams);
      setPlotConfig((prevConfig) => {
        const plotToUpdate = params?.plotConfigData?.find(
          (plot) => plot.id === prevConfig.id,
        );
        if (
          plotToUpdate &&
          (!plotToUpdate?.selectedOptimizationId ||
            !plotToUpdate.selectedMetrics?.length ||
            !plotToUpdate.selectedParams?.length)
        ) {
          syncUrlParams(plotConfig.id, {
            selectedMetrics: defaultMetrics,
            selectedOptimizationId: visibleOptimizations[0].id,
            selectedParams: defaultParams.map((el) => el.name),
          });
          return {
            ...prevConfig,
            name: `${visibleOptimizations[0]?.name} Parallel plot`,
            selectedMetrics: defaultMetrics,
            selectedOptimization: visibleOptimizations[0],
            selectedParams: defaultParams,
          };
        }
        const optimizationFromUrl = visibleOptimizations.find(
          (el) => el.id === plotToUpdate?.selectedOptimizationId,
        );
        const paramsFromUrl = optimizationFromUrl?.parameterSpecs;
        return {
          ...prevConfig,
          name: `${optimizationFromUrl?.name} Parallel plot`,
          selectedMetrics: plotToUpdate?.selectedMetrics,
          selectedOptimization: optimizationFromUrl,
          selectedParams: paramsFromUrl?.filter((el) =>
            plotToUpdate?.selectedParams?.includes(el.name),
          ),
        };
      });
    } else if (
      !allVisOptimizationIds.includes(plotConfig.selectedOptimization?.id || '')
    ) {
      // when list of visible optimization changes and the selected viz is no longer there
      setPlotConfig((prevConfig) => ({
        ...prevConfig,
        name: `${visibleOptimizations[0]?.name} Parallel plot`,
        selectedMetrics: defaultMetrics,
        selectedOptimization: visibleOptimizations[0],
        selectedParams: defaultParams,
      }));
      syncUrlParams(plotConfig.id, {
        selectedMetrics: defaultMetrics,
        selectedOptimizationId: visibleOptimizations[0].id,
        selectedParams: defaultParams.map((el) => el.name),
      });
    }
  }, [
    objectiveMetrics,
    constraintMetrics,
    plotParameters,
    plotConfig,
    setPlotConfig,
    visibleOptimizations,
    params?.plotConfigData,
    syncUrlParams,
    selectedOptimizations,
  ]);

  const handleSelectedMetricsChange = (newMetric: string) => {
    setPlotConfig((prevConfig) => {
      const newSelectedMetrics = prevConfig?.selectedMetrics?.includes(
        newMetric,
      )
        ? prevConfig.selectedMetrics.filter((el) => el !== newMetric) // if already selected filter it out
        : [...(prevConfig?.selectedMetrics || []), newMetric];
      syncUrlParams(prevConfig.id, { selectedMetrics: newSelectedMetrics });
      return {
        ...prevConfig,
        selectedMetrics: newSelectedMetrics,
      };
    });
  };

  const handleSelectedParamsChange = (newParam: string) => {
    const chosenParam = plotParameters.filter((el) => el.name === newParam);
    setPlotConfig((prevConfig) => {
      const newParams = prevConfig?.selectedParams
        ?.map((el) => el.name)
        .includes(newParam)
        ? prevConfig.selectedParams.filter((el) => el.name !== newParam) // if already selected filter it out
        : [...(prevConfig?.selectedParams || []), ...chosenParam];

      syncUrlParams(prevConfig.id, {
        selectedParams: newParams.map((el) => el.name),
      });
      return {
        ...prevConfig,
        selectedParams: newParams,
      };
    });
  };

  const handleOptimizationChange = (newOptimizationId: string) => {
    const newOptimization = visibleOptimizations.filter(
      (el) => el.id === newOptimizationId,
    )[0];
    const newMetrics = newOptimization.metricSpecs.map((el) => el.name);
    const newParams = newOptimization?.parameterSpecs ?? [];

    setVisibleMetrics([...newMetrics]);
    setVisibleParams([...newParams]);
    setPlotConfig((prevConfig) => {
      syncUrlParams(prevConfig.id, {
        selectedMetrics: newMetrics,
        selectedOptimizationId: newOptimization.id,
        selectedParams: newParams.map((el) => el.name),
      });

      return {
        ...prevConfig,
        selectedMetrics: newMetrics,
        selectedOptimization: newOptimization,
        selectedParams: newParams,
      };
    });
  };

  return (
    <div
      className={cn('flex flex-col relative', className, {
        'animate-pulse': isLoading,
      })}
    >
      {isLoading && (
        <div className="text-on-surface-subtle absolute left-1/2 top-1/2 z-10">
          Loading ...
        </div>
      )}
      <PlotHeader title="">
        <div className="flex justify-between w-full">
          <div className="flex items-center">
            <Dropdown
              buttonText="Select metrics and parameters"
              variant="ghost"
              contentClassName="max-h-60 overflow-auto"
            >
              {objectiveMetrics.length > 0 && (
                <DropdownMenuGroup key="menu-group-metrics">
                  <DropdownMenuLabel className="label-1 text-primary">
                    Objective Functions
                  </DropdownMenuLabel>
                  {objectiveMetrics.map((metric) => (
                    <DropdownMenuItem
                      key={`${metric.name}_objective_function`}
                      onClick={() => handleSelectedMetricsChange(metric.name)}
                      className="label-1 text-secondary cursor-pointer select-none hover:bg-hover focus:bg-focus transition-colors flex flex-row gap-2"
                      onSelect={(e) => e.preventDefault()}
                      disabled={!visibleMetrics.includes(metric.name)}
                    >
                      <>
                        {plotConfig.selectedMetrics?.includes(metric.name) ? (
                          <CheckedIcon />
                        ) : (
                          <UncheckedIcon />
                        )}
                        {formatMetricName(metric.name, metric.toMinimize, true)}
                      </>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuGroup>
              )}
              {constraintMetrics.length > 0 && objectiveMetrics.length > 0 && (
                <DropdownMenuSeparator />
              )}
              {constraintMetrics.length > 0 && (
                <DropdownMenuGroup key="menu-group-constraints">
                  <DropdownMenuLabel className="label-1 text-primary">
                    Constraints
                  </DropdownMenuLabel>
                  {constraintMetrics.map((metric) => (
                    <DropdownMenuItem
                      key={`${metric.name}_constraint`}
                      onClick={() => handleSelectedMetricsChange(metric.name)}
                      className="label-1 text-secondary cursor-pointer select-none hover:bg-hover focus:bg-focus transition-colors flex flex-row gap-2"
                      onSelect={(e) => e.preventDefault()}
                      disabled={!visibleMetrics.includes(metric.name)}
                    >
                      <>
                        {plotConfig.selectedMetrics?.includes(metric.name) ? (
                          <CheckedIcon />
                        ) : (
                          <UncheckedIcon />
                        )}
                        {metric.name}
                      </>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuGroup>
              )}
              {trackingMetrics.length > 0 && constraintMetrics.length > 0 && (
                <DropdownMenuSeparator />
              )}
              {trackingMetrics.length > 0 && (
                <DropdownMenuGroup key="menu-group-tracking-metrics">
                  <DropdownMenuLabel className="label-1 text-primary">
                    Tracking
                  </DropdownMenuLabel>
                  {trackingMetrics.map((metric) => (
                    <DropdownMenuItem
                      key={`${metric.name}_tracking`}
                      onClick={() => handleSelectedMetricsChange(metric.name)}
                      className="label-1 text-secondary cursor-pointer select-none hover:bg-hover focus:bg-focus transition-colors flex flex-row gap-2"
                      onSelect={(e) => e.preventDefault()}
                      disabled={!visibleMetrics.includes(metric.name)}
                    >
                      <>
                        {plotConfig.selectedMetrics?.includes(metric.name) ? (
                          <CheckedIcon />
                        ) : (
                          <UncheckedIcon />
                        )}
                        {metric.name}
                      </>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuGroup>
              )}
              {plotParameters.length > 0 && constraintMetrics.length > 0 && (
                <DropdownMenuSeparator />
              )}
              {plotParameters.length > 0 && (
                <DropdownMenuGroup key="menu-group-parameters">
                  <DropdownMenuLabel className="label-1 text-primary">
                    Parameters
                  </DropdownMenuLabel>
                  {plotParameters.map((param) => (
                    <DropdownMenuItem
                      key={`${param.name}_parameter`}
                      onClick={() => handleSelectedParamsChange(param?.name)}
                      className="label-1 text-secondary cursor-pointer select-none hover:bg-hover focus:bg-focus transition-colors flex flex-row gap-2"
                      onSelect={(e) => e.preventDefault()}
                      disabled={
                        ![...visibleParams.map((el) => el.name)].includes(
                          param?.name,
                        )
                      }
                    >
                      <>
                        {[
                          ...(plotConfig?.selectedParams?.map(
                            (el) => el?.name,
                          ) ?? []),
                        ]?.includes(param?.name) ? (
                          <CheckedIcon />
                        ) : (
                          <UncheckedIcon />
                        )}
                        {param?.name}
                      </>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuGroup>
              )}
            </Dropdown>
            <Dropdown
              buttonText={formatOptimizationLabel(
                plotConfig.selectedOptimization?.createdAt,
                plotConfig.selectedOptimization?.name || 'Select optimization',
              )}
              variant="ghost"
            >
              {visibleOptimizations.map((optimization) => (
                <DropdownMenuItem
                  key={optimization.id}
                  onClick={() => handleOptimizationChange(optimization.id)}
                  className="label-1 text-secondary cursor-pointer select-none hover:bg-hover focus:bg-focus transition-colors"
                >
                  {formatOptimizationLabel(
                    optimization?.createdAt,
                    optimization?.name || '',
                  )}
                </DropdownMenuItem>
              ))}
            </Dropdown>
          </div>
        </div>
      </PlotHeader>
      {plotData &&
      plotConfig.selectedMetrics?.length &&
      plotConfig.selectedOptimization ? (
        <PlotlyPlot
          onPointClick={onPointClick}
          data={plotData as Partial<CustomPlotData>[]} // have to cast type, since i do not return customdata
          layout={{
            font: {
              // using the same color as other graphs results in a weird background for on plot labels that can not be removed
              color: colors.neutral.white, // so using white instead
              family: 'Geist Sans',
              size: 11,
            },
            margin: {
              b: PLOT_MARGIN_BOTTOM,
              r: PLOT_MARGIN_RIGHT,
              t: PLOT_MARGIN_RIGHT,
            },
            ...DEFAULT_PLOT_LAYOUT_CONFIG,
          }}
          isActive={params.plotConfigData?.some(
            (plot) => plot.highlighted && plot.id === plotId,
          )}
        />
      ) : (
        <div />
      )}
    </div>
  );
};

export default ParallelCoordinatePlot;
