import { type ButtonHTMLAttributes, type FC } from 'react';
import { Link } from 'react-router-dom';

import cn from '@pxui/lib/utils';
import { type VariantProps, cva } from 'class-variance-authority';

import launcherTilePlaceholder from '@assets/images/Placeholder.png';

const layoutClasses =
  'relative flex flex-col items-start rounded-lg border border-transparent max-w-[284px] min-w-[284px]';
const interactionClasses =
  'hover:state-hover active:state-active hover:border-subtle focus-visible:outline-none focus-visible:state-focus focus-visible:state-focus-inset hover:rounded-[20px]';
const beforeBaseClasses =
  'before:absolute before:hidden before:content-[attr(data-description)]';

const launcherTileVariants = cva(
  cn('p-4', layoutClasses, interactionClasses, beforeBaseClasses),
  {
    defaultVariants: {
      size: 'default',
    },
    variants: {
      size: {
        default: 'gap-2',
        large: 'gap-3',
        medium: 'gap-3',
      },
    },
  },
);
const SkeletonLauncherTile: FC = () => {
  return (
    <>
      <div
        className="rounded-md bg-gray-500 h-20 w-20"
        data-testid="launcher-skeleton"
      />
      <div className="flex flex-col gap-1 items-start text-left">
        <div className="rounded-md bg-gray-500 w-14 h-5" />
        <div className="rounded-md bg-gray-500 w-36 h-10" />
      </div>
    </>
  );
};

const imageVariants = cva('object-contain', {
  defaultVariants: {
    size: 'default',
  },
  variants: {
    size: {
      default: 'w-12 h-12',
      large: 'w-24 h-24',
      medium: 'w-20 h-20',
    },
  },
});

interface LauncherTileProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof launcherTileVariants> {
  className?: string;
  description?: string;
  embedded?: boolean;
  externalApp?: boolean;
  hidden?: boolean;
  id: string;
  imageSrc?: string;
  isLoading?: boolean;
  path: string;
  size?: 'default' | 'medium' | 'large';
  title: string;
}

const TileContent: FC<{
  description?: string;
  imageSrc?: string;
  isLoading?: boolean;
  size?: 'default' | 'medium' | 'large';
  title: string;
}> = ({ title, description, imageSrc, size, isLoading }) => {
  const titleStyles = 'mt-1 title-3 text-primary line-clamp-1';
  const descriptionStyles = 'paragraph-1 text-secondary line-clamp-2';
  const displayImage = imageSrc?.length ? imageSrc : launcherTilePlaceholder;

  return (
    <div
      className={cn(
        'flex flex-row gap-4 items-center justify-center',
        isLoading ? 'animate-pulse' : '',
      )}
    >
      {isLoading ? (
        <SkeletonLauncherTile />
      ) : (
        <>
          <img
            src={displayImage}
            alt={`${title}-icon`}
            className={cn(imageVariants({ size }))}
            data-testid="launcher-tile-app-icon"
          />
          <div
            className="flex flex-col gap-1 items-start text-left"
            data-testid="launcher-tile-body"
          >
            {/* Title with truncation */}
            <div className={titleStyles} title={title}>
              {title}
            </div>
            {/* Description with truncation to 2 lines */}
            {description && (
              <div className={descriptionStyles} title={description}>
                {description}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const LauncherTile: FC<LauncherTileProps> = ({
  className,
  description,
  imageSrc,
  title,
  size = 'default',
  externalApp = false,
  embedded = false,
  path,
  id,
  isLoading,
  ...props
}) => {
  const content = (
    <TileContent
      title={title}
      description={description}
      imageSrc={imageSrc}
      size={size}
      isLoading={isLoading}
    />
  );

  let wrappedContent;
  // iFramed custom apps
  if (embedded) {
    wrappedContent = (
      <Link
        to={path}
        className="flex flex-col cursor-pointer"
        aria-label={title}
        id={id}
      >
        {content}
      </Link>
    );
  } else if (externalApp) {
    // external apps
    wrappedContent = (
      <a
        href={path}
        className="flex flex-col"
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`${title} (external link)`}
        id={id}
      >
        {content}
      </a>
    );
  } else {
    // internal apps
    wrappedContent = (
      <Link to={path} className="flex flex-col" aria-label={title} id={id}>
        {content}
      </Link>
    );
  }

  return (
    <button
      type="button"
      data-testid="launcher-tile"
      className={cn(
        launcherTileVariants({
          className,
          size,
        }),
      )}
      data-description={description}
      {...props}
    >
      {wrappedContent}
    </button>
  );
};

LauncherTile.displayName = 'LauncherTile';

export { LauncherTile, type LauncherTileProps };
