import { type ReactNode, useEffect, useState, type FC } from 'react';

import { compareViewState } from '@optimizer/states/comparisonState';
import cn from '@pxui/lib/utils';
import {
  type ResizableProps,
  Resizable,
  type ResizeCallback,
} from 're-resizable';
import { useRecoilValue } from 'recoil';

interface GeometryInspectorContainerProps extends ResizableProps {
  children?: ReactNode;
  maxWidthPercentage?: number;
  minWidth?: number;
  open?: boolean;
}

const MIN_CARD_WIDTH = 580;
const MAX_WIDTH_PERCENTAGE = 65;

const transitionClasses =
  'transition-[width,min-width,opacity,padding-right,margin-left] duration-300';

const stateOpenClasses = 'px-4 opacity-100';
const stateClosedClasses = 'opacity-0';

const layoutClasses = 'overflow-auto !sticky top-0 right-0';
const spacingClasses = 'py-4';
const colorClasses = 'surface-0';

const handleComponentClasses =
  'fixed h-12 w-0.5 bg-surface-container-high top-1/2 ml-[9px] rounded-full';
const handleWrapperClasses =
  '[&_div.inspector-sidebar-handle>div]:!w-4 [&_div.inspector-sidebar-handle>div]:!h-12 [&_div.inspector-sidebar-handle>div]:!top-1/2';

const RESIZE_EVENT_TIMEOUT = 300;

const triggerResize = () => {
  const event = new Event('resize');
  window.dispatchEvent(event);
};

const GeometryInspectorContainer: FC<GeometryInspectorContainerProps> = ({
  className,
  open,
  minWidth = MIN_CARD_WIDTH,
  maxWidthPercentage = MAX_WIDTH_PERCENTAGE,
  children,
  ...props
}) => {
  const [width, setWidth] = useState(MIN_CARD_WIDTH);
  const [isTransitionOn, setIsTransitionOn] = useState(true);
  const isOnCompareView = useRecoilValue(compareViewState);

  useEffect(() => {
    if (isOnCompareView) {
      const viewWidthRatio = 0.5;

      setWidth(window.innerWidth * viewWidthRatio);
    } else {
      setWidth(open ? MIN_CARD_WIDTH : 0);
    }

    const timeoutId = setTimeout(() => {
      triggerResize();
    }, RESIZE_EVENT_TIMEOUT);

    return () => clearTimeout(timeoutId);
  }, [open, isOnCompareView]);

  const handleResizeStop: ResizeCallback = (_e, _direction, _ref, d) => {
    setWidth((prev) => {
      const newWidth = prev + d.width;
      const maxWidtx = window.innerWidth * (maxWidthPercentage / 100);
      return Math.min(newWidth, maxWidtx);
    });
    setIsTransitionOn(true);
    triggerResize();
  };

  const handleResizeStart = () => {
    setIsTransitionOn(false);
  };

  return (
    <Resizable
      className={cn(
        layoutClasses,
        spacingClasses,
        colorClasses,
        handleWrapperClasses,
        {
          className,
          [stateClosedClasses]: !open,
          [stateOpenClasses]: open,
          [transitionClasses]: isTransitionOn,
        },
      )}
      minWidth={open ? minWidth : 0}
      maxWidth={`${maxWidthPercentage}%`}
      size={{
        height: '100%',
        width: `${width}px`,
      }}
      onResizeStop={handleResizeStop}
      onResizeStart={handleResizeStart}
      enable={{
        bottom: false,
        bottomLeft: false,
        bottomRight: false,
        left: true,
        right: false,
        top: false,
        topLeft: false,
        topRight: false,
      }}
      handleComponent={{
        left: <div className={handleComponentClasses} />,
      }}
      handleWrapperClass="inspector-sidebar-handle"
      {...props}
    >
      <div className="flex flex-col gap-2">{children}</div>
    </Resizable>
  );
};

GeometryInspectorContainer.displayName = 'GeometryInspectorContainer';

export { GeometryInspectorContainer, type GeometryInspectorContainerProps };
