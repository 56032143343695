import type { FC } from 'react';

import { showDiffState } from '@optimizer/states/showDiffState';
import { useRecoilValue } from 'recoil';

interface CompareValueProps {
  baseValue: number;
  currentValue: number;
  index: number;
  isBaselineCandidate: boolean;
  parametersToCompare?: { name: string; value: number }[];
}

const comparisonValueClasses = 'code-1 text-inverse-primary';

const CompareValue: FC<CompareValueProps> = ({
  baseValue,
  currentValue,
  index,
  isBaselineCandidate,
  parametersToCompare,
}) => {
  const showDiff = useRecoilValue(showDiffState);

  if (isBaselineCandidate || parametersToCompare?.[index] || !showDiff)
    return null;

  const diff = currentValue - baseValue;
  const prefix = diff > 0 ? '+' : '';

  return (
    <div
      className={comparisonValueClasses}
    >{`${prefix}${diff.toExponential(2)}`}</div>
  );
};

export default CompareValue;
