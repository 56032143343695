import { CandidateSelectionMode } from '@optimizer/schemas/filteredOptimizationsSchema';
import { UncertaintySelectionModeValue } from '@optimizer/states/uncertaintySelectionMode';
import { PlotStaticData, PlotType } from '@optimizer/types/plotStateTypes';
import { v4 as uuidv4 } from 'uuid';

export interface StepsState {
  candidateSelection?: Record<'line' | 'marker', CandidateSelectionMode>;
  // max?: number;
  // min?: number;
  selectedRange?: [number, number];
  spacing?: number;
  uncertaintyMode?: UncertaintySelectionModeValue;
}

export interface PlotDynamicDataForUrl {
  highlighted: boolean;
  metricX?: string;
  metricY?: string;
  name: string;
  selectedMetrics?: string[];
  selectedOptimizationId?: string;
  selectedParams?: string[];
}

export interface PlotConfigDataForUrl
  extends PlotStaticData,
    PlotDynamicDataForUrl {}

export interface OptimizerUrlState {
  paretoFront?: {
    line?: 'Pareto';
    marker?: 'Pareto' | 'All candidates';
  };
  metrics?: string[];
  optimizationIds?: Array<string>;
  plotConfigData?: Partial<PlotConfigDataForUrl>[];
  satisfiedConstraints?: string[];
  steps?: StepsState;
}

export const defaultConfigDataDummy = [
  {
    id: uuidv4(),
    type: PlotType.LINE,
  },
  {
    id: uuidv4(),
    type: PlotType.SCATTER,
  },
];

export const optimizerDefaultUrl: OptimizerUrlState = {
  metrics: undefined,
  optimizationIds: undefined,
  paretoFront: {
    line: 'Pareto',
    marker: 'All candidates',
  },
  plotConfigData: defaultConfigDataDummy,
  satisfiedConstraints: undefined,
  steps: {
    candidateSelection: {
      line: CandidateSelectionMode.MAX,
      marker: CandidateSelectionMode.MAX,
    },
    // eslint-disable-next-line no-magic-numbers
    selectedRange: [1, 20],
    spacing: 0,
    uncertaintyMode: '0',
  },
};
