import { FC } from 'react';

import { Checkbox, ListHeader, ListSection } from '@pxui/components/ui';

type DataSeriesSelectorProps = {
  title: string;
  config: {
    args: Record<string, boolean>;
    label: string;
    id: string;
  }[];
  handleChange: (dataKey: string, argKey: string) => void;
};

export const DataSeriesSelector: FC<DataSeriesSelectorProps> = ({
  title,
  config,
  handleChange,
}) => {
  if (!config.length) return null;

  const allKeys = [
    ...new Set(
      config.reduce<string[]>((acc, item) => {
        const keys = Object.keys(item.args);

        return acc.concat(keys);
      }, []),
    ),
  ];

  return (
    <ListSection>
      <ListHeader title={title} className="text-white" />
      <div className="flex gap-2 p-2.5 pt-0 label-1 text-on-surface-subtle">
        {config.map((item, index) => (
          <div className="flex flex-col gap-3" key={index}>
            <div>{item.label}</div>
            <div className="flex flex-col gap-2">
              {Object.keys(item.args).map((key, index) => (
                <Checkbox
                  key={index}
                  checked={item.args[key as keyof typeof item.args]}
                  onCheckedChange={() => handleChange(item.id, key)}
                />
              ))}
            </div>
          </div>
        ))}
        <div className="flex flex-col gap-3">
          <div>&nbsp;</div>
          <div className="flex flex-col gap-2">
            {allKeys.map((key, index) => (
              <div
                key={index}
                className="h-5 flex items-center label-1 text-on-surface pl-1"
              >
                {key.charAt(0) + key.slice(1).toLowerCase()}
              </div>
            ))}
          </div>
        </div>
      </div>
    </ListSection>
  );
};
