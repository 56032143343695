import { type HTMLAttributes, FC } from 'react';

import { Button } from '@pxui/components/ui/button';
import ButtonGroup from '@pxui/components/ui/buttonGroup';
import { cn } from '@pxui/lib/utils';

interface TablePaginationProps extends HTMLAttributes<HTMLDivElement> {
  onNext?: () => void;
  onPrev?: () => void;
  page: number;
  pagesNumber: number;
}

const containerLayoutClasses = 'flex items-center justify-between';
const containerSpacingClasses = 'px-4';

const textLayoutClasses = 'paragraph-1';
const textColorClasses = 'text-secondary';

export const TablePagination: FC<TablePaginationProps> = ({
  className,
  page,
  pagesNumber,
  onNext,
  onPrev,
  ...props
}) => {
  return (
    <div
      className={cn(containerLayoutClasses, containerSpacingClasses, className)}
      {...props}
    >
      <span className={cn(textLayoutClasses, textColorClasses)}>
        Page {page} of {pagesNumber}
      </span>

      <ButtonGroup>
        <Button
          variant="secondary"
          layout="iconText"
          iconName="arrow_left"
          onClick={onPrev}
          disabled={page === 1}
        >
          Prev
        </Button>
        <Button
          variant="secondary"
          layout="textIcon"
          iconName="arrow_right"
          onClick={onNext}
          disabled={page === pagesNumber}
        >
          Next
        </Button>
      </ButtonGroup>
    </div>
  );
};

TablePagination.displayName = 'TablePagination';

export default TablePagination;
