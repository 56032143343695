import getConfiguration from '@components/vtk-viewer-wasm/utils/wasmConfigure-react';
import {
  GeometryViewerModule,
  ViewportLayoutOption,
} from './GeometryViewerFactory';

interface GeometryViewerWasmModuleConfig {
  viewApi?: 'webgl' | 'webgpu';
  webgpuDevice?: GPUDevice;
}

class GeometryViewerWasmModule {
  private wasmModulePromise?: Promise<GeometryViewerModule>;
  private viewApi: string = 'webgl';
  private webgpuDevice?: GPUDevice;

  constructor(config?: GeometryViewerWasmModuleConfig) {
    if (config) {
      if (config.viewApi) this.viewApi = config.viewApi;
      if (config.webgpuDevice) this.webgpuDevice = config.webgpuDevice;
    }
  }

  // Memory allocation helper methods
  public async malloc(size: number): Promise<number> {
    const wasmModule = await this.getWasmModule();
    return wasmModule._malloc(size);
  }

  public async free(ptr: number): Promise<void> {
    const wasmModule = await this.getWasmModule();
    wasmModule._free(ptr);
  }

  public async setHeapData(data: Uint8Array, ptr: number): Promise<void> {
    const wasmModule = await this.getWasmModule();
    wasmModule.HEAPU8.set(data, ptr);
  }

  // Lazy-loaded getter for the wasmModulePromise
  private async getWasmModule(): Promise<GeometryViewerModule> {
    if (!this.wasmModulePromise) {
      // Dynamically import the module and store the promise
      //TODO: define types for GeometryViewer.js
      const jsModule = await import(
        // @ts-ignore: ignore TypeScript warning for dynamic import paths
        './GeometryViewer.js'
      );
      const { default: createGeometryViewerModule } = jsModule;
      const config = await getConfiguration(this.viewApi, null);
      const wasmModulePromise = createGeometryViewerModule(config);
      this.wasmModulePromise =
        wasmModulePromise as Promise<GeometryViewerModule>;
    }

    return this.wasmModulePromise;
  }

  public async createNewGeometeryViewer(layout?: ViewportLayoutOption) {
    const wasmModule = await this.getWasmModule();
    return layout
      ? new wasmModule.GeometryViewer(layout)
      : new wasmModule.GeometryViewer();
  }
}

export const geometryViewerWasmModule = new GeometryViewerWasmModule({
  viewApi: 'webgl',
});
