import { atom } from 'recoil';

export const paginationState = atom<{
  // The token used to fetch the next page of results
  hasNextPage: boolean;
  pageToken: string | null;
}>({
  default: {
    hasNextPage: true,
    pageToken: null,
  },
  key: 'paginationState',
});

export default paginationState;
