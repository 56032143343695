import type { FC } from 'react';

import { cn } from '@pxui/lib/utils';

interface ListItemCollectionToggleProps {
  color: string;
  toggled?: boolean;
}

const layoutClasses = 'w-2 h-2 rounded-full shrink-0';

const dotStateUntoggled = 'border border-00-subtle';

const ListItemCollectionToggle: FC<ListItemCollectionToggleProps> = ({
  color,
  toggled = true,
}) => (
  <div className={layoutClasses}>
    <div
      className={cn('rounded-full w-full h-full', {
        [dotStateUntoggled]: !toggled,
      })}
      style={{ backgroundColor: toggled ? color : 'transparent' }}
    />
  </div>
);

export { ListItemCollectionToggle, type ListItemCollectionToggleProps };
