import { type FC } from 'react';

import ExternalAppButtons from '@optimizer/components/InspectorPage/InspectorView/InspectorSidePanel/ActionButtons/ExternalAppButtons';
import useGetOptimizationInfo from '@optimizer/hooks/useGetOptimizationInfo';
import baselineCandidateIdState from '@optimizer/states/baselineCandidateIdState';
import cn from '@pxui/lib/utils';
import { useRecoilValue } from 'recoil';

import useRegisteredApps from '@hooks/useRegisteredApps';

import GridItemMetadata from './GridItemMetadata';
import InfoSectionItem from './InfoSectionItem';

interface DetailsSectionProps {
  className?: string;
  optimizationId: string;
  selectedCandidateId: string;
  singleColumn?: boolean;
}

const InfoSection: FC<DetailsSectionProps> = ({
  className,
  optimizationId,
  selectedCandidateId,
  singleColumn,
}) => {
  const baselineCandidateId = useRecoilValue(baselineCandidateIdState);

  const { registeredApps, isLoading: isAppsInfoLoading } = useRegisteredApps();

  const { constraints, error, isLoading, objectiveFunctions, parameters } =
    useGetOptimizationInfo({
      optimizationId,
      selectedCandidateId,
    });

  const {
    constraints: comparisonConstraints,
    error: comparisonError,
    isLoading: comparisonIsLoading,
    objectiveFunctions: comparisonObjectiveFunctions,
    parameters: comparisonParameters,
  } = useGetOptimizationInfo({
    optimizationId,
    selectedCandidateId: baselineCandidateId || '',
  });

  const isBaselineCandidate = selectedCandidateId === baselineCandidateId;

  if (isLoading || comparisonIsLoading) {
    return (
      <div className="flex items-center justify-center text-primary w-full mt-8">
        Candidate is loading...
      </div>
    );
  }

  if (error || comparisonError) {
    return (
      <div className="flex items-center justify-center text-primary w-full mt-8">
        {error}
      </div>
    );
  }

  return (
    <div className={cn('grid grid-cols-2 gap-6')}>
      <GridItemMetadata
        candidateId={selectedCandidateId}
        optimizationId={optimizationId}
      />
      <div className="flex flex-col h-full justify-around items-end">
        <ExternalAppButtons
          isAppsLoading={isAppsInfoLoading}
          registeredApps={registeredApps}
          optimizationId={optimizationId}
          selectedCandidateId={selectedCandidateId}
        />
      </div>
      {objectiveFunctions?.length > 0 && (
        <InfoSectionItem
          className={className}
          isBaselineCandidate={isBaselineCandidate}
          parameters={objectiveFunctions}
          parametersToCompare={comparisonObjectiveFunctions}
          title="Objective function"
        />
      )}
      {constraints?.length > 0 && (
        <InfoSectionItem
          className={className}
          isBaselineCandidate={isBaselineCandidate}
          parameters={constraints}
          parametersToCompare={comparisonConstraints}
          title="Constraints"
        />
      )}
      {parameters?.length > 0 && (
        <InfoSectionItem
          className={className}
          isBaselineCandidate={isBaselineCandidate}
          parameters={parameters}
          parametersToCompare={comparisonParameters}
          title="Parameters"
        />
      )}
    </div>
  );
};

export default InfoSection;
