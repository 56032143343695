import './index.css';
import ReactDOM from 'react-dom/client';

import * as Sentry from '@sentry/react';
import { RecoilRoot } from 'recoil';

import App from './App';
import '@fontsource/geist-sans/300.css';
import '@fontsource/geist-sans/400.css';
import '@fontsource/geist-sans/500.css';
import '@fontsource/geist-sans/600.css';
import '@fontsource/geist-mono/400.css';
import 'material-icons/iconfont/material-icons.css';

// TODO: handle React.StrictMode

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

Sentry.init({
  dsn: 'https://4c48abcfbfe1509e7f1b727d45f94f71@o4507112991948800.ingest.de.sentry.io/4507113070264400',
  enabled: process.env.NODE_ENV !== 'development',

  // Performance Monitoring
  integrations: [Sentry.browserTracingIntegration()],

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/api\..*\.cloud\.physicsx\.ai\//,
  ],
  //  Capture 100% of the transactions
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(rootElement);

root.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>,
);
