import type { FC } from 'react';

import baselineCandidateIdState from '@optimizer/states/baselineCandidateIdState';
import { comparisonState } from '@optimizer/states/comparisonState';
import { showDiffSelector } from '@optimizer/states/showDiffState';
import { exportAllGeometries } from '@optimizer/utils/geometryUtils';
import { Button } from '@pxui/components/ui';
import cn from '@pxui/lib/utils';
import { useRecoilState, useSetRecoilState } from 'recoil';

import type { GeometryData } from '@optimizer/types/geometryTypes';

interface ComparisonGridItemControlsProps {
  candidateId: string;
  geometries: GeometryData[];
}

const buttonClasses = 'w-8 h-8 p-0';
const containerClasses =
  'absolute transition-opacity duration-400 flex gap-1 right-0 top-2 z-10 p-1 rounded ';
const hoverContainerClasses = 'opacity-0 group-hover:opacity-100';
const baselinePinContainerClasses = 'opacity-100';

const ComparisonGridItemControls: FC<ComparisonGridItemControlsProps> = ({
  geometries,
  candidateId,
}) => {
  const [comparison, setComparison] = useRecoilState(comparisonState);
  const [baselineCandidateId, setBaselineCandidateId] = useRecoilState<
    string | null
  >(baselineCandidateIdState);
  const setShowDiff = useSetRecoilState(showDiffSelector);
  const isBaselineCandidate = candidateId === baselineCandidateId;

  // Toggle off the showDiff state and clear the baseline candidate id
  const clearComparisonState = () => {
    setShowDiff(false);
    setBaselineCandidateId(null);
  };

  // Remove the item from the comparison state
  const onItemClose = () => {
    setComparison((prev) =>
      prev.filter((el) => el.candidate.id !== candidateId),
    );

    if (isBaselineCandidate) {
      clearComparisonState();
    }
  };

  // Handle exporting all geometries for this candidate
  const handleExportAllGeometries = () => {
    if (geometries.length > 0) {
      exportAllGeometries(geometries, candidateId);
    }
  };

  // Handle changing the baseline for this candidate
  const handleChangeBaseline = () => {
    if (isBaselineCandidate) {
      clearComparisonState();
    } else {
      setBaselineCandidateId(candidateId);
      setShowDiff(true);
    }
  };

  const pinButton = (
    <Button
      className={buttonClasses}
      disabled={comparison.length < 2}
      iconName="push_pin"
      layout="iconOnly"
      onClick={handleChangeBaseline}
      variant={isBaselineCandidate ? 'primary' : 'secondary'}
    />
  );

  return (
    <>
      <div className={cn(containerClasses, hoverContainerClasses)}>
        <Button
          className={buttonClasses}
          // TODO: Return if we will have the option to delete candidates from another interface.
          // disabled={comparison.length <= 2}
          iconName="close"
          layout="iconOnly"
          onClick={onItemClose}
          variant="secondary"
        />
        <Button
          className={buttonClasses}
          iconName="download"
          layout="iconOnly"
          onClick={handleExportAllGeometries}
          variant="secondary"
        />
        {pinButton}
      </div>
      <div
        className={cn(containerClasses, baselinePinContainerClasses, {
          'opacity-0 z-0': !isBaselineCandidate,
        })}
      >
        {pinButton}
      </div>
    </>
  );
};

export default ComparisonGridItemControls;
