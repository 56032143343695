import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';

import Auth0ProviderWithHistory from '@auth/Auth0ProviderWithHistory';

import { AppbarContextProvider } from '@contexts/AppbarContext';
import { Auth0TokenProvider } from '@contexts/Auth0TokenContext';
import { UrlProvider } from '@contexts/UrlQueryParamsContext';

import AppWrapper from './AppWrapper';
import { AuthenticationGuard } from './AuthenticationGuard';

const RedirectToOrg = () => {
  const location = useLocation();

  // Use useMemo to stabilize the params object
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const org = params.get('organization_name');

  useEffect(() => {
    if (
      !org ||
      window.location.hostname !== 'app.platform-prod.cloud.physicsx.ai'
    ) {
      window.location.href = '/';
      return;
    }

    // Redirect to the organization's subdomain only if on the platform-prod domain
    // Remove 'org' from the query parameters
    const remainingQueryString = params.toString();

    // Construct the full redirect URL
    const redirectUrl = `https://app.${org}.cloud.physicsx.ai${remainingQueryString ? `?${remainingQueryString}` : ''}`;
    window.location.href = redirectUrl;
  }, [org, params]);

  // Fallback to prevent rendering
  return null;
};

export const Root = () => {
  const location = useLocation();

  // Handle the redirect before wrapping in guards and providers
  if (location.pathname === '/redirect') {
    return <RedirectToOrg />;
  }

  return (
    <AppbarContextProvider>
      <Auth0ProviderWithHistory>
        <AuthenticationGuard>
          <Auth0TokenProvider>
            <UrlProvider>
              <AppWrapper />
            </UrlProvider>
          </Auth0TokenProvider>
        </AuthenticationGuard>
      </Auth0ProviderWithHistory>
    </AppbarContextProvider>
  );
};

export default Root;
