import { Optimization } from '@optimizer/types/services/allOptimizationsTypes';
import handleError from '@optimizer/utils/handleError';

import apiClient from '@services/interceptors/authInterceptor';

import { OptimizationSchema } from '../schemas/allOptimizationsSchema';

/**
 * Fetches a single optimization by its ID.
 * @param {string} optimizationId - The ID of the optimization to fetch.
 * @returns {Promise<Optimization | undefined>} - The fetched optimization or undefined if an error occurs.
 * @throws Will throw an error if the fetch operation fails or if the response does not match the expected schema.
 */
export const fetchOptimization = async (
  optimizationId: string,
): Promise<Optimization | undefined> => {
  try {
    const response = await apiClient.get(
      `${process.env.PLATFORM_API_URL}/v0/optimizer/optimizations/${optimizationId}`,
    );

    const parsedResponse = OptimizationSchema.parse(response.data);

    return parsedResponse;
  } catch (error) {
    handleError(error, `fetch optimization with ID ${optimizationId}`);
    return undefined;
  }
};

export default fetchOptimization;
