import { LazyRouteFunction, RouteObject } from 'react-router';

export const lazyLoadHelper = (
  module: () => Promise<{ default: React.FC<any> }>,
): LazyRouteFunction<RouteObject> => {
  return async () => {
    const page = await module();

    return {
      Component: page.default,
      // HydrateFallback: Fallback, or hydrateFallbackElement: <Fallback />, for rendering during the initial hydration.
    };
  };
};

export default lazyLoadHelper;
