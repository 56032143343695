import {
  FunctionComponent,
  type InputHTMLAttributes,
  ReactNode,
  forwardRef,
  useEffect,
  useRef,
} from 'react';

import {
  Dropdown,
  InfoTooltip,
  InfoTooltipProps,
  Icon as MaterialIcon,
  MaterialIconProps,
  DropdownMenuItem,
  DropdownMenuGroup,
} from '@pxui/components/ui';
import { ErrorText, HelperText, Label } from '@pxui/components/ui/textInput';
import { cn } from '@pxui/lib/utils';

import { IconProps } from '../icons';

type IconType = FunctionComponent<IconProps> | MaterialIconProps['name'];

type DropdownType = {
  buttonText: string;
  options: {
    label: string;
    onClick: () => void;
  }[];
};

export type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  debounce?: number;
  endDropDownProps?: DropdownType;
  endIcon?: IconType;
  endText?: string;
  errorText?: string;
  helperText?: string;
  id: string;
  isMandatory?: boolean;
  isOptional?: boolean;
  labelText?: string;
  prefixElement?: ReactNode;
  sizeVariant?: 'medium' | 'small';
  startDropDownProps?: DropdownType;
  startIcon?: IconType;
  startText?: string;
  suffixElement?: ReactNode;
  tipIcon?: IconType;
  tipText?: InfoTooltipProps['description'];
  wrapperClassName?: string;
};

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      type,
      id,
      labelText,
      helperText,
      errorText,
      disabled,
      wrapperClassName,
      tipIcon: Icon,
      debounce,
      onChange,
      isOptional,
      isMandatory,
      sizeVariant = 'medium',
      tipText,
      prefixElement,
      suffixElement,
      startText,
      endText,
      startIcon,
      endIcon,
      startDropDownProps,
      endDropDownProps,
      ...props
    },
    ref,
  ) => {
    const layoutClasses =
      'bg-transparent border-none outline-0 border-0 h-full';

    const placeholderClasses = cn(
      'placeholder:label-1 placeholder:text-on-surface-subtle',
      disabled ? 'placeholder:text-disabled cursor-not-allowed' : '',
    );

    const isSmallVariant = sizeVariant === 'small';

    const wrapperClasses = cn(
      'flex flex-col gap-3',
      isSmallVariant ? 'gap-2' : '',
      wrapperClassName,
    );

    const labelClasses = cn(
      'text-on-surface',
      isSmallVariant ? 'label-2-strong' : 'label-1-strong',
    );

    const optionalTextClass = cn(
      'text-on-surface-subtle',
      isSmallVariant ? 'label-2' : 'label-1',
    );

    const inputClasses = cn(
      'label-1 text-on-surface w-full',
      disabled ? 'text-disabled cursor-not-allowed' : '',
      layoutClasses,
      placeholderClasses,
      className,
    );

    const inputWrapperClasses = cn(
      'flex items-stretch bg-surface-container-low w-fit rounded box-content text-on-surface w-full border border-subtle',
      !disabled && 'hover:state-hover',
      'group focus-within:state-focus',
      errorText ? 'border-danger' : '',
      disabled ? 'text-disabled cursor-not-allowed' : '',
      isSmallVariant ? 'h-[24px] label-2' : ' h-[32px] label-1',
    );

    const prefixElementClassNames = cn(
      'flex items-center py-2 px-3',
      disabled ? 'transition-colors:text-disabled' : 'text-on-surface',
    );

    const tipIconClasses = cn('flex', disabled && 'text-on-surface-subtle');

    const endTextClasses = cn(
      'whitespace-nowrap',
      disabled ? 'text-disabled' : 'text-on-surface',
    );

    const debounceRef = useRef<NodeJS.Timeout | null>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (debounce && onChange) {
        if (debounceRef.current) {
          clearTimeout(debounceRef.current);
        }
        debounceRef.current = setTimeout(() => onChange(e), debounce);
      } else {
        onChange?.(e);
      }
    };

    useEffect(() => {
      return () => {
        if (debounceRef.current) {
          clearTimeout(debounceRef.current);
        }
      };
    }, []);

    const renderIcon = (IconComponent?: IconType, className?: string) => {
      if (!IconComponent) {
        return null;
      }

      const iconSize: IconProps['size'] = isSmallVariant ? 'small' : 'default';

      const iconProps = {
        className: cn(
          `transition-colors ${disabled ? 'text-disabled' : 'text-on-surface-subtle'}`,
          className,
        ),
        size: iconSize,
      };

      if (typeof IconComponent === 'function') {
        return <IconComponent {...iconProps} />;
      }

      if (typeof IconComponent === 'string') {
        return <MaterialIcon {...iconProps} name={IconComponent} />;
      }

      return null;
    };

    const renderTipIcon = () => {
      const tipIcon = renderIcon(Icon, tipIconClasses);

      if (!tipText) {
        return tipIcon;
      }

      return (
        <InfoTooltip description={tipText} side="top">
          <div className="flex">{tipIcon}</div>
        </InfoTooltip>
      );
    };

    const renderDropDown = (
      dropdownProps?: DropdownType,
      dropdownClassName?: string,
    ) => {
      if (!dropdownProps) {
        return null;
      }

      return (
        <Dropdown
          buttonText={dropdownProps.buttonText}
          variant="ghost"
          disabled={disabled}
          className={dropdownClassName}
          buttonClassName={isSmallVariant ? 'label-2' : 'label-1'}
        >
          <DropdownMenuGroup>
            {dropdownProps.options.map((option, index) => (
              <DropdownMenuItem inset onClick={option.onClick} key={index}>
                {option.label}
              </DropdownMenuItem>
            ))}
          </DropdownMenuGroup>
        </Dropdown>
      );
    };

    const renderLabel = () => {
      if (!labelText) {
        return null;
      }

      return (
        <div className="flex items-center gap-1">
          <Label text={labelText} className={labelClasses} htmlFor={id} />
          {isOptional && <span className={optionalTextClass}>(Optional)</span>}
          {isMandatory && <span className="label-1 text-key-error">*</span>}
          {renderTipIcon()}
        </div>
      );
    };

    // TODO add generic text input validation for all "text" and "search" type input fields
    return (
      <div className={wrapperClasses}>
        {renderLabel()}
        <div className={inputWrapperClasses}>
          {prefixElement && (
            <div
              className={`${prefixElementClassNames} border-r border-subtle`}
            >
              {prefixElement}
            </div>
          )}
          <div className="flex items-center px-3 w-full gap-2">
            {renderDropDown(startDropDownProps, '-ml-3')}
            {startText && (
              <span className="whitespace-nowrap">{startText}</span>
            )}
            {startIcon && renderIcon(startIcon)}
            <input
              id={id}
              type={type}
              disabled={disabled}
              className={inputClasses}
              ref={ref}
              onChange={handleChange}
              autoComplete="off"
              {...props}
            />
            {endIcon && renderIcon(endIcon)}
            {endText && <span className={endTextClasses}>{endText}</span>}
            {renderDropDown(endDropDownProps, '-mr-3')}
          </div>
          {suffixElement && (
            <div
              className={`${prefixElementClassNames} border-l border-subtle`}
            >
              {suffixElement}
            </div>
          )}
        </div>
        {!errorText && (
          <HelperText size={sizeVariant} helperText={helperText} />
        )}
        <ErrorText size={sizeVariant} errorText={errorText} />
      </div>
    );
  },
);
TextInput.displayName = 'TextInput';

export { TextInput };
