import { useEffect, useState } from 'react';

import fetchCandidate from '@optimizer/services/fetchCandidate';
import fullCandidatesState from '@optimizer/states/fullCandidateState';
import { useRecoilState } from 'recoil';

interface UseGetFullCandidateDataProps {
  optimizationId?: string | null;
  selectedCandidateId?: string | null;
}

export const useGetFullCandidateData = ({
  optimizationId,
  selectedCandidateId,
}: UseGetFullCandidateDataProps) => {
  const [fullCandidateData, setFullCandidateData] = useRecoilState(
    fullCandidatesState(selectedCandidateId || ''),
  );

  const [isLoading, setIsLoading] = useState(!fullCandidateData);

  useEffect(() => {
    const getFullCandidateData = async () => {
      const candidateFullDate = await fetchCandidate({
        candidateId: selectedCandidateId || '',
        optimizationId: optimizationId || '',
      });

      if (candidateFullDate) {
        setFullCandidateData(candidateFullDate);
      }
    };

    if (selectedCandidateId && optimizationId && !fullCandidateData) {
      getFullCandidateData();
    }

    setIsLoading(false);
  }, [
    fullCandidateData,
    optimizationId,
    selectedCandidateId,
    setFullCandidateData,
  ]);

  return { isLoading };
};

export default useGetFullCandidateData;
