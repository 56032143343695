export const MAX_COLOR_VALUE = 255; // Maximum value for RGB components
export const HEX_BASE = 16; // Base for hexadecimal conversion
export const HEX_LENGTH_SHORT = 3; // Length for short hex color codes (#RGB)
export const HEX_LENGTH_FULL = 6; // Length for full hex color codes (#RRGGBB)
export const HEX_SUBSTRING_LENGTH = 2; // Substring length for parsing full hex codes
export const HEX_PADDING = 2; // Padding for hex values when converting RGB to hex
export const PURE_COLOR_BRIGHTEN_FACTOR = 50; // Factor for brightening pure colors

/**
 * Helper function to check if a hex string is valid.
 * @param {string} hex - The hex string to validate.
 * @returns {boolean} - Returns true if valid hex color.
 */
const isValidHex = (hex: string): boolean => {
  const hexPattern = /^#?([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;
  return hexPattern.test(hex);
};

/**
 * Strategy to brighten pure colors such as #ff0000, #00ff00, and #0000ff.
 * If one of the RGB components is maxed out, brighten by slightly increasing other components.
 *
 * @param {number} r - The red component.
 * @param {number} g - The green component.
 * @param {number} b - The blue component.
 * @param {number} factor - The brightening factor.
 * @returns {[number, number, number]} - The adjusted RGB components.
 */
const brightenPureColor = (
  r: number,
  g: number,
  b: number,
  factor: number,
): [number, number, number] => {
  let newR = r;
  let newG = g;
  let newB = b;

  if (r === MAX_COLOR_VALUE && g === 0 && b === 0) {
    // Pure red
    newG = Math.min(
      MAX_COLOR_VALUE,
      Math.round(g + factor * PURE_COLOR_BRIGHTEN_FACTOR),
    );
    newB = Math.min(
      MAX_COLOR_VALUE,
      Math.round(b + factor * PURE_COLOR_BRIGHTEN_FACTOR),
    );
  } else if (g === MAX_COLOR_VALUE && r === 0 && b === 0) {
    // Pure green
    newR = Math.min(
      MAX_COLOR_VALUE,
      Math.round(r + factor * PURE_COLOR_BRIGHTEN_FACTOR),
    );
    newB = Math.min(
      MAX_COLOR_VALUE,
      Math.round(b + factor * PURE_COLOR_BRIGHTEN_FACTOR),
    );
  } else if (b === MAX_COLOR_VALUE && r === 0 && g === 0) {
    // Pure blue
    newR = Math.min(
      MAX_COLOR_VALUE,
      Math.round(r + factor * PURE_COLOR_BRIGHTEN_FACTOR),
    );
    newG = Math.min(
      MAX_COLOR_VALUE,
      Math.round(g + factor * PURE_COLOR_BRIGHTEN_FACTOR),
    );
  }

  return [newR, newG, newB];
};

/**
 * Helper function to brighten a hex color.
 * This function increases the brightness of each RGB component in the hex color.
 *
 * @param {string} inputHex - The original hex color code (e.g., "#0033ff").
 * @param {number} factor - The factor by which to brighten the color. Values > 1 make the color brighter.
 * @returns {string} - The brightened hex color code.
 */
export const brightenHexColor = (inputHex: string, factor: number): string => {
  const hex = inputHex.replace(/^#/, '');

  if (!isValidHex(hex)) {
    return inputHex; // Return original input if invalid
  }

  let r: number;
  let g: number;
  let b: number;

  if (hex.length === HEX_LENGTH_SHORT) {
    // Short hex notation (e.g., #0f0 -> #00ff00)
    r = parseInt(hex[0] + hex[0], HEX_BASE);
    g = parseInt(hex[1] + hex[1], HEX_BASE);
    b = parseInt(hex[2] + hex[2], HEX_BASE);
  } else if (hex.length === HEX_LENGTH_FULL) {
    // Full hex notation (e.g., #0033FF)
    r = parseInt(hex.substring(0, HEX_SUBSTRING_LENGTH), HEX_BASE);
    g = parseInt(
      hex.substring(HEX_SUBSTRING_LENGTH, HEX_SUBSTRING_LENGTH * 2),
      HEX_BASE,
    );
    b = parseInt(
      hex.substring(HEX_SUBSTRING_LENGTH * 2, HEX_LENGTH_FULL),
      HEX_BASE,
    );
  } else {
    return inputHex; // Return original input if hex is invalid
  }

  // Apply brightening strategy for pure colors (maxed-out components)
  if (
    (r === MAX_COLOR_VALUE && g === 0 && b === 0) || // Pure red
    (r === 0 && g === MAX_COLOR_VALUE && b === 0) || // Pure green
    (r === 0 && g === 0 && b === MAX_COLOR_VALUE) // Pure blue
  ) {
    [r, g, b] = brightenPureColor(r, g, b, factor);
  } else {
    // Brighten each component
    r = Math.min(MAX_COLOR_VALUE, Math.round(r * factor));
    g = Math.min(MAX_COLOR_VALUE, Math.round(g * factor));
    b = Math.min(MAX_COLOR_VALUE, Math.round(b * factor));
  }

  // Convert back to hex and return
  const toHex = (n: number) => n.toString(HEX_BASE).padStart(HEX_PADDING, '0');

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};
