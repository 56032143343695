import { type HTMLAttributes, forwardRef } from 'react';

import { Link } from '@pxui/components/ui/link';
import cn from '@pxui/lib/utils';

import RunningSpan from '../running-span';

interface DetailsLinkProps extends HTMLAttributes<HTMLDivElement> {
  asButton?: boolean;
  button?: React.ReactElement;
  label?: string;
  linkLabel?: string;
  linkTo?: string;
}

const DetailsLink = forwardRef<HTMLDivElement, DetailsLinkProps>(
  (
    { className, label, linkLabel, linkTo, asButton, button, ...props },
    ref,
  ) => {
    return (
      <div ref={ref} className={cn('flex items-center', className)} {...props}>
        <RunningSpan labelText={label ?? ''} functionality="text" />
        {asButton ? button : <Link href={linkTo}>{linkLabel}</Link>}
      </div>
    );
  },
);
DetailsLink.displayName = 'DetailsLink';

export { DetailsLink, type DetailsLinkProps };
