import { comparisonState } from '@optimizer/states/comparisonState';
import { useRecoilValue } from 'recoil';

import ComparisonGeometries from './ComparisonGeometries';
import ComparisonGridItem from './ComparisonGridItem';

const ComparisonGrid = () => {
  const comparison = useRecoilValue(comparisonState);

  return (
    <section className="flex flex-col overflow-auto h-full">
      <ComparisonGeometries />
      <div className="grid grid-flow-col auto-cols-min h-full p-4 gap-4 relative">
        {comparison.map((item) => (
          <ComparisonGridItem key={item.candidate.id} item={item} />
        ))}
      </div>
    </section>
  );
};

export default ComparisonGrid;
