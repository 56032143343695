import { FC } from 'react';

import { OptimizerUrlState } from '@config/optimizerUrl';
import { compareViewState } from '@optimizer/states/comparisonState';
import {
  visibleObjectiveMetricsState,
  visibleMetricsState,
} from '@optimizer/states/metricsState';
import { plotConfigState } from '@optimizer/states/plotsStates';
import { selectedCandidateState } from '@optimizer/states/selectedCandidateState';
import { PlotType } from '@optimizer/types/plotStateTypes';
import { CustomPlotMouseEvent } from '@optimizer/types/plotTypes';
import cn from '@pxui/lib/utils';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import EmptyStateMessage from '@components/EmptyStateMessage/EmptyStateMessage';

import { useUrlContext } from '@contexts/UrlQueryParamsContext';

import LinePlot from './LinePlot';
import ParallelCoordinatePlot from './ParallelCoordinatePlot';
import ScatterPlot from './ScatterPlot';

interface InspectorPlotProps {
  id: string;
}

const InspectorPlot: FC<InspectorPlotProps> = ({ id }) => {
  const { params } = useUrlContext<OptimizerUrlState>();

  const plotConfig = useRecoilValue(plotConfigState(id));
  const objectiveMetrics = useRecoilValue(visibleObjectiveMetricsState);
  const metrics = useRecoilValue(visibleMetricsState);
  const setSelectedCandidate = useSetRecoilState(selectedCandidateState);
  const setCompareView = useSetRecoilState(compareViewState);

  const handlePlotClick = (e: CustomPlotMouseEvent) => {
    const { points } = e;
    if (points && points[0]?.customdata) {
      const [candidateId, optimizationId, step] = points[0].customdata;
      if (candidateId && optimizationId && step) {
        setSelectedCandidate({ id: candidateId, optimizationId, step });
        setCompareView(false);
      }
    }
  };

  // Combine logic for rendering error message and plot in a single switch
  const renderContent = () => {
    switch (plotConfig.type) {
      case PlotType.LINE:
        if (!objectiveMetrics.length && !params.metrics?.length) {
          return (
            <EmptyStateMessage message="No data available for line plot display" />
          );
        }
        return <LinePlot onPointClick={handlePlotClick} plotId={id} />;

      case PlotType.SCATTER:
        if (!metrics.length && !params.metrics?.length) {
          return (
            <EmptyStateMessage message="No data available for scatter plot display" />
          );
        }
        return <ScatterPlot onPointClick={handlePlotClick} plotId={id} />;

      case PlotType.PARALLEL:
        // Optionally, you could add a condition here if needed
        return (
          <ParallelCoordinatePlot onPointClick={handlePlotClick} plotId={id} />
        );

      default:
        return <EmptyStateMessage message="Unknown plot type" />;
    }
  };

  if (!plotConfig) {
    return <EmptyStateMessage message="No data available for plot display" />;
  }

  return (
    <div
      className={cn(
        plotConfig.type !== PlotType.SCATTER ? 'col-span-full' : 'col-span-1',
        'w-full min-w-[400px]',
      )}
    >
      {renderContent()}
    </div>
  );
};

export default InspectorPlot;
