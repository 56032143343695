import { createBrowserRouter, Navigate } from 'react-router';

import { getAllOperatorApps } from '@config/appRegistry';

import mainRoutes from '@pages/routes';

import { type AppConfig } from '@typing/AppConfig';

import { Root } from './Root';

const fluxApps: AppConfig[] = [
  ...getAllOperatorApps().filter((el) => !el?.external),
];

const routesConfiguration = [
  {
    children: [
      { element: <Navigate to="/" replace />, path: '/oauth2/callback' },
      ...mainRoutes,
      ...fluxApps.map(({ path, routes, root }: AppConfig) => ({
        children: routes,
        lazy: root,
        path,
      })),
    ],
    element: <Root />,
    path: '/',
  },
];

export const router = createBrowserRouter(routesConfiguration, {
  future: {
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_relativeSplatPath: true,
    v7_skipActionErrorRevalidation: true,
    v7_startTransition: true,
  },
});

export default router;
