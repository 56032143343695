import moment from 'moment';
import { z } from 'zod';

import { CandidateSchema, StepSchema } from './filteredOptimizationsSchema';

export enum OptimizationStatusEnum {
  FAILED = 'FAILED',
  RUNNING = 'RUNNING',
  SUCCEEDED = 'SUCCEEDED',
}

export enum MetricTypeEnum {
  OBJECTIVE = 'OBJECTIVE',
  OUTCOME_CONSTRAINT = 'OUTCOME_CONSTRAINT',
  TRACKING = 'TRACKING',
}

const ParamTypeEnum = z.enum(['FLOAT', 'INTEGER']);

export const ObjectiveMetricSpecSchema = z
  .object({
    name: z.string(),
    to_minimize: z.boolean(),
    type: z.literal(MetricTypeEnum.OBJECTIVE),
  })
  .transform(({ to_minimize, ...rest }) => ({
    ...rest,
    toMinimize: to_minimize,
  }));

export const OutcomeConstraintMetricSpecSchema = z
  .object({
    lower_bound: z.number().nullable().optional(),
    name: z.string(),
    type: z.literal(MetricTypeEnum.OUTCOME_CONSTRAINT),
    upper_bound: z.number().nullable().optional(),
  })
  .superRefine((data, ctx) => {
    if (!data.lower_bound && !data.upper_bound) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          'At least one of `lower_bound` and `upper_bound` should be set',
      });
    }
    if (
      data.lower_bound &&
      data.upper_bound &&
      data.lower_bound > data.upper_bound
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '`lower_bound` should be less or equal to `upper_bound`',
      });
    }
  })
  .transform(({ lower_bound, upper_bound, ...rest }) => ({
    ...rest,
    lowerBound: lower_bound,
    upperBound: upper_bound,
  }));

// Tracking metric schema
export const TrackingMetricSpecSchema = z.object({
  name: z.string(),
  type: z.literal(MetricTypeEnum.TRACKING),
});

// Union of metric specs
export const MetricSpecSchema = z.union([
  ObjectiveMetricSpecSchema,
  OutcomeConstraintMetricSpecSchema,
  TrackingMetricSpecSchema,
]);

// Parameter Specifications
export const ParamsSpecSchema = z
  .object({
    lower_bound: z.number(),
    name: z.string(),
    type: ParamTypeEnum,
    upper_bound: z.number(),
  })
  .transform(({ lower_bound, upper_bound, ...rest }) => ({
    ...rest,
    lowerBound: lower_bound,
    upperBound: upper_bound,
  }));

// Optimization schema
export const OptimizationSchema = z
  .object({
    created_at: z
      .string()
      .refine((val) => moment(val, moment.ISO_8601, true).isValid(), {
        message: 'Invalid datetime string! Must be a valid ISO 8601 date.',
      }),
    description: z.string().nullable().optional(),
    error_details: z.string().nullable().optional(),
    filtered_step_data: z.array(StepSchema).nullable().optional(),
    flow_run_id: z.string().uuid().nullable().optional(),
    flux_version: z.string().nullable().optional(),
    id: z.string().uuid(),
    max_steps: z.number().nullable().optional(),
    metric_specs: z.array(MetricSpecSchema),
    name: z.string().nullable(),
    parameter_specs: z.array(ParamsSpecSchema),
    pareto_front: z.array(CandidateSchema).nullable().optional(),
    pxo_version: z.string().nullable().optional(),
    status: z.nativeEnum(OptimizationStatusEnum),
    steps_completed: z.number(),
    url: z.string().nullable().optional(),
  })
  .transform(
    ({
      created_at,
      flow_run_id,
      filtered_step_data,
      metric_specs,
      max_steps,
      steps_completed,
      parameter_specs,
      pareto_front,
      ...rest
    }) => ({
      ...rest,
      createdAt: created_at,
      filteredStepData: filtered_step_data,
      flowRunId: flow_run_id,
      maxSteps: max_steps,
      metricSpecs: metric_specs,
      parameterSpecs: parameter_specs,
      paretoFront: pareto_front,
      stepsCompleted: steps_completed,
    }),
  );

export const OptimizationsResponseSchema = z
  .object({
    next_page_token: z.string().nullable(),
    optimizations: z.array(OptimizationSchema),
  })
  .transform(({ optimizations, next_page_token }) => ({
    nextPageToken: next_page_token,
    optimizations,
  }));

export const GetFilteredOptimizationDataResponseSchema = z
  .object({
    optimizations: z.array(OptimizationSchema),
  })
  .transform(({ optimizations }) => ({
    optimizationData: optimizations,
  }));
