import { CandidateSchema } from '@optimizer/schemas/filteredOptimizationsSchema';
import { Candidate } from '@optimizer/types/services/filteredOptimizationsTypes';
import { toast } from '@pxui/components/ui/toast/useToast';

import apiClient from '@services/interceptors/authInterceptor';

export interface FetchCandidateParams {
  candidateId: string;
  optimizationId: string;
}

export const fetchCandidate = async ({
  candidateId,
  optimizationId,
}: FetchCandidateParams): Promise<Candidate | undefined> => {
  try {
    const response = await apiClient.get(
      `${process.env.PLATFORM_API_URL}/v0/optimizer/optimizations/${optimizationId}/candidates/${candidateId}`,
    );
    return CandidateSchema.parse(response.data);
  } catch (error) {
    toast({
      description: 'Failed to fetch candidate data',
      title: 'Error',
      variant: 'error',
    });
    return undefined;
  }
};

export default fetchCandidate;
