/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable sort-keys */
// FIGMA LINK - https://www.figma.com/design/EYpL5PqsjNU4USPUNrCPUx/Kinetic?node-id=7210-35615&m=dev

export const colorTokens = {
  key: {
    default: 'var(--color-key-default)',
    error: 'var(--color-key-error)',
    primary: 'var(--color-key-primary)',
    success: 'var(--color-key-success)',
    warning: 'var(--color-key-warning)',
  },
  border: {
    border: 'var(--color-border)',
    borderSubtle: 'var(--color-border-subtle)',
  },
  surface: {
    0: 'var(--color-surface-0)',
    1: 'var(--color-surface-1)',
    2: 'var(--color-surface-2)',
    canvas: 'var(--color-surface-canvas)',
  },
  inverse: {
    surface: 'var(--color-inverse-surface)',
    onSurface: 'var(--color-inverse-on-surface)',
    primary: 'var(--color-inverse-primary)',
  },
  on: {
    errorContainer: 'var(--color-on-error-container)',
    primaryContainer: 'var(--color-on-primary-container)',
    surface: 'var(--color-on-surface)',
    surfaceSubtle: 'var(--color-on-surface-subtle)',
  },
  container: {
    surfaceHigh: 'var(--color-container-surface-high)',
    surface: 'var(--color-container-surface)',
    surfaceLow: 'var(--color-container-surface-low)',
    error: 'var(--color-container-error)',
    primary: 'var(--color-container-primary)',
  },
  state: {
    hover: 'var(--color-state-hover)',
    selected: 'var(--color-state-selected)',
    active: 'var(--color-state-active)',
  },
};
