import type { FC } from 'react';

import {
  Button,
  SectionHeader,
  SectionHeaderLeft,
  SectionHeaderRight,
} from '@pxui/components/ui';

interface OptimizationManagerSectionHeaderProps {
  onInspectButtonClick: () => void;
  selectedOptimizationsCount: number;
}

const OptimizationManagerSectionHeader: FC<
  OptimizationManagerSectionHeaderProps
> = ({ selectedOptimizationsCount, onInspectButtonClick }) => {
  return (
    <SectionHeader>
      <SectionHeaderLeft title="Optimization history" />
      <SectionHeaderRight>
        <Button
          layout="textOnly"
          onClick={onInspectButtonClick}
          disabled={!selectedOptimizationsCount}
        >
          Inspect ({selectedOptimizationsCount})
        </Button>
      </SectionHeaderRight>
    </SectionHeader>
  );
};

export default OptimizationManagerSectionHeader;
