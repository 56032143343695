import { FC, useState } from 'react';

import { Checkbox, ListItem } from '@pxui/components/ui';

import capitalizeFirstLetter from '@utils/capitalizeFirstLetter';

interface ConstraintsListItemProps {
  handleClick: (newName: string) => void;
  name: string;
  selected?: boolean;
}

const ConstraintsListItem: FC<ConstraintsListItemProps> = ({
  name,
  handleClick,
  selected,
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(selected ?? true);

  return (
    <ListItem
      prefix={<Checkbox checked={isSelected} />}
      className="items-center hover:state-hover group cursor-pointer transition-all duration-150"
      label={capitalizeFirstLetter(name)}
      onClick={() => {
        handleClick(name);
        setIsSelected(!isSelected);
      }}
    />
  );
};

export default ConstraintsListItem;
