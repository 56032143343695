import { useState, useEffect } from 'react';

import useGetFullCandidateData from '@optimizer/hooks/useGetFullCandidateData';
import { MetricTypeEnum } from '@optimizer/schemas/allOptimizationsSchema';
import fullCandidatesState from '@optimizer/states/fullCandidateState';
import { optimizationsDataState } from '@optimizer/states/optimizationsState';
import { useRecoilValue } from 'recoil';

interface UseGetOptimizationInfoProps {
  optimizationId: string;
  selectedCandidateId: string;
}

export const useGetOptimizationInfo = ({
  optimizationId,
  selectedCandidateId,
}: UseGetOptimizationInfoProps) => {
  const [error, setError] = useState<string | null>(null);
  const fullCandidateData = useRecoilValue(
    fullCandidatesState(selectedCandidateId),
  );

  const optimization = useRecoilValue(optimizationsDataState(optimizationId));

  const { isLoading } = useGetFullCandidateData({
    optimizationId,
    selectedCandidateId,
  });

  // Set error if optimization is not found
  useEffect(() => {
    if (!optimization) {
      setError(`Optimization not found.`);
    } else {
      setError(null);
    }
  }, [optimization, optimizationId]);

  if (error) {
    return {
      constraints: [],
      error,
      isLoading: false,
      objectiveFunctions: [],
      parameters: [],
    };
  }

  const objectiveNames =
    optimization?.metricSpecs
      .filter((spec) => spec.type === MetricTypeEnum.OBJECTIVE)
      .map((spec) => spec.name) || [];

  const constraintsNames =
    optimization?.metricSpecs
      .filter((spec) => spec.type === MetricTypeEnum.OUTCOME_CONSTRAINT)
      .map((spec) => spec.name) || [];

  // TODO: change Candidate schema
  const objectiveFunctions =
    fullCandidateData?.metrics.filter((metric) =>
      objectiveNames.includes(metric.name),
    ) || [];

  const constraints =
    fullCandidateData?.metrics.filter((metric) =>
      constraintsNames.includes(metric.name),
    ) || [];

  const parameters = Object.keys(fullCandidateData?.parameters || {}).map(
    (key) => ({
      name: key,
      value: fullCandidateData?.parameters[key],
    }),
  );

  return { constraints, error, isLoading, objectiveFunctions, parameters };
};

export default useGetOptimizationInfo;
