import { FC, useCallback, useEffect } from 'react';

import { OptimizerUrlState } from '@config/optimizerUrl';
import {
  availableStepsRangeState,
  stepsRangeFilterState,
} from '@optimizer/states/selectedStepsState';
import { ListSection, ListHeader, RangeSlider } from '@pxui/components/ui';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useUrlContext } from '@contexts/UrlQueryParamsContext';

const StepsRange: FC = () => {
  const { params, updateParams } = useUrlContext<OptimizerUrlState>();

  const [stepsRangeFilter, setStepsRangeFilter] = useRecoilState(
    stepsRangeFilterState,
  );
  const availableStepsRange = useRecoilValue(availableStepsRangeState);

  const handleRangeSliderUpdate = useCallback(
    (value: [number, number]) => {
      // Only update the steps if the selected range is different from the current one
      if (
        JSON.stringify(params?.steps?.selectedRange) !== JSON.stringify(value)
      ) {
        updateParams({
          steps: {
            ...params.steps,
            selectedRange: [...value],
          },
        });
      }
    },
    [params.steps, updateParams],
  );

  // initialise url with max and min
  useEffect(() => {
    if (!params.steps?.selectedRange) {
      updateParams({
        steps: {
          ...params.steps,
          ...(!params.steps?.selectedRange
            ? { selectedRange: stepsRangeFilter }
            : {}),
        },
      });
    } else if (
      JSON.stringify(stepsRangeFilter) !==
      JSON.stringify(params.steps.selectedRange)
    ) {
      setStepsRangeFilter(params.steps.selectedRange);
    }
  }, [
    availableStepsRange,
    params.steps,
    setStepsRangeFilter,
    stepsRangeFilter,
    updateParams,
  ]);

  return (
    <ListSection>
      <ListHeader title="Steps Range" />
      <RangeSlider
        min={availableStepsRange[0]}
        max={availableStepsRange[1]}
        debounceTime={500}
        defaultRange={params.steps?.selectedRange ?? stepsRangeFilter}
        onChange={(value) => {
          setStepsRangeFilter(value);
          handleRangeSliderUpdate(value);
        }}
        size="small"
      />
    </ListSection>
  );
};

export default StepsRange;
