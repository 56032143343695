interface ComparisonLayoutProps {
  children: React.ReactNode;
}

const ComparisonLayout = ({ children }: ComparisonLayoutProps) => {
  return (
    <div className="surface-0 flex flex-col w-full px-4 overflow-hidden">
      {children}
    </div>
  );
};

export default ComparisonLayout;
