/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable sort-keys */
// FIGMA LINK - https://www.figma.com/design/EYpL5PqsjNU4USPUNrCPUx/Kinetic?node-id=7210-35615&m=dev

export const colors = {
  black: '#000000',
  danger: '#FA4D56',
  white: '#FFFFFF',
  neutral: {
    black: '#000000',
    white: '#FFFFFF',
    4: '#0C0E13',
    6: '#111318',
    10: '#191C20',
    12: '#1D2024',
    20: '#2E3035',
    22: '#32353A',
    24: '#37393E',
    30: '#45474C',
    40: '#5C5E64',
    50: '#75777D',
    60: '#8F9097',
    70: '#AAABB1',
    80: '#C5C6CD',
    86: '#D6D7DD',
    90: '#E1E2E9',
    94: '#EDEDF4',
    96: '#F3F3FA',
    98: '#F9F9FF',
  },
  blue: {
    100: '#EDF5FF',
    200: '#D0E2FF',
    300: '#A6C8FF',
    400: '#78A9FF',
    450: '#578CFF',
    500: '#4589FF',
    600: '#0F62FE',
    700: '#0043CE',
    800: '#002D9C',
    900: '#001D6C',
    950: '#001141',
    10: '#011945',
    20: '#002C6F',
    30: '#00419C',
    40: '#0057CC',
    50: '#006EFE',
    60: '#578CFF',
    70: '#86A9FF',
    80: '#B0C6FF',
    90: '#D9E2FF',
    95: '#EEF0FF',
  },
  gray: {
    100: '#F4F4F4',
    200: '#E0E0E0',
    300: '#C6C6C6',
    400: '#A8A8A8',
    500: '#8D8D8D',
    600: '#6F6F6F',
    700: '#525252',
    800: '#393939',
    900: '#262626',
    950: '#161616',
  },
  green: {
    100: '#DEFBE6',
    200: '#A7F0BA',
    300: '#6FDC8C',
    400: '#42BE65',
    450: '#05A831',
    500: '#24A148',
    600: '#198038',
    700: '#0E6027',
    800: '#044317',
    900: '#022D0D',
    950: '#071908',
    10: '#002204',
    20: '#01390A',
    30: '#005313',
    40: '#006E1D',
    50: '#008A27',
    60: '#05A831',
    70: '#00C63B',
    80: '#00E546',
    90: '#6FFF77',
    95: '#C8FFC0',
  },
  red: {
    100: '#FF5359',
    10: '#410005',
    20: '#680110',
    30: '#93001B',
    40: '#BF0126',
    50: '#EE0033',
    60: '#FF5359',
    70: '#FF8887',
    80: '#FFB3B0',
    90: '#FFDAD8',
    95: '#FFEDEA',
  },
  orange: {
    10: '#321200',
    20: '#553322',
    30: '#753400',
    40: '#9A4600',
    50: '#C15900',
    60: '#E96D00',
    70: '#FF8D43',
    80: '#FFB68C',
    90: '#FFDBC9',
    95: '#FFEDE5',
  },
};

export const numbers = {
  10: 10,
  12: 12,
  16: 16,
  2: 2,
  20: 20,
  24: 24,
  32: 32,
  4: 4,
  40: 40,
  6: 6,
  8: 8,
};
