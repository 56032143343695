import type { FC, HTMLAttributes } from 'react';

import cn from '@pxui/lib/utils';

const GeometryInspectorHeader: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => (
  <div
    className={cn(
      'sticky top-[-20px] z-20 bg-surface-0 shadow-md pt-2 pb-2 w-full flex gap-2 justify-between',
      className,
    )}
    {...props}
  >
    {children}
  </div>
);

GeometryInspectorHeader.displayName = 'GeometryInspectorHeader';

export default GeometryInspectorHeader;
