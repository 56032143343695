import { OptimizationStatusEnum } from '@optimizer/schemas/allOptimizationsSchema';
import getColorForOptimization from '@optimizer/utils/getColorForOptimization';
import {
  atomFamily,
  atom,
  selectorFamily,
  selector,
  DefaultValue,
} from 'recoil';

import { optimizationsState } from './optimizationsState';

import { Optimization } from '../types/services/allOptimizationsTypes';

// Dynamic data interface for selected optimizations, with added `error` field
export interface SelectedOptimizationDynamicData {
  color: string;
  error: boolean; // Tracks if optimization failed
  hide: boolean;
}

// Full data interface combining the Optimization object and dynamic parts
export interface SelectedOptimizationData
  extends Optimization,
    SelectedOptimizationDynamicData {}

// Atom to store the list of selected optimizations
export const selectedOptimizationsStaticDataState = atom<Optimization[]>({
  default: [],
  key: 'selectedOptimizationsStaticDataState',
});

// AtomFamily to store dynamic data of selected optimizations
export const selectedOptimizationsDynamicDataState = atomFamily<
  SelectedOptimizationDynamicData,
  string
>({
  default: (id) => ({
    color: getColorForOptimization(id),
    error: false,
    hide: false,
  }),
  key: 'selectedOptimizationsDynamicDataState',
});

// SelectorFamily to get the full data (static + dynamic) for a specific optimization
export const selectedOptimizationFullState = selectorFamily<
  SelectedOptimizationData,
  string
>({
  get:
    (id) =>
    ({ get }) => {
      const staticData = get(selectedOptimizationsStaticDataState).find(
        (opt) => opt.id === id,
      );

      if (!staticData) throw new Error(`Optimization with id ${id} not found`);

      const dynamicData = get(selectedOptimizationsDynamicDataState(id));
      return { ...staticData, ...dynamicData };
    },
  key: 'selectedOptimizationFullState',
  set:
    (id) =>
    ({ set, get }, newValue) => {
      if (!(newValue instanceof DefaultValue)) {
        const updatedData = { ...newValue } as SelectedOptimizationDynamicData;
        const currentOptimization = get(optimizationsState).find(
          (opt) => opt.id === id,
        );

        // Set error to true if optimization status has failed
        if (currentOptimization?.status === OptimizationStatusEnum.FAILED) {
          updatedData.error = true;
        }

        set(selectedOptimizationsDynamicDataState(id), updatedData);
      }
    },
});

// Selector to get all visible selected optimizations with full data
export const visibleSelectedOptimizationsState = selector<
  (Optimization & SelectedOptimizationDynamicData)[]
>({
  get: ({ get }) => {
    const selectedOptimizations = get(selectedOptimizationsStaticDataState);
    const allOptimizations = get(optimizationsState);

    // Filter optimizations that are not hidden and check error status
    return selectedOptimizations
      .map((selectedOpt) => {
        const optimization = allOptimizations.find(
          (opt) => opt.id === selectedOpt.id,
        );
        if (!optimization) return null;

        const dynamicData = get(
          selectedOptimizationsDynamicDataState(selectedOpt.id),
        );
        return {
          ...optimization,
          color: dynamicData.color,
          error: dynamicData.error,
          hide: dynamicData.hide,
        };
      })
      .filter(
        (opt): opt is Optimization & SelectedOptimizationDynamicData =>
          opt !== null && !opt.hide,
      );
  },
  key: 'visibleSelectedOptimizationsState',
});

// Selector to reset all dynamic states of optimizations
export const resetAllOptimizationsDynamicDataState = selector({
  get: () => null, // No need to return anything, we just use the "set" function for resetting state
  key: 'resetAllOptimizationsDynamicDataState',
  set: ({ get, set }) => {
    const selectedOptimizationsStaticData = get(
      selectedOptimizationsStaticDataState,
    ); // Get the static data (list of optimizations)

    // Loop through each optimization and reset its dynamic hide and error state to false
    selectedOptimizationsStaticData.forEach((optimization) => {
      set(selectedOptimizationsDynamicDataState(optimization.id), {
        color: getColorForOptimization(optimization.id),
        error: false,
        hide: false,
      });
    });
  },
});
