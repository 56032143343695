import { useEffect, type FC } from 'react';

import { OptimizerUrlState } from '@config/optimizerUrl';
import { selectedOptimizationsStaticDataState } from '@optimizer/states/selectedOptimizationsStates';
import { ListHeader, ListSection } from '@pxui/components/ui';
import { useRecoilValue } from 'recoil';

import { useUrlContext } from '@contexts/UrlQueryParamsContext';

import OptimizationsListItem from './OptimizationsListItem';

const OptimizationsList: FC = () => {
  const { params, updateParams } = useUrlContext<OptimizerUrlState>();

  const selectedOptimizations = useRecoilValue(
    selectedOptimizationsStaticDataState,
  );

  // update url once the selections have been finalised
  useEffect(() => {
    if (!params?.optimizationIds?.length && !params?.optimizationIds) {
      updateParams({
        optimizationIds: [...selectedOptimizations.map((el) => el.id)],
      });
    }
  }, [params.optimizationIds, selectedOptimizations, updateParams]);

  return (
    <ListSection>
      <ListHeader title="Runs" />
      {selectedOptimizations.length &&
        selectedOptimizations.map(({ id }) => (
          <OptimizationsListItem key={id} id={id} />
        ))}
      {!selectedOptimizations.length && (
        <div className="text-sm text-gray-500 p-4">
          No optimizations available.2
        </div>
      )}
    </ListSection>
  );
};

export default OptimizationsList;
