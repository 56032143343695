import { MutableRefObject, useCallback, useEffect, useState } from 'react';

import { DetailsCard } from '@pxui/components/ui';

import DetailsCardWrapper from '@components/DetailsCardWrapper/DetailsCardWrapper';

import SidebarFilters from './SidebarFilters';
import SidebarMeshMetadata from './SidebarMeshMetadata';
import SidebarMeshSettings from './SidebarMeshSettings';

import { MeshConfig, MeshMetadata } from '../../hooks/useMeshVisualiser';
import { GeometryViewer } from '../GeometryViewer/GeometryViewerFactory';
import {
  MeshRepresentationLabel,
  MeshRepresentationStringToValueMap,
} from '../GeometryViewer/settings';

interface SidebarProps {
  colorArrays: string[];
  colorMapPresets: string[];
  isSingleMesh?: boolean;
  meshes: MeshConfig[];
  metadata?: MeshMetadata[];
  onColorByChange?: (colorBy: string) => void;
  onColorPresetChange?: (colorPreset: string) => void;
  viewer?: MutableRefObject<GeometryViewer | undefined>;
  viewerReady: boolean;
}
export function Sidebar({
  viewer,
  meshes,
  isSingleMesh = true,
  viewerReady,
  colorArrays,
  colorMapPresets,
  metadata,
  onColorByChange,
  onColorPresetChange,
}: SidebarProps) {
  console.log('Sidebar: Color arrays', colorArrays);
  const [vertexVisibility, setVertexVisibility] = useState(false);
  const [representation, setRepresentation] =
    useState<MeshRepresentationLabel>('Surface');

  const [activeColorArray, setActiveColorArray] = useState<string>('Solid');

  const [activeColorMapPreset, setActiveColorMapPreset] =
    useState<string>('Warm');
  const handleToggleVertexVisibility = useCallback(() => {
    viewer?.current?.setVertexVisibility(!vertexVisibility);
    viewer?.current?.render();
    setVertexVisibility((prev) => !prev);
  }, [viewer, vertexVisibility]);

  const handleMeshRepChange = useCallback(
    (rep: MeshRepresentationLabel) => {
      viewer?.current?.setRepresentation(
        MeshRepresentationStringToValueMap[rep],
      );
      viewer?.current?.render();

      setRepresentation(rep);
    },
    [viewer],
  );

  const handleColorArrayChange = useCallback(
    (arrayLabel: string) => {
      viewer?.current?.setColorMapPreset(activeColorMapPreset);
      viewer?.current?.setColorByArray(arrayLabel);
      viewer?.current?.render();
      setActiveColorArray(arrayLabel);

      if (onColorByChange) {
        onColorByChange(arrayLabel);
      }
    },
    [activeColorMapPreset, onColorByChange, viewer],
  );

  const handleColorPresetChange = useCallback(
    (preset: string) => {
      viewer?.current?.setColorMapPreset(preset);
      viewer?.current?.render();
      setActiveColorMapPreset(preset);

      if (onColorPresetChange) {
        onColorPresetChange(preset);
      }
    },
    [viewer, onColorPresetChange],
  );

  const handlePointSizeChange = useCallback(
    (pointSizeChange: number[]) => {
      if (pointSizeChange.length > 0) {
        const [pointSize] = pointSizeChange;
        viewer?.current?.setPointSize(pointSize);
        viewer?.current?.render();
      }
    },
    [viewer],
  );

  const handleLineWidthChange = useCallback(
    (lineWidthChange: number[]) => {
      if (lineWidthChange.length > 0) {
        const [lineWidth] = lineWidthChange;
        viewer?.current?.setLineWidth(lineWidth);
        viewer?.current?.render();
      }
    },
    [viewer],
  );

  const meshesFullMetdata = meshes.map((mesh, i) => {
    const met = metadata && metadata[i] ? metadata[i] : [];
    return {
      mesh,
      metadata: met,
    };
  });

  // We initialize the color array to the first item after Solid.
  useEffect(() => {
    if (colorArrays.length > 1) {
      setActiveColorArray(colorArrays[1]);
    }
  }, [colorArrays]);

  return (
    <DetailsCardWrapper open className="py-0 px-0">
      <DetailsCard
        className="h-full w-full relative rounded-none gap-2"
        surface="surface-01"
        elevation="elevation-00"
      >
        <div
          className="px-4 py-4 surface-00 border-01-subtle border-b w-full overflow-y-auto"
          style={{ maxHeight: '33.33vh' }}
        >
          {meshesFullMetdata.map(({ mesh, metadata: meshMetadata }, i) => {
            const { filename } = mesh;
            return (
              <SidebarMeshMetadata
                key={i}
                filename={filename}
                metadata={meshMetadata}
              />
            );
          })}
        </div>
        <div className="px-4 py-4 w-full border-01-subtle border-b overflow-y-auto">
          <SidebarMeshSettings
            fields={colorArrays}
            colorMapPresets={colorMapPresets}
            activeColorMapPreset={activeColorMapPreset}
            activeField={activeColorArray}
            representation={representation}
            vertexVisibility={vertexVisibility}
            onToggleVertexVisibility={handleToggleVertexVisibility}
            onRepresentationChange={handleMeshRepChange}
            onFieldChange={handleColorArrayChange}
            onColorPresetChange={handleColorPresetChange}
            onPointSizeChange={handlePointSizeChange}
            onLineWidthChange={handleLineWidthChange}
          />
        </div>
        <div className="px-4 py-4 w-full overflow-y-auto">
          <SidebarFilters
            isLoaded={viewerReady}
            viewer={viewer}
            isSingleMesh={isSingleMesh}
          />
        </div>
      </DetailsCard>
    </DetailsCardWrapper>
  );
}

export default Sidebar;
