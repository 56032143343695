import { CandidateSelectionMode } from '@optimizer/schemas/filteredOptimizationsSchema';
import { atom } from 'recoil';

export const candidateSelectionModeState = atom<
  Record<'line' | 'marker', CandidateSelectionMode[]>
>({
  default: {
    line: [CandidateSelectionMode.MAX],
    marker: [CandidateSelectionMode.MAX],
  },
  key: 'candidateSelectionModeState',
});

export default candidateSelectionModeState;
