import { forwardRef, memo, useState } from 'react';

import {
  MetricsNames,
  OptimizationStatusBar,
  TextWithEllipsis,
} from '@optimizer/components';
import { OptimizationStatusEnum } from '@optimizer/schemas/allOptimizationsSchema';
import deleteOptimization from '@optimizer/services/deleteOptimization';
import { deleteOptimizationState } from '@optimizer/states/optimizationsState';
import { Optimization } from '@optimizer/types/services/allOptimizationsTypes';
import formatDate from '@optimizer/utils/formatDate';
import {
  ArgoIcon,
  Button,
  Checkbox,
  InfoTooltip,
  TableCell,
  TableRow,
} from '@pxui/components/ui';
import cn from '@pxui/lib/utils';
import { Loader } from 'lucide-react';
import { useSetRecoilState } from 'recoil';

interface OptimizationManagerTableRowProps {
  onSelect: (opt: Optimization) => void;
  optimization: Optimization;
  selected: boolean;
}

const OptimizationManagerTableRow = forwardRef<
  HTMLTableRowElement,
  OptimizationManagerTableRowProps
>(({ optimization, selected, onSelect }, ref) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const setDeleteOptimization = useSetRecoilState(deleteOptimizationState);

  const deleteOptimizationHandler = async () => {
    setIsDeleting(true);
    // Delete optimization from the backend and update recoil state
    deleteOptimization(optimization.id, optimization.name)
      .then(() => setDeleteOptimization(optimization.id))
      .finally(() => setIsDeleting(false));
  };

  return (
    <TableRow ref={ref} className={cn('group/row')}>
      <TableCell className="h-[60px] py-[7px]">
        <Checkbox
          // TODO: Is there better way to check status?
          disabled={
            !(
              optimization.status === OptimizationStatusEnum.SUCCEEDED ||
              optimization.status === OptimizationStatusEnum.RUNNING
            )
          }
          checked={selected}
          onCheckedChange={() => onSelect(optimization)}
        />
      </TableCell>
      <TableCell className="h-[60px] py-[7px]">
        {optimization.createdAt && formatDate(optimization.createdAt)}
      </TableCell>
      <TableCell className="h-[60px] py-[7px]">
        <TextWithEllipsis
          text={optimization.name || 'N/A'}
          className={cn(optimization.name ? 'text-primary' : 'text-secondary')}
        />
      </TableCell>
      <TableCell className="h-[60px] py-[7px]">
        <TextWithEllipsis
          text={optimization.description || 'N/A'}
          className={cn(
            optimization.description ? 'text-primary' : 'text-secondary',
          )}
        />
      </TableCell>
      <TableCell className="cursor-pointer h-[60px] py-[7px] min-w-[260px] w-[260px]">
        <MetricsNames
          metrics={optimization.metricSpecs.map((metric) => metric.name)}
        />
      </TableCell>
      <TableCell className="h-[60px] py-0">
        <OptimizationStatusBar
          status={optimization.status}
          maxSteps={optimization.maxSteps}
          stepsCompleted={optimization.stepsCompleted}
        />
      </TableCell>
      <TableCell className="w-0">
        {isDeleting ? (
          <Loader className="text-primary animate-spin" />
        ) : (
          <InfoTooltip description="Double-click to permanently delete the optimization">
            <Button
              disabled={isDeleting}
              iconName="delete"
              layout="iconOnly"
              onDoubleClick={deleteOptimizationHandler}
              variant="ghost"
            />
          </InfoTooltip>
        )}
        {optimization.url && (
          <Button layout="iconOnly" variant="ghost" icon={ArgoIcon} asChild>
            <a
              href={optimization.url}
              aria-label="Go to Argo Workflows run"
              title="Go to Argo Workflows run"
              target="_blank"
              rel="noreferrer"
            />
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
});

export default memo(OptimizationManagerTableRow);
